<template>
  <dialog-box :dialog-box-specific-style="dialogBoxStyle">
    <span class="CreateVersion-header"> Mark this version </span>
    <div class="CreateVersion-form">
      <div class="CreateVersion-title">
        <div class="CreateVersion-titleSection">
          <span class="CreateVersion-label"> {{ $t('title-version') }}</span>
          <span
            :class="{ 'CreateVersion-maxCharactersReached': computeCharactersLeft < 0, 'CreateVersion-charactersLeftCounter': true }"
          >
            {{ computeCharactersLeft }} </span>
        </div>
        <div class="CreateVersion-titleInputSection">
          <input
            ref="title"
            v-model="version.name"
            placeholder="Required title"
            :class="{'CreateVersion-input': true, 'CreateVersion-emptyInputName': isNameEmpty}"
            type="text"
          >
          <caution-mark
            v-show="isNameEmpty"
            color="rgb(195, 70, 82, 1)"
            height="45"
            margin-left="-37"
          />
        </div>
      </div>
      <div class="CreateVersion-description">
        <span class="CreateVersion-label"> Description</span>
        <textarea
          v-model="version.description"
          class="CreateVersion-textarea"
          placeholder="Describe your version"
        />
      </div>
      <div class="CreateVersion-actions">
        <button
          class="CreateVersion-cancelButton"
          @click="cancel()"
        >
          <span class="CreateVersion-cancel"> Cancel </span>
        </button>
        <button
          :class="[isSaveDisabled ? 'CreateVersion-disabledSaveButton' : 'CreateVersion-enabledSaveButton']"
          :disabled="isSaveDisabled"
          @click="manageVersion(version)"
        >
          <span :class="[isSaveDisabled ? 'CreateVersion-disabledSave' : 'CreateVersion-enabledSave']"> Save </span>
        </button>
      </div>
    </div>
  </dialog-box>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { VERSION_MODES } from 'GLOBALS/constants.js';

export default {
  name: 'CreateVersion',
  data() {
    return {
      submitted: false,
      maxAllowedCharacters: 30,
      version: {name: 'Marked version', description: ''},
      dialogBoxStyle: 'position: absolute; width: 494px; height: 382px; left: 35%; top: 25%;'
    };
  },
  computed: {
    ...mapState('board', ['openedComponents', 'mode', 'selectedVersion', 'dialogBoxFlags']),
    isSaveDisabled() {
      return this.isNameEmpty || this.computeCharactersLeft < 0  || this.computeCharactersLeft === this.maxAllowedCharacters ? true : false;
    },
    isNameEmpty() {
      return this.version.name.trim().length === 0;
    },
    computeCharactersLeft() {
      return this.maxAllowedCharacters - this.version.name.length;
    }
  },
  mounted() {
    this.selectAndFocusTitle();
  },
  created() {
    if (this.mode === VERSION_MODES[1]) {
      /*
         the version should be prepopulated if it's editVersion mode
      */
      this.version.name = this.selectedVersion.name;
      this.version.description = this.selectedVersion.description;
    }
  },
  methods: {
    ...mapActions('board', [
      'addBoardVersion',
      'editVersion'
    ]),
    ...mapMutations('board', [
      'setOpenedComponents',
      'setMode',
      'setDialogBoxFlags'
    ]),
    async addMarkedVersion(version) {
      await this.addBoardVersion({...version, marked: true});
      this.setMode(VERSION_MODES[3]);
      this.manageOpenedComponents();
      this.closeDialogBox();
    },
    async markVersion(version) {
      await this.editVersion({version: {...version, marked: true}, versionId: this.selectedVersion.id});
      this.manageOpenedComponents();
      this.closeDialogBox();
    },
    manageVersion(version) {
      if (this.mode === 'editVersion' || this.mode === 'markVersion') {
        this.markVersion(version);
      }
      if (this.mode === 'createVersion') {
        this.addMarkedVersion(version);
      }
    },
    closeDialogBox() {
      this.setDialogBoxFlags({...this.dialogBoxFlags, isCreateVersionDialogOpened: false});
    },
    manageOpenedComponents() {
      const openedComponents = this.openedComponents;
      if (!this.openedComponents.includes('VersionHistory')) {
        openedComponents.push('VersionHistory');
      }
      this.setOpenedComponents(openedComponents);
    },
    selectAndFocusTitle() {
      this.$refs.title.focus();
      this.$refs.title.select();
    },
    cancel() {
      this.closeDialogBox();
    }
  }
};
</script>
<style src="./CreateVersion.css" />
