<template>
  <div class="ToolsConfig-tabs">
    <div
      v-for="(tab, index) of tabs"
      :key="index"
      :class="index === selectedTab && 'ToolsConfig-tab-selected'"
      class="ToolsConfig-tab"
      @click="setSelectedTab(index)"
    >
      <icon-loader
        style="display: grid; align-items: center;"
        :name="tab.icon"
        :color="index === selectedTab ? 'rgba(0, 74, 250, 1)' : '#9A9A9A'"
        :stroke="index === selectedTab ? 'rgba(0, 74, 250, 1)' : '#9A9A9A'"
      />
      <span class="tab-label">{{ tab.label }}</span>
    </div>
  </div>
</template>
<script>

export default {
  name: 'ToolsConfigTabs',
  props: {
    tabs: {
      type: Array,
      required: true
    },
    selectedTab: {
      type: Number,
      required: true
    },
    setSelectedTab: {
      type: Function,
      required: true
    }
  }
};
</script>
<style src="./ToolsConfigTabs.css" scoped />
