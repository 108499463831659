<template>
  <div
    id="cardCustomization-Container"
    class="cardCustomization"
    @scroll="setScrollPosition"
  >
    <div class="cardCustomization-Title">
      Color
    </div>
    <div class="cardCustomization-ColorPicker-list">
      <div
        v-for="(color, index) of initialColorsList"
        :key="index"
        class="cardCustomization-colorcircle-container"
      >
        <colored-circle
          :color="color"
          :is-selected="defaultColor === color"
          @click="setDefaultColor(color)"
        />
      </div>
      <div class="cardCustomization-colorcircle-container">
        <colored-circle
          v-if="defaultColor && !initialColorsList.includes(defaultColor)"
          :color="defaultColor"
          :is-selected="true"
          @click="setDefaultColor(color)"
        />
      </div>
      <div>
        <button
          id="dropdowButton"
          data-dropdown-toggle="dropdownDelay"
          data-dropdown-placement="start"
          type="button"
          :style="'line-height: 30px;'"
          @click.stop="toggleColorPopup"
        >
          More
        </button>
        <colors-popover
          v-if="isColorsPopup"
          :selected-color="defaultColor"
          @select-color="setDefaultColor"
        />
      </div>
    </div>
    <div class="cardCustomization-Title">
      Layout
      <span
        v-if="selectedLayout"
        class="cardCustomization-layoutLabel"
      >
        | {{ selectedLayoutName }}
      </span>
    </div>
    <div>
      <card-layout
        v-if="layouts && layouts.length > 0"
        :selected-layout="selectedLayout"
        :select-layout="selectLayout"
        :layouts="layouts"
      />
    </div>
    <div>
      <custom-field-mapper
        v-if="tiles"
        :tiles="tiles"
        :scroll-position="scrollPosition"
      />
    </div>
  </div>
</template>

<script>
import { COLOR_PALETTE } from 'GLOBALS/constants';
import CardLayout from './CardLayout/CardLayout.vue';
import { mapState, mapMutations } from 'vuex';
import CustomFieldMapper from './CustomFieldMapper/CustomFieldMapper.vue';

export default {
  name: 'CardCustomization',
  components: { CardLayout, CustomFieldMapper },
  data: () => ({
    COLOR_PALETTE,
    initialColorsList: COLOR_PALETTE.slice(0, 8),
    isColorsPopup: false,
    scrollPosition: 0
  }),
  computed: {
    ...mapState('app', ['layouts']),
    ...mapState('toolsConfig', ['selectedLayout', 'defaultColor']),
    tiles() {
      if (this.selectedLayout && this.layouts) {
        const selectedLayoutObj = this.layouts.find(
          (e) => e.id === this.selectedLayout
        );
        return selectedLayoutObj
          ? selectedLayoutObj.tiles.filter((tile) => (tile.label && tile.name !== 'title'))
          : null;
      }
      return null;
    },
    selectedLayoutName() {
      if (this.selectedLayout) {
        const selectedLayoutObj = this.layouts.find(
          (e) => e.id === this.selectedLayout
        );
        if (selectedLayoutObj) {
          return selectedLayoutObj.friendlyName;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapMutations('toolsConfig', ['setSelectedLayout', 'setDefaultColor']),
    selectLayout(value) {
      this.setSelectedLayout(value);
    },
    closeColorsPopup() {
      this.isColorsPopup = false;
    },
    toggleColorPopup() {
      this.isColorsPopup = !this.isColorsPopup;
    },
    setScrollPosition() {
      const container = document.querySelector('#cardCustomization-Container');
      this.scrollPosition = container.scrollTop;
    }
  }
};
</script>

<style scoped>
.cardCustomization {
  padding: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 85%;
  position: relative;
}

.cardCustomization-layoutLabel {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #9a9a9a;
}

.cardCustomization-Title {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  margin-bottom: 6px;
  margin-top: 9px;
}

.cardCustomization-colorcircle-container {
  margin-right: 8px;
}

.cardCustomization-ColorPicker-list {
  display: flex;
  height: 30px;
}
</style>
