<template>
  <div
    class="BoardNote-wrapper"
    data-target="board-element"
  >
    <div
      class="BoardNote-card"
      :style="cardStyle"
      data-target="board-element"
    >
      <div
        class="BoardNote-header"
        data-target="board-element"
      />
      <p
        class="BoardNote-text"
        data-target="board-element"
        :style="textStyle"
      >
        {{ props.title }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { NOTE_FONT_SIZES } from 'GLOBALS/constants';
import { getDimensionFromSize } from 'BOARD/utils/utils';

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: ''
  },
  color: {
    type: String,
    default: '#FFE48A'
  },
  layout: {
    type: String,
    default: 'square'
  },
  sizeLayoutMap: {
    type: Array,
    default: () => []
  }
});
const dimension = computed(() => {
  const layoutItems = props?.sizeLayoutMap?.find((item) => item[props?.layout]);
  return getDimensionFromSize(props?.size.width, props?.size.height, layoutItems && layoutItems[props?.layout]);
});

const cardStyle = computed(() => `background: ${props.color}; padding:${NOTE_FONT_SIZES[dimension.value]?.padding}px;`);
const calculateLineClamp = () => {
  if (!dimension.value) {
    return 7;
  }
  const {  fontSize, padding } = NOTE_FONT_SIZES[dimension.value];
  const estimatedLineHeight = fontSize * 1.5;
  return Math.floor((props?.size.height - padding) / estimatedLineHeight) ;
};
const lineClamp = computed(() => calculateLineClamp());
const textStyle = computed(() => `font-size: ${NOTE_FONT_SIZES[dimension.value]?.fontSize}px; 
    -webkit-line-clamp: ${lineClamp.value};
`);
</script>

<style>
.BoardNote-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--BoardNote-gap-small-default, 6.25px);
  padding: var(--BoardNote-padding-small-default, 6.25px);
  width: 100%;
  height: 100%;
}

.BoardNote-header {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 15%;
  opacity: 0.08;
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 113.75%);
}

.BoardNote-text {
  font-family: "Noto Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  max-width: 100%;
  max-height: 100%;
  font-size: 40px;
  color: var(--BoardNote-color-font-yellow, #261F08);
  word-break: break-word;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: normal;
}

.BoardNote-wrapper {
  box-shadow: 0 2px 4px rgba(120 116 145 / 6%), 0 4px 6px rgba(120 116 145 / 12%);
}
</style>
