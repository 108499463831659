<template>
  <movable
    :id="elementId"
    ref="movable"
    :position="position"
    :element-id="elementId"
    :no-element-drag="noElementDrag || locked"
    :is-selected="isSelected"
    :no-interactions="isColorsPopup"
    :height="size.height"
    :width="size.width"
    :scale="size.scale"
    :rows-per-page="boardElementAttributes?.rowsPerPage"
    :set-is-resizing="setIsResizing"
    :homothetic-resize="homotheticResize"
    :tool-type="toolType"
    :board-element-attributes="boardElementAttributes"
    @dblclick="onDoubleClick"
    @mousedown="() => send('pressElements')"
    @drag="onDrag"
  >
    <div
      :id="elementId + 'insider'"
      :class="['BoardElement', { 'BoardElement--ghost': isGhost }]"
    >
      <div>
        <ResizeKnobs
          v-if="isSelected && (isFreeSize || homotheticResize)"
          :zoom-level="zoomLevel"
          :feedback-color="feedbackColor"
          :corner-knobs="resizeConfig?.cornerKnobs"
          :middle-knobs="resizeConfig?.middleKnobs"
        />
        <contextual-menu
          v-if="
            isSelected &&
              !(formulaInEditionBoardElementId === elementId) &&
              !isBeingMovedByThisUser &&
              userSelectedElements &&
              userSelectedElements.length === 1 &&
              contextualMenuShown
          "
          :on-refresh="refreshWebContent"
          :on-edit="onEditClick"
          :on-open-in-new-tab="openInNewTab"
          :on-delete="deleteUserSelectedElements"
          :on-size-change="onSizeChange"
          :on-layout-change="updateLayout"
          :on-zoom-change="onZoomChange"
          :zoom-value="zoomValue"
          :on-lock-toggle="toggleElementsLockStatus"
          :on-open-indicator="onOpenIndicator"
          :locked="locked"
          :is-in-edition="isInEditionByCurrentUser"
          :has-color="hasColor"
          :bg-color="bgColor"
          :layout="selectedLayout"
          :has-layout="hasLayout"
          :set-color="(value) => updateElementProprety({color: value + 'FF'})"
          :set-view-type="setViewType"
          :close-colors-popup="closeColorsPopup"
          :toggle-colors-popup="toggleColorsPopup"
          :is-colors-popup="isColorsPopup"
          :contextual-menu-items="contextualMenuItems"
          :size-layout-map="sizeLayoutItems"
          :size="size"
          :tool-type="toolType"
          :element-size="size"
          :is-free-size="isFreeSize"
          :open-link="onOpenLink"
        />
        <board-element-feedback
          v-if="
            !isUnHighlighted &&
              (isInEdition ||
                isMoving ||
                isHighlighted ||
                isOnlySelectedByOthers ||
                isSelected)
          "
          :text="String(tooltipText)"
          :is-edition="isInEdition"
          :is-selected="isSelected"
          :color="String(feedbackColor)"
          :is-highlighted="isHighlighted"
          :is-widget="isWidget"
          :is-in-edition-only-by-others="isInEditionOnlyByOthers"
        />
        <formula-widget
          v-if="attributes && isWidget"
          class="BoardElement-content"
          :attributes="attributes"
          :dataset-element-id="datasetElementId"
          :board-element-id="elementId"
          :is-edition="isInEditionByCurrentUser"
          :outline-color="String(feedbackColor)"
          :has-outline="hasOutline"
        />
        <indicator-chart
          v-else-if="isIndicator"
          :key="elementId + width + height"
          :is-resizing="isResizing"
          :style="style"
          class="BoardElement-content"
          :attributes="attributes"
          :element-id="elementId"
          :dataset-element-id="datasetElementId"
          :height="size.height"
          :width="size.width"
          :has-outline="hasOutline"
          :outline-color="String(feedbackColor)"
        />
        <EmbedContent
          v-else-if="attributes && isEmbedContent"
          :style="style"
          :tool-type="toolType"
          :url="attributes.url"
          :size="size"
          :zoom-value="zoomValue"
          :is-refreshing="toggleRefreshState"
          :user-interaction="userInteractionOnBoardElements"
        />
        <BookmarkButton
          v-else-if="attributes && isButton"
          :color="bgColor"
          :link="attributes.link"
          :label="attributes.label"
          :height="size.height"
          :width="size.width"
          :style="style"
        />
        <BoardNote
          v-else-if="attributes && isBoardNote"
          :style="style"
          :title="attributes.title"
          :size="size"
          :color="bgColor"
          :layout="selectedLayout"
          :size-layout-map="sizeLayoutItems"
        />
        <card
          v-else-if="isCard"
          :style="style"
          class="BoardElement-content"
          :fields="elementFields"
          :bg-color="bgColor"
          :has-color="hasColor"
          :can-be-highlighted="selectedInteractionMode === INTERACTION_MODES.LINKS"
          :additional-dots="additionalDots"
          :element-id="elementId"
          :height="size.height"
          :width="size.width"
          :data-type-id="dataTypeId"
          :attributes="attributes"
          :is-free-size="isFreeSize"
        />
        <list-widget
          v-else-if="isBoardget"
          :key="elementId + size.height + size.width"
          :element-id="elementId"
          :style="style"
          class="BoardElement-content"
          :can-be-highlighted="selectedInteractionMode === INTERACTION_MODES.LINKS"
          :list="widgetDatasetElements"
          :scale="size.scale"
          :rows-per-page="boardElementAttributes?.rowsPerPage"
          :previous-rows-per-page="previousRowsPerPage"
          :filter-id="attributes.filter"
          :title="attributes.title"
          :height="size.height"
          :width="size.width"
          :is-resizing="isResizing"
          @update="({ currentPage}) => currentListPage = currentPage"
        />
        <board-image
          v-else-if="isImage && attributes?.asset"
          :key="elementId"
          :attributes="attributes"
          :height="size.height"
          :width="size.width"
          :style="style"
          class="BoardElement-content"
        />
      </div>
    </div>
  </movable>
</template>

<script>
import Movable from 'BOARD/components/Movable/Movable.vue';
import Card from 'BOARD/components/Card/Card.vue';
import BoardNote from 'SRC/note/BoardNote.vue';
import EmbedContent from 'BOARD/components/Embed/EmbedContent.vue';
import BoardElementFeedback from 'BOARD/components/BoardElementFeedback/BoardElementFeedback';
import ContextualMenu from 'BOARD/components/ContextualMenu/ContextualMenu';
import ResizeKnobs from 'BOARD/components/BoardElement/ResizeKnobs/ResizeKnobs.vue';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import {
  getFeedbackColorForEdition,
  getFeedbackColorForMove,
  getToolTipTextForEdition,
  getToolTipTextForMove,
  DEFAULT_EDITION_FEEDBACK_COLOR,
  getSizeFromDimensions,
  hasProprety,
  getPropertyValue,
  openLink
} from 'BOARD/components/BoardElement/boardElementUtils';
import {
  INTERACTION_MODES,
  DEFAULT_BOARD_ELEMENT_SIZE,
  ToolTypes,
  zoomLevelsArray,
  RESIZE_TYPE
} from 'GLOBALS/constants';
import FormulaWidget from 'BOARD/components/widgets/formula/FormulaWidget.vue';
import ListWidget from 'BOARD/components/widgets/list/ListWidget.vue';
import IndicatorChart from '../IndicatorChart/IndicatorChart.vue';
import BookmarkButton from 'SRC/bookmark/BookmarkButton.vue';
import BoardImage from 'BOARD/components/BoardImage/BoardImage.vue';
import { formatDateToLabel, getDimensionFromSize } from 'BOARD/utils/utils';

export default {
  name: 'BoardElement',
  components: {
    BookmarkButton,
    BoardElementFeedback,
    Movable,
    Card,
    BoardNote,
    ContextualMenu,
    FormulaWidget,
    ListWidget,
    IndicatorChart,
    ResizeKnobs,
    BoardImage,
    EmbedContent
  },
  props: {
    layoutTiles: {
      type: Array,
      //required: true,
      default: () => []
    },
    attributes: {
      type: Object,
      default: () => {}
    },
    state: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: Object,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    scale: {
      type: Number,
      default: 1
    },
    rowsPerPage: {
      type: Number,
      default: 5
    },
    height: {
      type: Number,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true
    },
    elementId: {
      type: String,
      required: true
    },
    selectedInteractionMode: {
      type: String,
      required: true
    },
    dataTypeId: {
      type: String,
      required: true
    },
    send: {
      type: Function,
      default: () => {}
    },
    datasetElementId: {
      type: String,
      required: true
    }
  },
  emits: ['element-drag', 'close'],
  data: () => ({
    INTERACTION_MODES,
    noElementDrag: false,
    DEFAULT_BOARD_ELEMENT_SIZE,
    isColorsPopup: false,
    toggleRefreshState: false,
    zoomValue: 100,
    previousRowsPerPage: 0,
    currentListPage: 1
  }),
  computed: {
    ...mapState('users', ['usersOnBoard', 'currentUser']),
    ...mapState('dock', ['displayComponent']),
    ...mapState('editor', ['filterId']),
    ...mapState('board', [
      'userSelectedElements',
      'highlightedBoardElements',
      'unHighlightedBoardElements',
      'contextualMenuShown',
      'formulaInEditionBoardElementId',
      'clickedElementId',
      'multipleRelations',
      'elements',
      'datasetElements',
      'filters',
      'zoomLevel',
      'indicatorsRelations',
      'userInteractionOnBoardElements'
    ]),
    ...mapGetters('board', ['getSelectedBoardElements']),
    ...mapGetters('app', ['getDataTypeIcon', 'getDataTypeColor', 'getDataTypeName', 'getViewTypeSize']),
    isFreeSize() {
      return this.viewTypes[this.viewTypeId]?.resizable === RESIZE_TYPE.FREE;
    },
    isResizing() {
      return this.elements[this.elementId]?.isResizing;
    },
    additionalDots() {
      const multiRelationsExist =
        this.multipleRelations && Object.values(this.multipleRelations).length > 0;
      if (!multiRelationsExist) {
        return [];
      }
      const hasMultipleRelations = Object.entries(
        this.multipleRelations
      ).filter(([relationId]) => relationId.includes(this.elementId));
      if (!hasMultipleRelations.length) {
        return [];
      }
      const maxDots = Math.max(...hasMultipleRelations.map((el) => el[1].length));
      return [...Array(maxDots).keys()];
    },
    ...mapState('app', ['viewTypes', 'dataTypes']),
    viewTypeId() {
      return this.elements[this.elementId]?.viewTypeId;
    },
    size() {
      if (!this.viewTypeId) {
        return { height: 100, width: 100 };
      }
      if (this.height && this.width) {
        return { height: this.height, width: this.width, scale: this.scale || 1, rowsPerPage: this.rowsPerPage };
      }
      return this.getViewTypeSize(this.viewTypeId);
    },
    elementFields() {
      const dataType = this.dataTypes[this.dataTypeId];
      return this.isGhost
        ? []
        : this.layoutTiles.map((tile) => {
          if (tile.position === 'bottom') {
            const fields = tile.fields;
            return {
              position: 'bottom',
              fields: fields.map((field) => {
                const fieldName = field.name;
                const fieldValue = this.attributes[fieldName];
                const dataTypeAttr = dataType.attributes.find((e) => e.name === fieldName);
                const originRef = dataTypeAttr ? dataTypeAttr.originRef : '';
                return { ...field, value: fieldValue, originRef };
              })
            };
          }
          const fieldName = tile.fieldName;
          const fieldValue = this.attributes[fieldName];
          const dataTypeAttr = dataType.attributes.find((e) => e.name === fieldName);
          const originRef = dataTypeAttr ? dataTypeAttr.originRef : '';
          return { ...tile, value: fieldValue, originRef };
        });
    },
    hasColor() {
      return hasProprety('color', this.dataTypes, this.dataTypeId);
    },
    hasLayout() {
      return hasProprety('layout', this.dataTypes, this.dataTypeId);
    },
    hasOutline() {
      if (this.isUnHighlighted) {
        return false;
      }
      const hasOutlineFlags = [
        this.isInEditionByCurrentUser,
        this.isMoving,
        this.isHighlighted,
        this.isSelected,
        this.isOnlySelectedByOthers
      ];
      return hasOutlineFlags.includes(true);
    },
    style() {
      const initialStyle = {
        height: `${this.size.height}px`,
        width: `${this.size.width}px`
      };
      if (this.hasOutline) {
        return {
          ...initialStyle,
          outline: `${Math.max(2, 2 * (1 / this.zoomLevel))}px ${String(
            this.feedbackColor
          )} solid`
        };
      }
      return { ...initialStyle };
    },
    outlinseStyle() {
      return {
        outline: `${Math.max(2, 2 * (1 / this.zoomLevel))}px ${String(
          this.feedbackColor
        )} solid`
      };
    },
    bgColor() {
      return getPropertyValue('color', this.dataTypes, this.attributes, this.dataTypeId);
    },
    selectedLayout() {
      return getPropertyValue('layout', this.dataTypes, this.attributes, this.dataTypeId);
    },
    contextualMenuItems() {
      return this.viewTypes[this.viewTypeId].contextualMenuItems;
    },
    resizeConfig() {
      return this.viewTypes[this.viewTypeId]?.resize;
    },
    homotheticResize() {
      return this.viewTypes[this.viewTypeId]?.resizable === RESIZE_TYPE.FREE_HOMOTHETIC;
    },
    toolType() {
      return this.viewTypes[this.viewTypeId]?.toolType;
    },
    sizeLayoutItems() {
      const viewTypeId = this.elements[this.elementId].viewTypeId;
      return this.viewTypes[viewTypeId]?.sizeMapLayout;
    },
    isWidget() {
      return !!this.dataTypes[this.dataTypeId]?.namespace.includes(
        '/element-data-types/widgets'
      );
    },
    isEmbedContent() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.POWERBI
      || this.viewTypes[this.viewTypeId].toolType === ToolTypes.WEBCONTENT;
    },
    isBoardNote() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.NOTE;
    },
    isBoardget() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.LIST;
    },
    isIndicator() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.INDICATOR;
    },
    isCard() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.CARD;
    },
    isImage() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.IMAGE;
    },
    isGhost() {
      return this.attributes === null;
    },
    isButton() {
      return this.viewTypes[this.viewTypeId].toolType === ToolTypes.BUTTON;
    },
    isInEdition() {
      return (
        this.getBoardElementModifiers()(this.elementId).length > 0 &&
        this.getBoardElementModifiers()(this.elementId).some(
          (eachUserId) =>
            eachUserId === this.currentUser.id ||
            this.usersOnBoard.map((each) => each.id).includes(eachUserId)
        )
      );
    },
    isInEditionByCurrentUser() {
      if (this.getBoardElementModifiers()(this.elementId)) {
        return (
          this.getBoardElementModifiers()(this.elementId).length > 0 &&
          this.getBoardElementModifiers()(this.elementId).some(
            (eachUserId) => eachUserId === this.currentUser.id
          )
        );
      } else {
        return false;
      }
    },
    isInEditionOnlyByOthers() {
      return (
        this.getBoardElementModifiers()(this.elementId).length > 0 &&
        this.getBoardElementModifiers()(this.elementId).some(
          (eachUserId) => eachUserId !== this.currentUser.id
        )
      );
    },
    isHighlighted() {
      return this.highlightedBoardElements.includes(this.elementId);
    },
    isUnHighlighted() {
      return this.unHighlightedBoardElements.includes(this.elementId);
    },
    isSelected() {
      const selectors = this.getBoardElementSelectors()(this.elementId);
      if (selectors.length > 0) {
        return !!selectors.find((eachUserId) => eachUserId === this.currentUser.id);
      }
      return false;
    },
    isOnlySelectedByOthers() {
      const selectors = this.getBoardElementSelectors()(this.elementId);
      if (selectors.length > 0) {
        return !selectors.find((eachUserId) => eachUserId === this.currentUser.id);
      }
      return false;
    },
    tooltipText() {
      if (this.isInEdition) {
        const boardElementModifiersIds = this.getBoardElementModifiers()(this.elementId);
        if (boardElementModifiersIds.length) {
          const currentUserIsAModifier = boardElementModifiersIds.some(
            (each) => each === this.getCurrentUser().id
          );
          const boardElementModifiers = this.getAllUsers()
            .filter((each) => each.id !== this.getCurrentUser().id)
            .filter((each) => boardElementModifiersIds.includes(each.id));
          return getToolTipTextForEdition(boardElementModifiers, currentUserIsAModifier);
        } else {
          return '';
        }
      } else {
        const boardElementMoversIds = this.getBoardElementMovers()(this.elementId);
        if (boardElementMoversIds?.length) {
          return getToolTipTextForMove(
            boardElementMoversIds[0],
            this.getAllUsers(),
            this.getCurrentUser().id
          );
        } else if (this.isOnlySelectedByOthers) {
          return '';
        } else {
          return '';
        }
      }
    },
    feedbackColor() {
      if (this.isSelected || this.isInEditionByCurrentUser) {
        return DEFAULT_EDITION_FEEDBACK_COLOR;
      }
      if (this.isInEdition) {
        const boardElementModifiersIds = this.getBoardElementModifiers()(this.elementId);
        return getFeedbackColorForEdition(
          boardElementModifiersIds,
          this.getUsersOnBoard(),
          this.getCurrentUser().id
        );
      }
      const boardElementMoversIds = this.getBoardElementMovers()(this.elementId);
      if (
        boardElementMoversIds &&
        boardElementMoversIds.length > 0 &&
        !this.isBeingMovedByThisUser
      ) {
        return getFeedbackColorForMove(boardElementMoversIds, this.getUsersOnBoard());
      }
      const boardElementSelectorsIds = this.getBoardElementSelectors()(this.elementId);
      return getFeedbackColorForEdition(
        boardElementSelectorsIds,
        this.getUsersOnBoard(),
        this.getCurrentUser().id
      );
    },
    isBeingMovedByThisUser() {
      const boardElementsBeingMoved = this.getBoardElementsBeingMoved();
      if (boardElementsBeingMoved && boardElementsBeingMoved[this.elementId]) {
        return (
          boardElementsBeingMoved[this.elementId].filter(
            (eachUserId) => eachUserId === this.getCurrentUser().id
          ).length > 0
        );
      }
      return false;
    },
    isMoving() {
      return (
        Object.keys(this.getBoardElementsBeingMoved()).indexOf(this.elementId) !== -1 &&
        (this.getBoardElementsBeingMoved()[this.elementId] || []).filter(
          (eachUserId) => eachUserId !== this.getCurrentUser().id
        ).length > 0
      );
    },
    boardElementAttributes() {
      return this.elements[this.elementId]?.attributes;
    },
    widgetDatasetElements() {
      if (this.attributes && this.attributes.filter) {
        const datasetElements = [];
        const filter = this.filters[this.attributes.filter];
        if (!filter || !filter.elements.length) {
          return [];
        } else {
          filter.elements.forEach((datasetElement) => {
            const element = this.datasetElements[datasetElement.id];
            if (element) {
              const formattedElement = this.formatDatasetElement(element);
              datasetElements.push(formattedElement);
            }
          });
        }
        return datasetElements;
      }
      return [];
    }
  },
  watch: {
    selectedInteractionMode: {
      immediate: true,
      handler(value) {
        this.noElementDrag = INTERACTION_MODES.SELECT !== value;
      }
    },
    state: {
      deep: true,
      immediate: true,
      handler() {}
    },
    attributes: {
      deep: true,
      immediate: true,
      async handler() {
        if (this.attributes.filter && !this.filters[this.attributes.filter]) {
          await this.fetchFilterResults({ id: this.attributes.filter,
            pageSize: this.boardElementAttributes?.rowsPerPage, page: this.currentListPage });
        }
      }
    }
  },
  methods: {
    formatDatasetElement(element) {
      const user = this.getAllUsers().find(
        (user) => user.id === element.attributes.owner
      );
      const dataTypeName = this.getDataTypeName(element.typeId, element);
      return {
        id: element.id,
        type: {
          text: dataTypeName,
          iconName: this.getDataTypeIcon(element.typeId),
          color: this.getDataTypeColor(element.typeId)
        },
        title: element.attributes.title,
        status: element.attributes.status,
        friendlyId: element.attributes['friendly-id'],
        user: {
          picture: user?.picture,
          altText: user?.firstname[0] + user?.lastname[0] || ''
        },
        initialValue: element.attributes.initialValue || '',
        targetValue: element.attributes.targetValue || '',
        bestInClass: element.attributes.bestInClass || '',
        unit: element.attributes.unit || '',
        timeframe: this.isTimeframe(element)  ? this.getHorizon(element, this.timeframeAttribute(element)) : null
      };
    },
    isTimeframe(element) {
      return this.dataTypes[element.typeId]?.attributes.find((attr) => attr.type === 'timeframe');
    },
    timeframeAttribute(element) {
      if (!this.isTimeframe(element)) {
        return '';
      } else {
        const hasHorizonAttribute = this.dataTypes[element.typeId].attributes.some((attr) => attr.name === 'horizon');
        return hasHorizonAttribute ? 'horizon' : 'due-date';
      }
    },
    getHorizon(element, attribute) {
      if (!element.attributes[attribute]) {
        return 'None';
      }
      return formatDateToLabel(element.attributes[attribute].type, element.attributes[attribute]);
    },
    setIsResizing(value) {
      if (value) {
        this.previousRowsPerPage = this.boardElementAttributes?.rowsPerPage;
      }
      this.updateBoardElement({ elementId: this.elementId, updates: { isResizing: value } });
    },
    onEditClick() {
      this.$emit('edit-click');
    },
    onSizeChange(value) {
      const {text: dimension, layout} = value;
      const sizeListOfLayout = this.sizeLayoutItems?.find((item) => item[layout || this.selectedLayout]);
      const size = getSizeFromDimensions(dimension, sizeListOfLayout && sizeListOfLayout[layout || this.selectedLayout]);
      this.updateBoardElementSize({
        elementId: this.elementId,
        size
      });
    },
    onZoomChange(zoomAction) {
      if (zoomAction) {
        const {action, value} = zoomAction;
        if (action === 'zoomIn') {
          this.zoomIn(value);
        }
        if (action === 'zoomOut') {
          this.zoomOut();
        }
      }
    },
    zoomIn(value) {
      if (value) {
        this.zoomValue = value;

      } else {
        let currentZoomIndex = zoomLevelsArray.indexOf(this.zoomValue);
        if (currentZoomIndex < zoomLevelsArray.length - 1) {
          currentZoomIndex++;
          this.zoomValue = zoomLevelsArray[currentZoomIndex];
        }
      }
    },
    zoomOut() {
      let currentZoomIndex = zoomLevelsArray.indexOf(this.zoomValue);
      if (currentZoomIndex > 0) {
        currentZoomIndex--;
        this.zoomValue = zoomLevelsArray[currentZoomIndex];
      }
    },
    openInNewTab() {
      openLink(this.attributes.url);
    },
    refreshWebContent() {
      this.toggleRefreshState = !this.toggleRefreshState;
    },
    onOpenIndicator() {
      const KPIdatasetElementId = this.indicatorsRelations[this.datasetElementId] ?
        this.indicatorsRelations[this.datasetElementId][0]?.id : undefined;
      if (!KPIdatasetElementId) {
        return;
      }
      this.$emit('open-click', KPIdatasetElementId);
    },
    setViewType(event) {
      const dataTypeName = this.dataTypes[this.dataTypeId].name;
      const viewTypes = Object.entries(this.viewTypes).filter(([, el]) => el.dataTypeName === dataTypeName);
      const clickedIconType = event.name === 'PanelBottom' ? 'restrictedResize' : 'freeResize';
      if (clickedIconType === 'freeResize') {
        const freeResizeViewType = viewTypes.find(([, el]) => el.resizable === RESIZE_TYPE.FREE);
        this.updateBoardElement({elementId: this.elementId, updates: {viewTypeId: freeResizeViewType[0], width: this.size.width * 1.5}});
        return;
      }
      const restrictedResizeViewType = viewTypes.find(([, el]) => el.resizable !== RESIZE_TYPE.FREE);
      this.onSizeChange({text: 'M'});
      this.updateBoardElement({elementId: this.elementId, updates: {viewTypeId: restrictedResizeViewType[0]}});
    },
    onDoubleClick() {
      const isDelete = !this.userSelectedElements?.includes(this.elementId) ;
      if (isDelete) {
        this.removeSelection(this.userSelectedElements);
      }
      if (this.isInEditionByCurrentUser) {
        this.$parent.$parent.$parent.$emit('close');
        this.removeSelection([this.elementId]);
        return;
      }
      if (this.clickedElementId === this.elementId) {
        this.setClickedElementId(null);
        return;
      }
      this.$emit('edit-click');
    },
    closeColorsPopup() {
      this.isColorsPopup = false;
    },
    toggleColorsPopup() {
      this.isColorsPopup = !this.isColorsPopup;
    },
    emitElementDrag(position) {
      this.$emit('element-drag', position);
    },
    onDrag(position) {
      this.emitElementDrag(position);
    },
    onOpenLink() {
      openLink(this.attributes.link);
    },
    ...mapGetters('board', [
      'getBoardElementsBeingMoved',
      'getBoardElementMovers',
      'getBoardElementModifiers',
      'getBoardElementSelectors'
    ]),
    ...mapGetters('users', [
      'getCurrentUser',
      'getAllUsers',
      'getUsersOnBoard'
    ]),
    ...mapMutations('board', ['deleteBoardElement', 'setClickedElementId', 'setFilter', 'updateBoardElement']),
    ...mapActions('board', [
      'removeGlobalSelection',
      'removeSelection',
      'toggleSelectedElementsLockState',
      'deleteUserSelectedElements',
      'addDatasetElementsToStore',
      'updateDatasetElementAttributes',
      'fetchFilterResults',
      'updateBoardElementSize'
    ]),
    updateLayout(item) {
      this.updateElementProprety({layout: item.value});
      const layoutItem = this.sizeLayoutItems?.find((item) => item[this.selectedLayout]);
      const list = layoutItem && layoutItem[this.selectedLayout];
      const dimension = getDimensionFromSize(this.size.width, this.size.height, list);
      this.onSizeChange({text: dimension, layout: item.value});
    },
    updateElementProprety(value) {
      this.updateDatasetElementAttributes({
        datasetElementId: this.datasetElementId,
        attributes: {
          ...this.attributes,
          ...value
        }
      });
      if (value.color) {
        this.closeColorsPopup();
      }
    },
    toggleElementsLockStatus() {
      this.toggleSelectedElementsLockState();
    }
  }
};
</script>

<style>
.BoardElement {
  user-select: none;
  position: absolute;
}

.BoardElement--ghost .BoardElement-content {
  background-color: #dcdcdc;
  opacity: 0.5;
}
</style>
