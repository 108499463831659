import { CHANGE_SOURCE } from 'GLOBALS/constants';
export const getChangeSource = (eventArray, clientId) => {
  let didOtherUserChange = false;
  for (const event of eventArray) {
    const keysChanged = event?.keysChanged;
    for (const keyValue of keysChanged) {
      const sourceId = event.target?.get(keyValue)?._item?.id?.client;
      if (sourceId !== clientId) {
        didOtherUserChange = true;
        break;
      }
    }
    if (didOtherUserChange) {
      return CHANGE_SOURCE.OTHER_USER;
    }
  }
  return CHANGE_SOURCE.CURRENT_USER;
};
