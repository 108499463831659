<template>
  <div
    id="dock"
    class="Dock"
  >
    <div
      v-for="(category, index) in Object.keys(categories).filter(cat => !inactiveDockCategory.includes(cat))"
      :id="`dock-category-${category}`"
      :key="index"
      class="Dock-category"
    >
      <div
        v-for="(toolset, i) in categories[category].filter(cat => !inactiveDockToolset.includes(cat))"
        :key="i"
        class="Dock-tool"
        @click="onToolClick(toolsets[toolset])"
      >
        <iob-icon-shape
          :icon-name="toolsets[toolset].iconName"
          :color="toolsets[toolset].iconColor"
          size="default"
        />
        <div class="Dock-toolType">
          {{ toolsets[toolset].name }}
        </div>
        <outside-click-listener
          v-if="dropdownsToggle[toolsets[toolset].name]"
          @outside-click="handleClickOutsideDropdown(toolsets[toolset].name)"
        >
          <iob-dropdown
            v-if="toolsets[toolset].expandableList && dropdownsToggle[toolsets[toolset].name]"
            class="Dock-toolType-dropdown"
            :items="dropdowns[toolsets[toolset].name]"
            @dropdown-element-item="handleDropdownItemClick($event, toolsets[toolset].name)"
          />
        </outside-click-listener>
      </div>
      <iob-separator
        v-if="index !== isLastCategory"
        is-vertical
        color="secondary"
        style="height: 50px; margin: 5px"
      />
    </div>

    <div v-if="selectedToolsetComponent && components[selectedToolsetComponent]">
      <component
        :is="components[selectedToolsetComponent]"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getUserClickedToolEvent } from 'GLOBALS/events';
import {dockToggle, dockToolsetToggle, FEATURES, ToolTypes} from 'GLOBALS/constants';
import { mapActions } from 'vuex/dist/vuex.cjs.js';
import {isFeatureEnabled} from 'SRC/utils/utils';

export default {
  name: 'ToolsDock',
  components: { },
  data() {
    return {
      dropdowns: [],
      dropdownsToggle: [],
      inactiveDockCategories: []
    };
  },
  computed: {
    ...mapState('dock', ['categories', 'toolsets']),
    ...mapState('board', ['openedComponents']),
    ...mapState('app', ['viewTypes', 'featuresList']),
    isLastCategory() {
      return Object.keys(this.categories).filter((cat) => !this.inactiveDockCategory.includes(cat)).length - 1;
    },
    inactiveDockCategory() {
      return dockToggle;
    },
    inactiveDockToolset() {
      return dockToolsetToggle;
    }
  },
  methods: {
    ...mapMutations('dock', ['setCreationComponent', 'setDisplayComponent', 'setDataTypeId', 'setViewTypeId']),
    ...mapMutations('board', [
      'setOpenedComponents'
    ]),
    ...mapActions('editor', [
      'closeEditor'
    ]),
    openCreationCompnent(item, displayComponent, creationComponent) {
      this.setViewTypeId(item.viewTypeId);
      this.setDataTypeId(item.viewType.dataTypeId);
      this.setDisplayComponent(displayComponent);
      this.setCreationComponent(creationComponent);
      if (!this.openedComponents.includes(creationComponent)) {
        const openedComponents = this.openedComponents;
        openedComponents.push(creationComponent);
        this.setOpenedComponents(openedComponents);
      }
    },
    handleClickOutsideDropdown(toolsetName) {
      this.dropdownsToggle[toolsetName] = false;
    },
    async createElementOnBoard(viewType) {
      document.dispatchEvent(getUserClickedToolEvent({viewType}));
    },
    onToolClick(toolset) {
      const isWebContentToolset = isFeatureEnabled(this.featuresList, FEATURES.WEB_CONTENT) && toolset.viewType &&
        (toolset.viewType.toolType === ToolTypes.POWERBI || toolset.viewType.toolType === ToolTypes.WEBCONTENT);
      if (toolset.expandableList && !isWebContentToolset) {
        this.dropdownToggle = !this.dropdownsToggle;
        this.dropdownsToggle[toolset.name] = true;
        const expandableDropdown = toolset.expandableList.map((item) => ({
          name: item.name,
          text: item.name, state: 'default', iconName: item.iconName,
          iconSize: 'default', type: 'menu', size: 'xs', color: item.color,
          viewTypeId: item.viewTypeId,
          viewType: item.viewType
        }));
        this.dropdowns[toolset.name] = expandableDropdown;
      }
      if (toolset.viewType && toolset.viewType.toolType === ToolTypes.CARD) {
        this.openCreationCompnent(toolset, 'Card', 'CardCreationModal');
      }
      if (toolset.viewType && toolset.viewType.toolType === ToolTypes.NOTE) {
        this.closeEditor();
        this.createElementOnBoard({id: toolset.viewTypeId, ...toolset.viewType});
      }
      if (toolset.viewType && toolset.viewType.toolType === ToolTypes.IMAGE) {
        this.openCreationCompnent(toolset, 'Image', 'ImageCreation');
      }
      if (isWebContentToolset) {
        this.openCreationCompnent(toolset, 'EmbedCreationModal', 'EmbedCreationModal');
      }
    },
    handleDropdownItemClick(event, toolsetName) {
      if (event.item.viewType && event.item.viewType.dataTypeName === 'KPI') {
        const indicatorViewType = Object.entries(this.viewTypes).find(([, viewType]) => viewType.dataTypeName === 'Indicator');
        this.createElementOnBoard({id: indicatorViewType[0], ...indicatorViewType[1]});
      } else if (event.item.viewType && event.item.viewType.toolType === ToolTypes.LIST) {
        this.openCreationCompnent(event.item, 'BoardgetList', 'ListBoardgetModal');
      }
      //TODO clean when web content feature is enabled
      if (event.item.viewType && event.item.viewType.toolType === ToolTypes.POWERBI) {
        this.setViewTypeId(event.item.viewTypeId);
        this.setDataTypeId(event.item.viewType.dataTypeId);
        this.setCreationComponent('EmbedCreationModal');
        if (!this.openedComponents.includes('EmbedCreationModal')) {
          const openedComponents = this.openedComponents;
          openedComponents.push('EmbedCreationModal');
          this.setOpenedComponents(openedComponents);
        }
      }
      if (event.item.viewType && event.item.viewType.toolType === ToolTypes.BUTTON) {
        this.openCreationCompnent(event.item, 'BookmarkButton', 'BookmarkButtonCreationModal');
      }
      // should add embed logic here as we did for boardget
      this.dropdowns[toolsetName] = false;
      this.dropdownsToggle[toolsetName] = false;
    }
  }
};
</script>

<style scoped src="./Dock.css"></style>
