<template>
  <div class="MainMenu">
    <iob-header-nav
      :picture="getCurrentUserPicture"
      :alt-text="currentUser && getUserInitials(currentUser.firstname, currentUser.lastname)"
      :show-left="true"
      :show-right="true"
      :show-collaborators="true"
      :page-title="boardData.name"
      room-title=""
      :menu-btn="false"
      :return-btn="true"
      :more-btn="true"
      :show-middle="true"
      :disable-undo="false"
      :disable-redo="false"
      :info-btn="false"
      :notification-btn="false"
      :favorite-btn="false"
      :dropdown-items="items"
      :collaborators="parseUsers"
      :level="getLevelName(boardData.level)"
      :max-displayed-collorators="maxDisplayedPeopleOnBoard"
      @redo-btn-action="redoAction"
      @undo-btn-action="undoAction"
      @dropdown-element-click="processMultiLevelClick"
      @return-btn-action="handleReturnAction"
    />
    <div class="MainMenu-alertNotification theme-dark">
      <iob-notification
        v-if="addToListDialogBoxContent.isNotificationDisplayed"
        :title="addToListDialogBoxContent.title"
        :description="addToListDialogBoxContent.message"
        primary-button="View Element"
        icon-name="Bell"
        icon-color="primary"
        @close="closeNotification"
        @primary-button-click="addToListDialogBoxContent.callbackfn"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { getUserInitials } from 'SRC/utils/collab-utils';
import { undo, redo } from 'SRC/utils/collab';
import { VERSION_MODES, DEFAULT_AVATAR_TEXT_COLOR, VERSION_DROPDOWN_MENU_ITEMS, avatarDropdownItems } from 'GLOBALS/constants.js';

export default {
  name: 'MainMenu',
  data: () => ({
    avatarDropdownItems,
    maxDisplayedPeopleOnBoard: 5,
    defaultAvatarTextColor: DEFAULT_AVATAR_TEXT_COLOR,
    items: VERSION_DROPDOWN_MENU_ITEMS
  }),
  computed: {
    ...mapState('users', [
      'usersOnBoard',
      'currentUser',
      'currentUserColor'
    ]),
    ...mapState('board', ['canUndo', 'canRedo', 'openedComponents', 'dialogBoxFlags', 'boardData', 'notifications']),
    ...mapGetters('app', ['getLevelName']),
    addToListDialogBoxContent() {
      return this.notifications.addElementToListNotification;
    },
    disableUndo() {
      return !this.canUndo;
    },
    disableRedo() {
      return !this.canRedo;
    },
    parseUsers() {
      return this.usersOnBoard ? this.usersOnBoard.map((user) => ({
        id: user.id,
        altText: getUserInitials(user.firstname, user.lastname),
        firstname: user.firstname,
        lastname: user.lastname,
        color: user.color,
        colorBottom: true,
        textColor: user.textColor,
        image: user.image,
        size: 'large'
      })) : [];
    },
    peopleOnBoard() {
      return this.usersOnBoard ? this.usersOnBoard : [];
    },
    getCurrentUserPicture() {
      return this.currentUser && this.currentUser.picture !== null ? this.currentUser.picture : '';
    }
  },
  mounted() {
    const query = this.$route.query;
    if (query?.openSettings) {
      this.toggleComponents('BoardSettings');
    }
  },
  methods: {
    ...mapActions('board', [
      'zoomIn',
      'zoomOut'
    ]),
    ...mapMutations('board', [
      'setOpenedComponents',
      'setMode',
      'setDialogBoxFlags',
      'setNotificationsInfo'
    ]),
    closeNotification() {
      this.setNotificationsInfo({
        ...this.notifications,
        addElementToListNotification: {
          ...this.notifications.addElementToListNotification,
          isNotificationDisplayed: false
        }
      });
    },
    createVersion() {
      this.setMode(VERSION_MODES[0]);
      this.setDialogBoxFlags({...this.dialogBoxFlags, isCreateVersionDialogOpened: true});
    },
    history() {
      this.setDialogBoxFlags({...this.dialogBoxFlags, isHistoryDialogOpened: true});
    },
    getUserInitials(firstname, lastname) {
      return getUserInitials(firstname, lastname);
    },
    undoAction() {
      undo();
    },
    redoAction() {
      redo();
    },
    processMultiLevelClick({ action, source }) {
      this[action](source);
    },
    toggleComponents(componentName) {
      if (!this.openedComponents.includes(componentName)) {
        const openedComponents = this.openedComponents;
        openedComponents.push(componentName);
        this.setOpenedComponents(openedComponents);
      }
    },
    handleReturnAction() {
      this.$router.push('/boards');
    }
  }
};
</script>

<style scoped src='./MainMenu.css' />
