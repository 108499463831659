<template>
  <dialog-box
    :dialog-box-specific-style="dialogBoxStyle"
    :no-padding="true"
    :has-close-icon="true"
    has-submit-group
    :disabled="isSaveDisabled"
    :submitted="isSubmitted"
    @submit-click="onSubmit"
    @cancel-click="closeToolsConfig"
    @close-icon-click="closeToolsConfig"
  >
    <tools-config-header
      :set-title="setCardName"
      :title="cardName"
    />
    <div class="ToolsConfig-horizontal-divider" />
    <div class="ToolsConfig-content">
      <tools-config-tabs
        :tabs="tabs"
        :set-selected-tab="setSelectedTab"
        :selected-tab="selectedTabIndex"
      />
      <div class="ToolsConfig-verticalDivider" />
      <div
        id="toolsConfigCustomizer"
        class="ToolsConfig-customizer"
      >
        <component :is="components[selectedTabIndex]" />
      </div>
    </div>
  </dialog-box>
</template>

<script>
import ToolsConfigTabs from './ToolsConfigTabs/ToolsConfigTabs.vue';
import CardCustomization from './Customization/Card/CardCustomization.vue';
import FieldsCustomization from './Customization/Fields/FieldsCustomization.vue';
import HierarchyCustomization from './Customization/Hierarchy/HierarchyCustomization.vue';
import ToolsConfigHeader from './ToolsConfigHeader/ToolsConfigHeader.vue';
import { mapMutations, mapState, mapActions } from 'vuex';
import viewType from './staticData/viewType.json';
import dataType from './staticData/dataType.json';
import {
  MAX_DISPLAYED_VIEWTYPES
} from 'GLOBALS/constants.js';
import {
  addDefalutColor,
  pipeAsyncFunctions,
  generateDataTypeBody,
  createDataType,
  generateMappings,
  generateViewTypeBody,
  createViewType,
  generateHierarchyRequestBody,
  createHierarchiecalRelations,
  refreshData,
  createFormulas,
  getAllFormulas,
  resetFieldsValue,
  closeToolsConfigModal,
  resetSelectedTab,
  createSummaryFormulas,
  updateParents,
  resetToolsState,
  manageNotifications
} from './utils';

export default {
  name: 'ToolsConfigModal',
  components: {
    ToolsConfigTabs,
    CardCustomization,
    FieldsCustomization,
    HierarchyCustomization,
    ToolsConfigHeader
  },
  data() {
    return {
      dialogBoxStyle: 'position: absolute; width: 50%; height: 60%; left: 24%; top: 24%;',
      tabs: [
        { label: 'Layout and color', icon: 'Card' },
        { label: 'Fields', icon: 'CardFields' },
        { label: 'Relations', icon: 'Network' }
      ],
      components: [
        CardCustomization,
        FieldsCustomization,
        HierarchyCustomization
      ],
      dataType,
      viewType,
      title: '',
      formulaId: null,
      isSaveDisabled: false,
      isSubmitted: false,
      maxDisplayedViewTypes: MAX_DISPLAYED_VIEWTYPES
    };
  },
  computed: {
    ...mapState('board', ['dialogBoxFlags', 'notifications']),
    ...mapState('app', ['layouts', 'viewTypes']),
    ...mapState('toolsConfig', [
      'selectedTabIndex',
      'fields',
      'hierarchy',
      'selectedLayout',
      'fieldPlacement',
      'cardName',
      'defaultColor'
    ]),
    customAttributes() {
      const customFields = this.fields
        .filter((field) => field.value !== 'Title')
        .map((field) => {
          const elem = field.details;
          const options = elem.enum;
          if (options) {
            elem.enum = options.map((option, index) => ({
              id: index,
              value: option.value
            }));
          }
          return elem;
        });
      return customFields;
    },
    isNotificationDisplayed() {
      let count = 0;
      if (this.viewTypes) {
        Object.values(this.viewTypes).forEach((viewType) => {
          if (!viewType.hidden) {
            count++;
          }
        });
        if (count >= this.maxDisplayedViewTypes - 1) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    if (!this.layouts) {
      this.fetchLayouts();
    }
  },
  methods: {
    ...mapMutations('board', ['setDialogBoxFlags', 'setNotificationsInfo']),
    ...mapMutations('toolsConfig', ['setSelectedTabIndex', 'resetFields', 'setCardName']),
    ...mapActions('app', ['fetchViewTypes', 'fetchDataTypes']),
    ...mapActions('toolsConfig', ['addFormula', 'resetState']),
    ...mapActions('app', ['fetchFormulas', 'fetchLayouts']),
    setTitle(value) {
      this.title = value;
    },
    closeToolsConfig() {
      this.resetState(this.layouts[0].id);
      this.setDialogBoxFlags({
        ...this.dialogBoxFlags,
        isToolsConfigDialogOpened: false
      });

    },
    setSelectedTab(value) {
      this.setSelectedTabIndex(value);
    },
    async onSubmit() {
      this.isSaveDisabled = true;
      this.isSubmitted = true;
      pipeAsyncFunctions(
        createFormulas,
        addDefalutColor,
        generateDataTypeBody,
        createDataType,
        generateMappings,
        generateViewTypeBody,
        createViewType,
        generateHierarchyRequestBody,
        createHierarchiecalRelations,
        createSummaryFormulas,
        updateParents,
        refreshData,
        getAllFormulas,
        closeToolsConfigModal,
        manageNotifications,
        resetFieldsValue,
        resetToolsState,
        resetSelectedTab
      )(this);

    }
  }
};
</script>
<style scoped src="./ToolsConfig.css" />
