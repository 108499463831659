import {POWERBI_URL_PATTERNS, URL_PATTERN} from 'GLOBALS/constants';
import parse from 'BOARD/components/Embed/powerbi/utils/parser';

export const pick = (obj, keys) => keys.reduce((acc, change) => {
  acc[change] = obj[change];
  return acc;
}, {});
export const isFeatureEnabled = (featuresList, featureName) => {
  const feature = featuresList.find((feature) => feature.name === featureName);
  return feature && feature.enabled;
};
export const isValidUrl = (url) => {
  // Test if is powerBi report or dashboard or visual or app
  if (POWERBI_URL_PATTERNS.basePattern.test(url) || POWERBI_URL_PATTERNS.appPattern.test(url)) {
    return parse(url) != null;
  }
  return URL_PATTERN.test(url);
};

export function useDebounce(fn, delay) {
  let cancelFn;
  const cancelPromise = new Promise((resolve) => {
    cancelFn = resolve;
  });

  return { debounce: debounce(fn, delay, cancelPromise), cancelFn };
}

export const debounce = (fn, delay, cancelPromise = null) => {
  let debounceTimer;
  cancelPromise?.then(() => {
    clearTimeout(debounceTimer);
  });

  return function (...args) {
    const context = this;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};
