<template>
  <div class="FieldsCustomization">
    <scroll-bar
      id="scroll-container"
      :scroll-container-style="scrollContainerStyle"
    >
      <div class="flex">
        <iobeya-fieldset
          id="fieldset-customization"
          :items="fields"
          border-color="border-gray"
          mode="gray"
          shape="rounded"
          outlined
          @end-icon-click="executeEndIconAction"
        />
        <outside-click-listener
          @outside-click="handleClickOutsideFieldMenu"
        >
          <multi-level-menu
            v-if="isFieldMenuOpened"
            :items="fieldMenu"
            :multi-levelmenu-style="fieldMenuStyle"
            @click="processFieldMenuClick"
          />
        </outside-click-listener>
      </div>
    </scroll-bar>
    <div>
      <custom-button
        id="add-field"
        label="Add field"
        mode="gray"
        shape="rounded"
        bg-color="#F3F3F4"
        border-color="border-gray"
        outlined
        :button-style="buttonStyle"
        class="pb-3"
        @click="toggleSearchMenu"
      />
      <outside-click-listener
        @outside-click="handleClickOutsideSearchMenu"
      >
        <search-menu
          v-if="isSearchMenuOpen"
          v-model="selectedType"
          :options="fieldTypes"
          :input-data="searchInput"
          :search-menu-style="searchMenuStyle"
          options-container-height="120"
          @click-item="openAddField"
        />
      </outside-click-listener>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { SEARCH_MENU_HEIGHT, FIELD_MENU_ITEMS } from 'GLOBALS/constants.js';

export default {
  name: 'FieldsCustomization',
  data() {
    return {
      isSearchMenuOpen: false,
      searchInput: {
        placeholder: 'Search for a type...',
        startIcon: 'Search',
        value: '',
        style: 'width: 100%'
      },
      target: null,
      scrollContainerStyle: 'overflow: hidden;',
      buttonStyle: 'position: fixed;',
      searchMenuStyle: `margin-top: 10%; max-height: ${SEARCH_MENU_HEIGHT}px`,
      isFieldMenuOpened: false,
      currentFieldId: null,
      fieldMenu: FIELD_MENU_ITEMS,
      fieldMenuStyle: ''
    };
  },
  computed: {
    ...mapState('board', ['dialogBoxFlags']),
    ...mapState('toolsConfig', ['fields', 'fieldTypes'])
  },
  mounted() {
    this.updateStyles();
  },
  methods: {
    ...mapMutations('board', ['setDialogBoxFlags']),
    ...mapMutations('toolsConfig', ['setFieldTypebeingManaged', 'updateFields', 'setFieldTypeId']),
    executeEndIconAction(id) {
      const field = this.fields.find((field) => field.id === id);
      if (field && field.value !== 'Title') {
        this.openFieldMenu(id);
      }
    },
    toggleSearchMenu(event) {
      this.isSearchMenuOpen = !this.isSearchMenuOpen;
      this.target = event.target;
    },
    closeSearchMenu(target) {
      if (target === document.getElementById('add-field')) {
        return;
      }
      this.isSearchMenuOpen = false;
    },
    handleClickOutsideSearchMenu() {
      if (this.target !== document.getElementById('add-field')) {
        this.closeSearchMenu();
      }
      this.target = null;
    },
    openAddField(data) {
      const { event, item } = data;
      this.closeSearchMenu(event.target);
      this.setDialogBoxFlags({
        ...this.dialogBoxFlags,
        isAddFieldDialogOpened: true,
        isToolsConfigDialogOpened: false
      });
      this.setFieldTypebeingManaged({value: item});
    },
    updateStyles() {
      const fieldsCustomizationHeight = document
        .getElementById('toolsConfigCustomizer')
        .getBoundingClientRect().height;
      const fieldsetHeight = document
        .getElementById('fieldset-customization')
        .getBoundingClientRect().height;
      const heightRatio = fieldsetHeight / fieldsCustomizationHeight;
      if (heightRatio > 0.64) {
        this.scrollContainerStyle = 'max-height: 65%;';
        const buttonGroupBoundingRectTop = document
          .getElementsByClassName('button-group')[0]
          .getBoundingClientRect().top;

        this.buttonStyle = `position: fixed; top: ${buttonGroupBoundingRectTop}px`;
        this.searchMenuStyle = `position: fixed; top: ${buttonGroupBoundingRectTop - SEARCH_MENU_HEIGHT}px`;
      }
    },
    openFieldMenu(id) {
      this.currentFieldId = id;
      this.updateFieldMenuStyle(id);
      this.isFieldMenuOpened = true;
    },
    updateFieldMenuStyle(id) {
      const fieldBoundingRectTop = document
        .getElementById(id)
        .getBoundingClientRect().top;
      this.fieldMenuStyle = `top: ${fieldBoundingRectTop}px; position: fixed; margin-left: -22px;`;
    },
    deleteField() {
      let fields = this.fields;
      const fieldToRemove = this.fields.find(
        (field) => field.id === this.currentFieldId
      );
      const fieldToRemoveIndex = fields.indexOf(fieldToRemove);
      fields = fields.filter((field) => field.id !== this.currentFieldId);
      fields = this.updateFieldsIds(fields, fieldToRemoveIndex);
      this.updateFields(fields);
      this.isFieldMenuOpened = false;
    },
    editField() {
      this.setDialogBoxFlags({
        ...this.dialogBoxFlags,
        isAddFieldDialogOpened: true,
        isToolsConfigDialogOpened: false
      });
      this.setFieldTypeId(this.currentFieldId);
      const currentField = this.fields.find((field) => field.id === this.currentFieldId);
      const fieldTypebeingManaged = {};
      fieldTypebeingManaged.iconName = currentField.startIcon;
      fieldTypebeingManaged.label = currentField.fieldType;
      this.setFieldTypebeingManaged({value: fieldTypebeingManaged});
    },
    updateFieldsIds(fields, removedFieldIndex) {
      return fields.map((field, index) => {
        const element = field;
        if (field.id && index >= removedFieldIndex) {
          element.id = `custom-field-${index}`;
        }
        return element;
      });
    },
    processFieldMenuClick({ source, action }) {
      this[action](source);
    },
    handleClickOutsideFieldMenu(e) {
      const endIconElement = document.getElementById(`end-icon-${this.currentFieldId}`);
      if (endIconElement && endIconElement.contains(e.target)) {
        return;
      }
      this.isFieldMenuOpened = false;
    }
  }
};
</script>

<style scoped>
.FieldsCustomization {
  padding: 25px;
}

.FieldsCustomization-actions {
  margin-top: 15px;
}
</style>
