<template>
  <dialog-box :dialog-box-specific-style="dialogBoxStyle">
    <div class="History-header">
      <span class="History-title"> History </span>
      <icon-loader
        name="X"
        stroke="black"
        color="black"
        @click="close"
      />
    </div>
    <div class="History-tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        @click="setCurrentTabIndex(index)"
      >
        <span :class="['History-tab', { 'History-tab--active': currentTabIndex === index }]">{{ tab }}</span>
        <component
          :is="specificComponents[tab]"
          v-if="currentTabIndex === index"
        />
      </div>
    </div>
  </dialog-box>
</template>

<script>
import BoardHistory from './BoardHistory/BoardHistory.vue';
import RoomComponent from './Room/Room.vue';
import {mapMutations, mapState } from 'vuex';

export default {
  name: 'HistoryComponent',
  components: { BoardHistory },
  data: () => ({
    tabs: ['Room', 'Board'],
    currentTabIndex: 1,
    dialogBoxStyle: 'position: absolute; width: 40%; height: 60%; left: 29%; top: 22%;',
    specificComponents: {
      Board: BoardHistory,
      Room: RoomComponent
    }
  }),
  computed: {
    ...mapState('board', ['dialogBoxFlags']),
    currentTabName() {
      return this.tabs[this.currentTabIndex];
    }
  },
  methods: {
    ...mapMutations('board', [
      'setDialogBoxFlags'
    ]),
    close() {
      this.setDialogBoxFlags({...this.dialogBoxFlags, isHistoryDialogOpened: false});
    },
    setCurrentTabIndex(tabIndex) {
      this.currentTabIndex = tabIndex;
    }
  }
};
</script>
<style src="./History.css" />
