import { API_URL } from 'SRC/config/api.js';
import store from 'SRC/store';
import AppMutations from 'SRC/store/app/mutations';
import CircuitBreaker from 'opossum';
import { makeRequest} from '../utils/CircuitBreaker';
function getAuthHeader() {
  const token = store.getters['auth/getToken'] || '';
  return {
    'Authorization': `Bearer ${token}`
  };
}

function getUserHeader() {
  const user = store.getters['users/getCurrentUser'] || null;
  if (!user) {
    return;
  }
  return {
    'X-iObeya-VMS-User-Id': `${user.id}`
  };
}

function fetcher(method) {
  const fileFetch = (url, options, body) => {
    options.body = body;
    delete options.headers['Content-Type'];
    return fetch(url, options);
  };
  return async (url, body = null, toJson = true, isRelative = true, interrupts = false, isFile = false) => {
    const requestUrl = isRelative ? `${API_URL}${url}` : `${url}`;
    try {
      const options = {
        method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'X-iObeya-VMS-Collab-Session': store.state.board.id,
          'X-iObeya-VMS-API-Returns-Self-Url': 'relative',
          ...getAuthHeader(),
          ...getUserHeader()
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: body ? JSON.stringify(body) : null
      };

      const response = isFile ? await fileFetch(requestUrl, options, body) : await fetch(requestUrl, options);

      if (response.status === 204) {
        return true;
      }

      if (toJson) {
        const res = await response.json();
        if (res) {
          if (res.error) {
            throw new Exception(res.status, res.error, null, res.path);
          } else if (res.title) {
            throw new Exception(res.status, res.type, res.title, res.detail);
          } else if (res.details) {
            throw new Exception(null, null, null, res.details);
          }
        }
        return res;
      }
      return true;
    } catch (exception) {
      if (interrupts) {
        const exceptions = store.state.app.exceptions;
        exceptions.push(exception);
        AppMutations.setExceptions(store.state.app, exceptions);
        throw exception;
      }
      throw exception;
    }
  };
}

class Exception {
  constructor(status, type, title, detail) {
    this.status = status;
    this.type = type;
    this.title = title;
    this.detail = detail;
  }
}

const userOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 10000
};
const vmsOptions = {
  errorThresholdPercentage: 50,
  resetTimeout: 5000,
  volumeThreshold: 2,
  timeout: 20000
};
export const vmsCircuitBreaker = new CircuitBreaker(fetcher('GET'), vmsOptions);
export const userCircuitBreaker = new CircuitBreaker(fetcher('GET'), userOptions);
export const get = async (url, body, toJson, isRelative, circuitBreakerInfo = {}) => {
  const {isActivated = true, interrupts, isUsersAPI} = circuitBreakerInfo;

  if (!isActivated) {
    return fetcher('GET')(url, body, toJson, isRelative, circuitBreakerInfo = {});
  }
  if (isUsersAPI) {
    return makeRequest(userCircuitBreaker, url, body, toJson, isRelative, interrupts);
  }
  return makeRequest(vmsCircuitBreaker, url, body, toJson, isRelative, interrupts);
};

export const patch = fetcher('PATCH');
export const post = fetcher('POST');
export const put = fetcher('PUT');
export const del = fetcher('DELETE');

export default {
  get,
  patch,
  post,
  put,
  del
};
