import actions from './actions';
import mutations from './mutations';

const AuthModule = {
  namespaced: true,
  state: {
    authenticated: false,
    token: null,
    headers: null
  },
  getters: {
    isAuthenticated(state) {
      return state.authenticated;
    },
    getToken(state) {
      return state.token;
    },
    getHeaders(state) {
      return state.headers;
    }
  },
  mutations,
  actions
};

export default AuthModule;
