<template>
  <div class="CustomFieldMapper-container">
    <div
      v-for="(placement, index) in tiles"
      :key="`${index}${placement.tileName === openedTileCustomization ? openedTileCustomization+placement.tileName : placement.tileName}`"
      :style="'width: 40%'"
    >
      <div
        v-if="fieldPlacement[placement.tileName]"
        class="relative inline-block"
      >
        <label-field
          :id="`field${placement.tileName}`"
          :is-clicked="openedTileCustomization === placement.tileName"
          :label="placement.label"
          :input-style="fieldPlacement[placement.tileName].style"
          :start-icon="fieldPlacement[placement.tileName].startIcon"
          end-icon="ChevronDown"
          :model-value="fieldPlacement[placement.tileName].friendlyName"
          :disabled="fieldPlacement[placement.tileName].disabled"
          shape="rounded"
          mode="gray"
          size="base"
          border-color="border-gray"
          outlined
          @update:modelValue="fieldPlacement[placement.tileName].value = $event"
        />
        <div
          class="absolute mt-7 inset-0 flex items-center mb-3 mr-6"
          @click="(event) => setOpenedTileCustomization(placement.tileName, event)"
        />
      </div>
      <outside-click-listener
        v-if="openedTileCustomization === placement.tileName && fields"
        :style="searchMenuStyle"
        class="CustomFieldMapper-outsideClickListener"
        @outside-click="resetOpenedTileCustomization"
      >
        <search-menu
          id="searchMenuC"
          :search-field="true"
          :options="customFields"
          :search-menu-style="'width: 250px'"
          options-container-height="120"
          :selected-item="fieldPlacement[placement.tileName]"
          @click-item="({item}) => setSelectedField(placement, item)"
        >
          <div
            class="CustomFieldMapper-AddField hover:bg-gray-100"
            @click="openAddField(placement)"
          >
            + Add a new field
          </div>
        </search-menu>
      </outside-click-listener>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'CustomFieldMapper',
  props: {
    tiles: {
      type: Array,
      required: true
    },
    scrollPosition: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      openedTileCustomization: null,
      searchMenuStyle: '',
      customFields: [],
      defaultValue: {value: 'None', friendlyName: 'None', startIcon: 'Ban', disabled: true}
    };
  },
  computed: {
    ...mapState('toolsConfig', ['fields', 'fieldTypes', 'fieldPlacement'])
  },
  watch: {
    scrollPosition: {
      handler(value) {
        this.getSearchMenuStyle(value, this.openedTileCustomization);
      },
      immediate: true
    },
    openedTileCustomization: {
      handler(value) {
        this.getSearchMenuStyle(this.scrollPosition, value);
      },
      immediate: true
    },
    tiles: {
      handler(value) {
        const fp = value.reduce((acc, tile) => {
          acc[tile.tileName] = this.fieldPlacement[tile.tileName] ? this.fieldPlacement[tile.tileName] : this.defaultValue;
          return acc;
        }, {});
        this.setFieldPlacement(fp);
      },
      immediate: true
    },
    fields: {
      handler(value) {
        this.customFields = value.filter((field) => field.value !== 'Title').map((field) => ({
          iconName: field.startIcon,
          label: field.value,
          type: field.details.type,
          name: field.details.name
        }));
        this.customFields.unshift({
          iconName: this.defaultValue.startIcon,
          label: this.defaultValue.value,
          friendlyName: this.defaultValue.friendlyName
        });
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('toolsConfig', ['setFieldPlacement', 'setFieldTypebeingManaged']),
    ...mapMutations('board', ['setDialogBoxFlags']),
    getEndIcon(placementName) {
      const icon = this.openedTileCustomization === placementName ? 'ChevronUp' : 'ChevronDown';
      return icon;
    },
    openAddField(value) {
      this.setDialogBoxFlags({
        ...this.dialogBoxFlags,
        isAddFieldDialogOpened: true,
        isToolsConfigDialogOpened: false
      });
      this.setFieldTypebeingManaged({value: this.fieldTypes[0], tile: value });
    },
    getSearchMenuStyle(scrollPosition, openedTileCustomization) {
      const sField = document.querySelector(`#field${openedTileCustomization}`);
      if (sField) {
        const container = document.querySelector('#cardCustomization-Container');
        const cgclbdr = container.getBoundingClientRect();
        if (sField.getBoundingClientRect().top > (cgclbdr.top + cgclbdr.height)) {
          return this.searchMenuStyle;
        }
      }
      const tr = -(scrollPosition || 0) - 20;
      this.searchMenuStyle = `transform: translateY(${tr}px)`;
    },
    setSelectedField(placement, item) {
      this.setFieldPlacement({
        ...this.fieldPlacement,
        [placement.tileName]: {
          friendlyName: item.label,
          startIcon: item.iconName,
          value: item.name,
          disabled: true,
          type: item.type
        }
      });
      this.resetOpenedTileCustomization();
    },
    setOpenedTileCustomization(value, event) {
      event.stopPropagation();
      if (this.openedTileCustomization === value) {
        this.resetOpenedTileCustomization();
        return;
      }
      this.openedTileCustomization = value;
    },
    resetOpenedTileCustomization() {
      this.openedTileCustomization = null;
    }
  }
};
</script>

<style scoped>
.CustomFieldMapper-container {
  margin-top: 5%;
}

.CustomFieldMapper-outsideClickListener {
  position: fixed;
  z-index: 45;
}
.CustomFieldMapper-AddField {
  height: 40px;
  font-family: 'Noto Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #004AFA;
  border-top: 2px #D9D9D9 solid;
  padding: 0.75rem;
  margin-top: 4px;
}
</style>
