<template>
  <div class="ListBoardgetEditor">
    <div class="ListBoardgetEditor-form">
      <div class="ListBoardgetEditor-form-header">
        <span class="ListBoardgetEditor-form-header-title">
          Settings
        </span>
        <iob-action-button
          icon="close"
          size="default"
          color="secondary"
          type="ghost"
          @click="closeSettings"
        />
      </div>
      <div class="ListBoardgetEditor-form-content">
        <div class="ListBoardgetEditor-form-content-label">
          <label for="board-name">Name</label>
        </div>
        <iob-label-field
          :model-value="title"
          placeholder="Type a name"
          class="ListBoardgetEditor-form-content-input"
          has-clear-button
          @update:modelValue="(value) => handleChange('title', value.trim())"
        />
      </div>
      <div
        class="ListBoardgetEditor-form-filters"
      >
        <iob-filter-modal
          :style="filterStyle"
          :has-separators="false"
          :current-user="currentUser"
          :items="items"
          :initial-selected-items="selectedItems"
          :initial-other-filters="otherFilters"
          :initial-quick-filters="quickFilters"
          :date-picker-styles="datePickerStyles"
          :has-clear-button="false"
          @update:inputValue="searchDatasetElements"
          @setFilter="handleSetFilters"
        />
      </div>
      <div class="ListBoardgetEditor-form-footer" />
    </div>
  </div>
</template>

<script setup>
import {  computed, defineEmits, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { getUserInitials } from 'SRC/utils/collab-utils';
import { debounce } from 'SRC/utils/utils.js';
import { formatDateToLabel } from 'BOARD/utils/utils';
import { QUICK_FILTERS_KEYS } from 'GLOBALS/constants';
import { getOtherFiltersDates, compareDates, fetchRelatedElements, getCurrentWeekDates } from './ListBoardgetEditorUtils';

const filterStyle = {
  boxShadow: 'none',
  padding: '0px',
  width: '100%',
  minWidth: 'auto',
  gap: '32px',
  backgroundColor: '#FFF'
};
const datePickerStyles = 'transform: translate(-373px);position: absolute;top: -111px;left: -8px';
const attributes = ref([]);
const relatedSearchQuery = ref('');
const selectedRelatedElements = [];

const filterResult = computed(() => store.state.editor.filterResult);
const store = useStore();
const filterId = computed(() => datasetElement.value ? datasetElement.value.attributes.filter : null);
onMounted(async () => {
  await store.dispatch('editor/fetchElementsFilter', filterId.value);
  const dataTypeId = filterResult.value.dataTypeId;
  await store.dispatch('dock/fetchDatasetElements',  { dataTypeId });
  const datasetElementsIds = filterResult.value.relatedElementIds;
  selectedRelatedElements.value = await getRelatedElements(datasetElementsIds);
  attributes.value = combineAttributes();
});
const getRelatedElements = async (datasetElementIds) => {
  if (!datasetElementIds || !datasetElementIds.length) {
    return [];
  }
  const elements = await fetchRelatedElements(datasetElementIds);
  const selectedRelatedElements = elements.map((element) => ({id: element.id, title: element.attributes.title,
    value: element.attributes['friendly-id'], shapeColor: element.attributes.color}));
  return  selectedRelatedElements;
};

const allFilters = computed(() => {
  if (!Object.keys(filterResult.value).length) {
    return {};
  }
  const elementsFilter = filterResult.value;
  return  {
    ...elementsFilter.attributeSearches,
    ...(elementsFilter.relatedElementIds.length && { relatedElementIds: elementsFilter.relatedElementIds })
  };
});
const currentUser = computed(() => store.state.users.currentUser);
const otherFilters = computed(() => {
  if (!Object.keys(allFilters.value).length) {
    return { ...allFilters.value };
  }
  const attributes = {...allFilters.value};
  let ids = [];
  QUICK_FILTERS_KEYS.forEach((key) => {
    if (attributes[key]) {
      ids = getOtherFiltersValues(key, attributes[key]);
      if (!ids.length) {
        delete attributes[key];
      } else {
        attributes[key] = ids;
      }
    }
  });
  return {...attributes};
});
const currentDate = new Date();
const getOtherFiltersValues = (key, values) => {
  let ids = [];
  if (key === 'owner') {
    ids = values.filter((id) => currentUser.value.id !== id);
  } else if (key === 'due-date') {
    ids = getOtherFiltersDates(values, currentDate);
  }
  return ids;
};

const quickFilters = computed(() => {
  let quickFilteredItems = {};
  let currentUserId, dueThisWeek;
  if (Object.keys(filterResult.value).length) {
    const {attributeSearches} = filterResult.value;
    if (!Object.keys(attributeSearches).length) {
      return quickFilteredItems;
    }
    if (attributeSearches.owner) {
      currentUserId = attributeSearches.owner.find((id) => currentUser.value.id === id);
    }
    if (attributeSearches['due-date'] && compareDates(attributeSearches['due-date'], currentDate)) {
      dueThisWeek = getCurrentWeekDates(currentDate);
    }
    quickFilteredItems = {
      ...(currentUserId && { owner: [currentUserId] }),
      ...(dueThisWeek && { 'due-date': dueThisWeek })
    };
  }
  return quickFilteredItems;
});
const datasetElementId = computed(() => store.state.editor.datasetElementId);
const datasetElement = computed(() => store.state.board.datasetElements[datasetElementId.value]);

const items = computed(() => attributes.value.length ? filterDataOptions(Object.values(attributes.value)) : []);
const selectedItems = computed(() => filterSelectedItems());
const filterSelectedItems = () => {
  let selectedItems = [];
  if (!otherFilters.value) {
    return selectedItems;
  }
  items.value.forEach((item) => {
    const attValues = otherFilters.value[item.name];
    if (attValues && attValues.length) {
      if (item.componentType === 'timeframe' || item.componentType === 'dateRange') {
        selectedItems = [...selectedItems, ...formatSelectedTimeframeAttributes(item, attValues)];
        return;
      }
      const items = (item.options || []).filter((option) => {
        const data = option.withID ? option.id : (option.date || option.text);
        return attValues.some((filter) => filter === data);
      });
      selectedItems = [...selectedItems, ...items];
    }
  });
  return selectedItems;
};

const datasetElements = computed(() => store.state.dock.datasetElements || {});
const dataTypes = computed(() => store.state.app.dataTypes);
const filteredAttributes = computed(() => {
  const dataTypeId = filterResult.value.dataTypeId;
  const defaultFilterConfig = dataTypes.value[dataTypeId].editorConfig?.filterConfig.default.attributes;
  const othersFilterConfig = dataTypes.value[dataTypeId].editorConfig?.filterConfig.other.attributes;
  const filterConfig = [...defaultFilterConfig, ...othersFilterConfig];
  const filteredAttributes = dataTypes.value[dataTypeId]?.attributes.filter((attr) =>  filterConfig.includes(attr.name));
  const mainAttributes = dataTypes.value[dataTypeId]?.editorConfig.general.mainAttributesPanel
    .filter((attr) => (filterConfig.includes(attr.name)));
  const mergedAttributes = filteredAttributes.map((filteredAttr) => {
    const element = mainAttributes.find((attr) => filteredAttr.name === attr.name);
    if (element) {
      return {...filteredAttr, ...element};
    }
    return {...filteredAttr};
  });
  return mergedAttributes;
});
const combineAttributes = () => {
  let filteredRelatedElements = Object.values(datasetElements.value).map((element) => ({id: element.id, value: element.attributes['friendly-id'],
    title: element.attributes.title, shapeColor: element.attributes.color}));
  if (selectedRelatedElements.value.length) {
    filteredRelatedElements = [...filteredRelatedElements, ...selectedRelatedElements.value].filter((element, index, self) =>
      index === self.findIndex((e) => e.id === element.id)
    );
  }
  return [...filteredAttributes.value, {
    name: 'relatedElementIds',
    friendlyName: 'Related to',
    componentType: 'listwithid',
    enum: filteredRelatedElements
  }];
};

const title = computed(() => datasetElement.value ? datasetElement.value.attributes.title : '');
const handleChange = (name, value) => {
  store.dispatch('board/updateDatasetElementAttributes', {
    datasetElementId: datasetElementId.value,
    attributes: {
      ...datasetElement.value.attributes,
      [name]: value
    }
  });
};

const emit = defineEmits(['close']);
const closeSettings = () => {
  emit('close');
};

const formatSelectedTimeframeAttributes = (item, values) => {
  const { name } = item;
  return values.map((attr) => {
    const label = formatDateToLabel(attr.type, attr);
    return { name, text: label, date: attr, type: 'menu'};
  });
};
const updateDatasetElements = debounce(async (updatedFilterResult) => {
  await store.dispatch('board/updateFilterResult', updatedFilterResult);
}, 500);

const handleSetFilters = (attributes) => {
  if (!Object.keys(filterResult.value).length) {
    return ;
  }
  const {relatedElementIds, ...rest} = attributes;
  const updatedFilterResult = {...filterResult.value, relatedElementIds, attributeSearches: rest};
  updateDatasetElements(updatedFilterResult);
};
const searchDatasetElements = debounce(async ({value, componentType}) => {
  if (componentType !== 'listwithid') {
    return ;
  }
  relatedSearchQuery.value = value;
  const query = `${value}`;
  await store.dispatch('dock/searchDatasetElements', {query, withTypeId: false});
  attributes.value = combineAttributes();
}, 500);

const allUsers = computed(() => store.state.users.users);
const levels = computed(() => store.state.app.levels);
const allLevels = computed(() => levels.value.map((level) => ({value: level.attributes?.['level-name'], id: level.id}) || []));
const filterDataOptions = (attributes) => attributes.map((attr) => {
  let options = [] ; let maxDisplayedItems = 5;
  const {name, componentType, friendlyName} = attr;
  if (attr.type === 'user' || attr.type === 'list') {
    options = allUsers.value.map((user) => ({
      text: `${user.firstname} ${user.lastname}`,
      id: user.id,
      showIcon: false,
      name: attr.name,
      withID: true,
      avatar: {
        picture: user.picture,
        size: 'default',
        color: '#8AC3C5',
        altText: getUserInitials(user.firstname, user.lastname)
      },
      type: 'member'
    })) || [];
    maxDisplayedItems = 2;
  } else if (attr.type === 'level') {
    options = allLevels.value.map((item) => ({
      id: item.id,
      text: item.value,
      name: attr.name,
      iconName: '',
      type: 'menu',
      state: 'default',
      iconSize: 'default',
      withID: true,
      shapeColor: item.shapeColor,
      title: item.title
    })) || [];
  } else if (attr.enum && attr.enum.length) {
    options = attr.enum.map((item) => ({
      id: item.id,
      text: item.value,
      name: attr.name,
      iconName: '',
      type: attr.componentType || 'menu',
      state: 'default',
      iconSize: 'default',
      withID: attr.componentType === 'listwithid',
      shapeColor: item.shapeColor,
      title: item.title
    })) || [];
  } else if (attr.componentType === 'checkbox') {
    let isChecked = allFilters.value[attr.name];
    if (!Array.isArray(isChecked)) {
      isChecked = [!!isChecked];
    }
    return {name, componentType, label: friendlyName, isChecked} ;
  }
  return {name, componentType, label: friendlyName, options, maxDisplayedItems} ;
});
</script>

<style lang="scss" scoped src="./ListBoardgetEditor.scss" />

