export class CurrentDateOccurence {
  /**
  * constructor description
  * @param  String frequency
  * @param  String currentDate
  */
  constructor(frequency, currentDate) {
    this._frequency = frequency;
    this._currentDate = currentDate;
  }
  /**
   * @description - Get frequency of the date array
   */
  get frequency() {
    return this._frequency;
  }
  /**
   * @param  String frequency - Frequency of the date array
   */
  set frequency(frequency) {
    this._frequency = frequency;
  }
  /**
   * @description - Get frequency of the date array
   */
  get currentDate() {
    return this._currentDate;
  }
  /**
   * @param  String frequency - Frequency of the date array
   */
  set currentDate(value) {
    this._currentDate = value;
  }
  #frequencyMethodMap = {
    Monthly: 'getMonth',
    Yearly: 'getYear',
    Quarterly: 'getQuarter',
    Semesterly: 'getSemester',
    Daily: 'getDay',
    Weekly: 'getWeek'
  };
  /**
   * @description generates the currentDateOccurence given a frequency
   * @returns String
   */
  generate() {
    if (!this.#frequencyMethodMap[this.frequency]) {
      return [];
    }
    return this[this.#frequencyMethodMap[this.frequency]]();
  }
  getMonth() {
    return `${this.generateMonthString()}.${this._currentDate.getFullYear()}`;
  }
  generateMonthString() {
    const monthString = this._currentDate.toLocaleDateString('en-us', { month: 'short' });
    return monthString;
  }
  getYear() {
    return this._currentDate.getFullYear();
  }
  getQuarter() {
    let quarter = '';
    if (this._currentDate.getMonth() >= 0 && this._currentDate.getMonth() <= 2) {
      quarter = 'Q1';
    } else if (this._currentDate.getMonth() >= 3 && this._currentDate.getMonth() <= 5) {
      quarter = 'Q2';
    } else if (this._currentDate.getMonth() >= 6 && this._currentDate.getMonth() <= 8) {
      quarter = 'Q3';
    } else {
      quarter = 'Q4';
    }
    return `${quarter} ${this.getYear()}`;
  }
  getSemester() {
    let semester = '';
    if (this._currentDate.getMonth() <= 5) {
      semester = 'S1';
    } else if (this._currentDate.getMonth() > 5) {
      semester = 'S2';
    }
    return `${semester} ${this.getYear()}`;
  }
  getDay() {
    const day = String(this._currentDate.getDate()).padStart(2, '0');
    const month = this._currentDate.getMonth();
    const monthString = this.generateMonthString(month);
    const year = this._currentDate.getFullYear();
    const dateString = `${day} ${monthString}.${year}`;
    return dateString;
  }
  /**
   *
   * @returns returns ISOWeek with year of a the currentDate
   */
  getWeek() {
    this._currentDate.setHours(0, 0, 0, 0);
    this._currentDate.setDate(this._currentDate.getDate() + 4 - (this._currentDate.getDay() || 7));
    const yearStart = new Date(this._currentDate.getFullYear(), 0, 1);
    const week = Math.ceil((((this._currentDate - yearStart) / 86400000) + 1) / 7);
    const year = this._currentDate.getFullYear();
    const month = this._currentDate.getMonth();
    if (week === 1 && month > 10) {
      return `${week} ${year + 1}`;
    }
    return `W${week} ${year}`;
  }
}
