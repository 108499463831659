<template>
  <PowerBI
    v-if="toolType === ToolTypes.POWERBI"
    :url="url"
    :prevent-interaction="preventInteraction"
    :style="{height: `${size.height}px`, width: `${size.width}px`}"
    @click.prevent
  />
  <div
    v-else-if="toolType === ToolTypes.WEBCONTENT"
    class="embed-wrapper level-2"
    data-target="board-element"
  >
    <div class="iframe-container">
      <iframe
        v-if="!error"
        ref="iframeRef"
        title="web content"
        class="embed"
        sandbox="allow-forms allow-same-origin allow-scripts allow-popups"
        allow="fullscreen"
        :src="iframeUrl"
        :inert="preventInteraction"
        :is-refreshing="isRefreshing"
      />

      <div
        v-else
        class="error-container"
      >
        <div class="error-container">
          <div class="error-message-wrapper">
            <IconLoader
              name="AlertCircle"
              color="#A43434"
            />
            <span class="error-message">{{ t('embed.powerbi.message.error') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { isValidUrl } from '@/utils/utils';
import { parseEmbed, unsanitizeUrl } from 'BOARD/components/Embed/utils/embed-parser';
import { ToolTypes, USER_INTERACTIONS } from 'GLOBALS/constants';
import { defineProps, ref, watch, onMounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import PowerBI from './powerbi/PowerBI.vue';

const props = defineProps({
  toolType: {
    type: String,
    required: true
  },
  url: {
    type: String,
    required: true
  },
  size: {
    type: Object,
    required: true
  },
  zoomValue: {
    type: Number,
    default: 100
  },
  isRefreshing: {
    type: Boolean,
    required: false
  },
  userInteraction: {
    type: String,
    default: null
  }
});

const iframeRef = ref(null);
const error = ref(false);
const { t } = useI18n();
const iframeUrl = computed(() => unsanitizeUrl(props.url));
const preventInteraction = computed(() => [USER_INTERACTIONS.DRAG, USER_INTERACTIONS.RESIZE].includes(props.userInteraction));

onMounted(() => {
  changeZoom(props.zoomValue);
  error.value = !checkIsValidUrl(props.url);
});
// Function to change the iframe zoom
const changeZoom = (zoomPercent) => {
  const iframe = iframeRef.value;
  if (iframe) {
    const zoomPercentage = zoomPercent / 100;
    const zoomRatio = 100 / zoomPercentage;

    iframe.style.transform = `scale(${zoomPercentage})`;

    iframe.style.width = `${zoomRatio}%`;
    iframe.style.height = `${zoomRatio}%`;
  }
};
const checkIsValidUrl = (url) => {
  const parsedData = parseEmbed(url);
  return isValidUrl(parsedData.url);
};
// Watch function to handle zoom changes
watch(() => props.zoomValue, (newZoomValue) => {
  if (newZoomValue) {
    changeZoom(newZoomValue);
  }
});
watch(() => props.url, (newUrl) => {
  error.value = !checkIsValidUrl(newUrl);
});
watch(() => props.isRefreshing, () => {
  const iframe = iframeRef.value;
  if (iframe) {
    iframe.src = unsanitizeUrl(props.url);
  }
});
</script>

<style lang="scss" scoped src="./EmbedContent.scss" />
