import { post, put } from 'SRC/api/index.js';

export const pipeAsyncFunctions =
  (...fns) =>
    (arg) =>
      fns.reduce((p, f) => p.then(f), Promise.resolve(arg));

export const addDefalutColor = (context) => {
  if (context.defaultColor) {
    return {
      ...context,
      dataType: {
        ...context.dataType,
        attributes: context.dataType.attributes.map((attr) => {
          if (attr.name === 'color') {
            return {...attr, defaultValue: context.defaultColor};
          }
          return attr;
        })
      }
    };
  }
  return context;
};

export const generateDataTypeBody = (context) => ({
  ...context,
  dataTypeBody: {
    ...context.dataType,
    name: `${context.cardName.replace(/' '/g, '')}`,
    attributes: [...context.dataType.attributes, ...context.customAttributes],
    editorConfig: {
      general: { attributes: context.customAttributes }
    }
  }
});
export const createDataType = async (context) => {
  const dataTypeRes = await post('/element-data-types', context.dataTypeBody);
  return { ...context, dataTypeRes };
};
export const generateMappings = async (context) => {
  let mappings = Object.entries(context.fieldPlacement).reduce(
    (acc, [key, value]) => {
      if (value && value.value !== 'None') {
        acc = [
          ...acc,
          {
            tileName: key,
            fieldName: value.value,
            type: value.type
          }
        ];
      }
      return acc;
    },
    []
  );
  mappings = [
    ...mappings,
    { tileName: 'center', fieldName: 'title', type: 'string' },
    { tileName: 'header-left', fieldName: 'friendly-id', type: 'tag' }
  ];
  return { ...context, mappings };
};

export const generateViewTypeBody = (context) => ({
  ...context,
  viewTypeWithId: {
    dataTypeId: context.dataTypeRes.id,
    layoutId: context.selectedLayout,
    mappings: context.mappings,
    dataTypeName: context.dataTypeRes.name,
    dataTypeVersion: 0,
    // eslint-disable-next-line max-len
    iconUri: 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODQiIGhlaWdodD0iNjIiIHZpZXdCb3g9IjAgMCA4NCA2MiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI4MyIgaGVpZ2h0PSI2MSIgcng9IjMuNSIgZmlsbD0iI0Y3RjdGNyIgc3Ryb2tlPSIjNkU2RTZFIi8+CjxwYXRoIGQ9Ik0wLjUgNDcuNUg4My41VjU4QzgzLjUgNTkuOTMzIDgxLjkzMyA2MS41IDgwIDYxLjVINEMyLjA2NyA2MS41IDAuNSA1OS45MzMgMC41IDU4VjQ3LjVaIiBmaWxsPSIjRjdGN0Y3IiBzdHJva2U9IiM2RTZFNkUiLz4KPHJlY3QgeD0iMTAiIHk9IjE1IiB3aWR0aD0iMzYiIGhlaWdodD0iNiIgcng9IjMiIGZpbGw9IiNDQUNBQ0EiLz4KPHJlY3QgeD0iMTAiIHk9IjI2IiB3aWR0aD0iNjQiIGhlaWdodD0iNiIgcng9IjMiIGZpbGw9IiNDQUNBQ0EiLz4KPC9zdmc+Cg=='
  }
});
export const createViewType = async (context) => {
  await post('/element-view-types', context.viewTypeWithId);
  return { ...context };
};
export const generateHierarchyRequestBody = (context) => {
  let body = [];
  if (context.hierarchy.parents.length > 0) {
    body = context.hierarchy.parents.reduce(
      (acc, curr) => [
        ...acc,
        { dataTypeId: curr.id, childDataTypeId: context.dataTypeRes.id }
      ],
      []
    );
  }
  if (context.hierarchy.children.length > 0) {
    body = [
      ...body,
      ...context.hierarchy.children.reduce(
        (acc, curr) => [
          ...acc,
          { dataTypeId: context.dataTypeRes.id, childDataTypeId: curr.id }
        ],
        []
      )
    ];
  }
  return { ...context, hierarchyReqBody: body };
};

export const createHierarchiecalRelations = async (context) => {
  await post('/hierarchical-relation-definitions', context.hierarchyReqBody);
  return context;
};

export const refreshData = async (context) => {
  const promise = (fn) => new Promise((resolve) => fn().then((result) => {
    resolve(result);
  }
  ));
  await Promise.all([
    promise(context.fetchViewTypes),
    promise(context.fetchDataTypes)
  ]);
  return context;
};

export const createFormulas = async (context) => {
  const promises = context.customAttributes.map(async (attribute, i) => {
    context.formulaId = null;
    if (attribute.computedByFormula) {
      let formula = attribute.computedByFormula;
      formula = {...formula, name: attribute.name};
      if (formula) {
        return {
          index: i,
          formulaId: await context.addFormula(formula)
        };
      }
    }
  });

  const res = await Promise.all(promises);
  res.filter((value) => value).forEach((value) => {
    context.customAttributes[value.index].computedByFormula = value.formulaId;
  });

  return context;
};

export const resetFieldsValue = (context) => {
  context.resetFields();
  return context;
};

export const getAllFormulas = async (context) => {
  await context.fetchFormulas();
  return context;
};

export const closeToolsConfigModal = (context) => {
  context.setDialogBoxFlags({
    ...context.dialogBoxFlags,
    isToolsConfigDialogOpened: false
  });
  return context;
};

export const resetSelectedTab = (context) => {
  context.setSelectedTabIndex(0);
};

export const generateSelectFormula = (values, fieldName) => {
  const expressions = values.reduce((acc, value) => {
    const expression = `CONCATENATE(COUNTIF(children().${fieldName}, "${value.value}"), " ", "${value.value}")`;
    return [...acc, expression];
  }, []);
  return `CONCATENATE(${expressions.join(',", ",')})`;
};

export const generateFormula = (fieldName, fieldType, enumValues) => {
  if (enumValues) {
    return generateSelectFormula(enumValues, fieldName);
  }
  return {
    int: `SUM(children().${fieldName})`,
    string: `CONCATENATE(COUNTA(children(NOT(IFNA(${fieldName}, "") = "")).${fieldName}), " ", "${fieldName}")`,
    date: `CONCATENATE(MINA(children().${fieldName})," ", MAXA(children().${fieldName}))`,
    user: `CONCATENATE(COUNTA(children(NOT(IFNA(${fieldName}, "") = "")).${fieldName}), " ", "${fieldName}")`
  }[fieldType];
};

export const createSummaryFormulas = async (context) => {
  let summaryFormulasIds = [];
  if (context.hierarchy.parents.length > 0) {
    const fieldsWithSummary = context.fields.filter((e) => e.summary);
    if (fieldsWithSummary.length) {
      const formulaRequests = fieldsWithSummary.map(async (field) => ({
        formulaId: await context.addFormula({ expression: generateFormula(field.details.name, field.details.type, field.details.enum)}),
        name: field.details.name,
        type: field.details.type === 'int' ? 'int' : 'string'
      }
      ));
      summaryFormulasIds = await Promise.all(formulaRequests);
      return {...context, summaryFormulasIds};
    }
  }
  return {...context, summaryFormulasIds};
};

export const generateUpdateParentBody = (formulas, parent) => {
  const attributes = formulas.map((formula) => ({
    name: formula.name,
    computedByFormula: formula.formulaId,
    originRef: `/${formula.name}`,
    type: formula.type,
    friendlyName: formula.name
  }));
  const editorConfig = formulas.map((formula) => ({
    friendlyName: formula.name,
    name: formula.name,
    originalRef: `/${formula.name}`,
    type: formula.type
  }));
  return ({
    attributes,
    editorConfig: {
      ...parent.editorConfig,
      general: {
        attributes: [
          ...parent.editorConfig.general.attributes || [],
          ...editorConfig
        ]
      }
    }
  });
};
export const updateParents = async (context) => {
  if (context.summaryFormulasIds.length > 0) {
    const parents = context.hierarchy.parents;
    let updateParentsReqs = parents.map((parent) => put(`/element-data-types/${parent.id}`,
      generateUpdateParentBody(context.summaryFormulasIds, parent)));
    updateParentsReqs = updateParentsReqs.flat();
    await Promise.all(updateParentsReqs);
  }
  return {...context};
};

export const resetToolsState = (context) => {
  context.resetState(context.layouts[0].id);
  return {...context};
};

export const manageNotifications = (context) => {
  if (context.isNotificationDisplayed) {
    context.setNotificationsInfo({
      checkViewTypes: {
        ...context.notifications.checkViewTypes,
        isNotificationDisplayed: true,
        message: `You have reached a maximum of ${context.maxDisplayedViewTypes} displayed view types`
      }
    });
  }
  return {...context};
};
