import { createStore } from 'vuex';
import AppModule from './app';
import BoardModule from './board';
import AuthModule from './auth';
import UsersModule from './users';
import EditorModule from './editor';
import HierarchyModule from './editor/hierarchy';
import ToolsConfigModule from './toolsConfig';
import relationsModule from './relations';
import workspaceModule from './workspace';
import dockModule from './dock';
import PowerBiOAuthModule from './oauth/powerbi';

const store = createStore({
  modules: {
    app: AppModule,
    board: BoardModule,
    users: UsersModule,
    auth: AuthModule,
    editor: EditorModule,
    hierarchy: HierarchyModule,
    toolsConfig: ToolsConfigModule,
    relations: relationsModule,
    workspace: workspaceModule,
    dock: dockModule,
    powerbi: PowerBiOAuthModule
  }
});

export default store;
