<template>
  <div :class="(props.labelOnTop || componentType === 'progressBar') ? '' : 'IobEditor-right-attributes-row'">
    <h5
      v-if="componentType !== 'progressBar'"
      :class="{
        'IobEditor-right-attributes-row--label' : !props.labelOnTop,
        'IobEditor-section-label': props.labelOnTop
      }"
    >
      {{ props.friendlyName }}
    </h5>
    <div v-if="componentType === 'progressBar'">
      <div
        class="IobEditor-right-progression"
      >
        <div class="IobEditor-right-progression-percentage">
          <div class="IobEditor-right-progression-percentage--info">
            <h3 class="IobEditor-right-progression-percentage--info--text">
              {{ props.datasetElement.attributes[name] }}%
            </h3>
            <div class="IobEditor-right-progression-percentage--icon">
              <icon-loader
                name="Info"
                size="small"
                color="#9491A8"
              />
              <span class="IobEditor-right-progression-percentage--icon--tooltip">{{ `Progress is automatically updated based on related ${props.computeProgress.title}` }}</span>
            </div>
          </div>
          <div class="IobEditor-right-progression-percentage--bar">
            <iob-progress-bar
              color="primary"
              :progress="props.datasetElement.attributes[name]"
              animation-duration="300"
            />
          </div>
        </div>
        <div class="IobEditor-right-progression-formula">
          <p class="IobEditor-right-progression-formula--label">
            track by
          </p>
          <iob-dropdown-button
            :title="props.computeProgress.title"
            :type-check="true"
            size="medium"
            :items="props.inputDropdown(props.computeProgress.enum)"
            @dropdown-element-click="props.handleAttributeChange('driver', $event)"
          />
        </div>
        <div class="IobEditor-right-attributes--seperator">
          <iob-separator :is-vertical="false" />
        </div>
      </div>
    </div>
    <div
      v-if="props.componentType === 'avatarList' && props.type === 'user'"
      class="IobEditor-right-attributes-row--value"
    >
      <iob-input-avatar
        :users="props.users"
        :current-user="props.getUser(props.name)"
        :placeholder="props.placeholder"
        @dropdown-element-click="props.handleAttributeChange(props.name, $event)"
      />
    </div>

    <!-- attribute -->
    <div
      v-else-if="props.componentType === 'checkbox'"
      class="IobEditor-right-attributes-row"
      style="width: 100%;"
    >
      <div class="IobEditor-right-attributes-row--value">
        <iob-toggle-switch
          class="checkbox"
          :checked="props.datasetElement.attributes[props.name]"
          @change="(value)=> props.handleAttributeChange(props.name, value)"
        />
      </div>
    </div>
    <!-- attribute -->

    <iob-input-dropdown
      v-else-if="props.componentType === 'inputDropdown' && props.type !== 'level'"
      class="IobEditor-right-attributes-row--value"
      :type-check="true"
      :status="props.getAttributeValue(props.name, attrOptions)"
      :items="props.inputDropdown(props.enum, props.name, attrOptions)"
      :disabled="shouldDisableDropdown(props.name, attrOptions)"
      @dropdown-element-click="props.handleAttributeChange(props.name, $event, '', props.enum, attrOptions)"
    />

    <tree-node
      v-else-if="props.componentType === 'inputDropdown' && props.type === 'level'"
      class="IobEditor-right-attributes-row--value"
      :options="props.levels"
      label-field="level-name"
      :status="props.getAttributeValue(props.name)"
      :is-editor="true"
      @dropdown-element-click="props.handleAttributeChange(props.name, $event)"
    />

    <iob-timeframe
      v-else-if="props.componentType === 'timeframe'"
      style="padding-left: 8px;"
      class="IobEditor-right-attributes-row--value"
      :types="[{'id': 0, 'value': 'standard'},{'id': 1, 'value': 'custom'}]"
      default-type="standard"
      :name="name"
      :date-settings="props.datasetElement.attributes[props.name]"
      @handle-date-change="({date, label}) => props.handleAttributeChange(props.name, date, label)"
    />

    <iob-timeframe
      v-else-if="props.componentType === 'dateRange'"
      style="padding-left: 8px;"
      class="IobEditor-right-attributes-row--value"
      default-type="custom"
      :types="[{'id': 0, 'value': 'custom'}]"
      :component-type="props.componentType"
      :data-type="dataType"
      :date-settings="props.datasetElement.attributes[props.name]"
      @handle-date-change="({date}) => props.handleAttributeChange(props.name, date)"
    />

    <div
      v-else-if="props.componentType === 'textInput'"
      class="IobEditor-right-attributes-row--value"
    >
      <iob-label-field
        :input-type="props.getInputType(props.type).inputType"
        :is-positive-int="props.getInputType(props.type).isPositiveInt"
        :is-positive-double="props.getInputType(props.type).isPositiveDouble"
        :model-value="props.inputs[props.name]"
        :postfix-type="props.postfixType"
        :postfix-unit-value="props.postfixUnitValue"
        :placeholder="`${props.friendlyName}...`"
        @update:modelValue="(value) => props.handleTextInput(props.name, value)"
      />
    </div>

    <iob-input-dropdown
      v-else-if="props.componentType === 'badge' && props.enum"
      class="IobEditor-right-attributes-row--value"
      :type-check="true"
      type="badge"
      :status="props.getAttributeValue(props.name)"
      size="xs"
      :items="props.inputDropdown(props.enum)"
      @dropdown-element-click="props.handleAttributeChange(props.name, $event)"
    />
    <!-- attribute -->

    <div
      v-else-if="props.componentType === 'iconWithLabel'"
      class="IobEditor-right-attributes-row--value"
    >
      <div class="iconWithLabel">
        <IobIconWithLabel
          :icon="props.iconName"
          :label="props.createdOn"
          size="default"
        />
      </div>
    </div>
    <div
      v-else-if="props.componentType ==='textArea'"
      class="IobEditor-description-text"
    >
      <iob-textarea
        min-rows="2"
        :placeholder="`Add a ${props.label.toLowerCase()}...`"
        :value="inputs ? inputs[props.name] : ''"
        max-caracters="15000"
        :show-caracters-counter="displayDescriptionCounter"
        styles="font-size: 16px; font-weight: 400;"
        @update:modelValue="(value) => handleTextInput(props.name, value)"
      />
    </div>
    <div v-else-if="props.componentType ==='radioList'">
      <div class="IobEditor-description-text">
        <iob-radio-button
          :selected="props.getAttributeValue(props.name)"
          :options="props.enum"
          @change="props.handleAttributeChange(props.name, {text: $event})"
        />
      </div>
    </div>
    <div v-else-if="props.componentType ==='tableMapdates'">
      <div class="IobEditor-description-text">
        <IobTimeFrameMapWrapper
          :type="props.timeframeAttrValue.type"
          :period="props.timeframeAttrValue.period"
          :start-date="props.createdOn"
          :end-date="props.timeframeAttrValue['end-date']"
          :frame="props.timeframeAttrValue.frame"
          :year="props.timeframeAttrValue.year"
          :frequency="props.frequencyAttrValue"
          :input-values="props.getAttributeValue(props.name)"
          :initial-value="props.initialValue"
          :final-target="props.finalTarget"
          :horizon-value="props.horizonValue"
          @change="props.handleAttributeChange(props.name, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import IobProgressBar from '../../../components/Atoms/IobProgressBar/IobProgressBar.vue';
import IobInputDropdown from '../../../components/Molecules/IobInputDropdown/IobInputDropdown.vue';
import IobInputAvatar from '../../../components/Molecules/IobInputAvatar/IobInputAvatar.vue';
import IobToggleSwitch from '../../../components/Atoms/IobToggleSwitch/IobToggleSwitch.vue';
import IobTextarea from '../../Atoms/IobTextarea/IobTextarea.vue';
import IobIconWithLabel from '../../../components/IobIconWithLabel/IobIconWithLabel.vue';
import IobLabelField from '../IobLabelField/IobLabelField.vue';
import IobRadioButton from '../../../components/Molecules/IobRadioButton/IobRadioButton.vue';
import {computed, defineProps} from 'vue';
import IconLoader from '../../IconLoader/IconLoader.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobDropdownButton from '../../Molecules/IobDropdownButton/IobDropdownButton.vue';
import IobTimeframe from '../../Molecules/IobTimeframe/IobTimeframe.vue';
import IobTimeFrameMapWrapper from '../IobTimeFrameMapWrapper/IobTimeFrameMapWrapper.vue';
import TreeNode from '../IobTree/TreeNode.vue';

const props = defineProps({
  labelOnTop: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  inputs: {
    type: Object,
    default: () => ({})
  },
  displayDescriptionCounter: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    required: true
  },
  friendlyName: {
    type: String,
    default: ''
  },
  componentType: {
    type: String,
    default: 'textInput'
  },
  type: {
    type: String,
    default: 'string'
  },
  enum: {
    type: Array,
    default:() => [],
  },
  datasetElement: {
    type: Object,
    default: () => {}
  },
  users: {
    type: Array,
    default: () => []
  },
  inputDropdown: {
    type: Function,
    default: () => {}
  },
  handleAttributeChange: {
    type: Function,
    default: () => {}
  },
  getUser: {
    type: Function,
    default: () => {}
  },
  getAttributeValue: {
    type: Function,
    default: () => {}
  },
  getInputType: {
    type: Function,
    default: () => {}
  },
  handleTextInput: {
    type: Function,
    default: () => {}
  },
  postfixType: {
    type: String,
    default: ''
  },
  postfixUnitValue: {
    type: String,
    default: ''
  },
  computeProgress: {
    type: Object,
    default: () => ({})
  },
  createdOn: {
    type: String,
    default: ''
  },
  iconName: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: ''
  },
  timeframeAttrValue: {
    type: Object,
    default: () => ({})
  },
  frequencyAttrValue: {
    type: String,
    default: ''
  },
  initialValue: {
    type: String,
    default: ''
  },
  dataType: {
    type: Object,
    default: () => {}
  },
  finalTarget: {
    type: String,
    default: ''
  },
  horizonValue: {
    type: String,
    default: ''
  },
  levels: {
    type: Array,
    default: () => []
  },
  indexPartOfAttrValue: {
    type: Number,
    default: 0
  },
  attrValueSeparator: {
    type: String,
    default: ''
  },
  useSameAttrEnumItemId: {
    type: Boolean,
    default: false
  }
});

const shouldDisableDropdown = (name, attrOptions) => {
  return name === 'timeSeriesFrequency'
      && props.getAttributeValue(name, attrOptions) === 'Daily'
      && attrOptions.indexPartOfAttrValue === 1;
};

const attrOptions = computed(() => {
  return {
    indexPartOfAttrValue: props.indexPartOfAttrValue,
    attrValueSeparator: props.attrValueSeparator,
    useSameAttrEnumItemId: props.useSameAttrEnumItemId
  };
});

// const getTimeframeAttributes = computed(()=> {
//   if(!props.dataType?.subAttributes) return;
//   return props.dataType.subAttributes.find((subAttribute) => subAttribute.name === 'type');
// })

</script>

<style lang="scss" src="./IobEditor.scss" scoped />
