<template>
  <div class="flex">
    <DropdownButtonFlowbite
      class="mr-4"
      :options="formattedNumberFormats"
      title="Format"
      :default-value="format"
      button-style="width: 150px;"
      search-menu-style="width: 160px"
      options-container-height="150"
      mode="gray"
      border-color="border-gray"
      @click-item="getNumberFormat"
    />
    <DropdownButtonFlowbite
      :options="decimals"
      title="Decimals"
      :search-field="false"
      :default-value="{ label: numberOfdecimals }"
      button-style="width: 80px"
      search-menu-style="width: 80px"
      options-container-height="240"
      mode="gray"
      border-color="border-gray"
      @click-item="getDecimals"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'AddFieldTypeNumber',
  props: {
    fieldItem: {
      type: Object,
      required: true,
      default: null
    }
  },
  emits: ['select-item'],
  data() {
    return {
      decimals: [
        { label: '0' },
        { label: '1' },
        { label: '2' },
        { label: '3' },
        { label: '4' },
        { label: '5' }
      ],
      selectedNumberFormat: null,
      selectedNumberOfdecimals: null
    };
  },
  computed: {
    ...mapState('toolsConfig', ['numberFormats']),
    formattedNumberFormats() {
      return this.numberFormats.map((format) => ({
        label: format.friendlyName,
        id: format.id,
        isDefaultFormat: format.isDefaultFormat,
        configurable: format.label.configurable
      }));
    },
    format() {
      if (this.selectedNumberFormat) {
        return this.selectedNumberFormat;
      }
      if (this.fieldItem) {
        const numberFormat = this.fieldItem.details.numberFormat;
        return this.formattedNumberFormats.find(
          (format) => format.id === numberFormat.id
        );
      }
      return this.formattedNumberFormats.find(
        (format) => format.isDefaultFormat
      );
    },
    numberOfdecimals() {
      if (this.selectedNumberOfdecimals) {
        return this.selectedNumberOfdecimals;
      }
      if (this.fieldItem) {
        const numberFormat = this.fieldItem.details.numberFormat;
        return numberFormat.maxDecimalPlace;
      }
      return 0;
    }
  },
  watch: {
    format: {
      handler(value) {
        if (value && this.numberOfdecimals) {
          this.$emit('select-item', {
            numberFormat: {
              id: value.id,
              maxDecimalPlace: this.numberOfdecimals
            }
          });
        }
      },
      immediate: true,
      deep: true
    },
    numberOfdecimals: {
      handler(value) {
        if (value && this.format) {
          this.$emit('select-item', {
            numberFormat: {
              id: this.format.id,
              maxDecimalPlace: value
            }
          });
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if (this.fieldItem) {
      const numberFormat = this.fieldItem.details.numberFormat;
      this.format = this.formattedNumberFormats.find(
        (format) => format.id === numberFormat.id
      );
      this.numberOfdecimals = numberFormat.maxDecimalPlace;
    }
    this.$emit('select-item', {
      numberFormat: {
        id: this.format.id,
        maxDecimalPlace: this.numberOfdecimals
      }
    });
  },
  methods: {
    getNumberFormat(format) {
      this.format = format;
      this.selectedNumberFormat = format;
      this.$emit('select-item', {
        numberFormat: {
          id: this.format.id,
          maxDecimalPlace: this.numberOfdecimals
        }
      });
    },
    getDecimals(decimals) {
      this.numberOfdecimals = parseInt(decimals.label);
      this.selectedNumberOfdecimals = parseInt(decimals.label);
      this.$emit('select-item', {
        numberFormat: {
          id: this.format.id,
          maxDecimalPlace: this.numberOfdecimals
        }
      });
    }
  }
};
</script>
