const getters = {
  getFullAttributes: (state) => (datasetElementId) =>
    state.datasetElements[datasetElementId] ? state.datasetElements[datasetElementId].attributes : null,
  getBoardElementsBeingMoved: (state) => state.boardElementsBeingMoved,
  getBoardElementMovers: (state) => (boardElementId) =>
    state.boardElementsBeingMoved ? state.boardElementsBeingMoved[boardElementId] : [],
  getDatasetElementsIds: (state) => Object.keys(state.datasetElements),
  getBoardElementModifiers: (state) => (boardElementId) =>
    // eslint-disable-next-line no-prototype-builtins
    state.boardElementsInEdition.hasOwnProperty(boardElementId) ?
      state.boardElementsInEdition[boardElementId].map((each) => each.userId) : [],
  getBoardElementSelectors: (state) => (boardElementId) =>
    // eslint-disable-next-line no-prototype-builtins
    state.boardElementsBeingSelected.hasOwnProperty(boardElementId) ?
      state.boardElementsBeingSelected[boardElementId].map((each) => each.userId) : [],
  getBoardVersionsByMonth: (state) => (month) =>
    // eslint-disable-next-line no-prototype-builtins
    state.oldVersions.hasOwnProperty(month) ?
      state.oldVersions[month] : [],
  getBoardElementDataType: (state, getters, rootState) => (boardElementId) =>
    rootState.app.dataTypes[state.datasetElements[state.elements[boardElementId].datasetElement.id].typeId].name,
  getBoardElementViewType: (state, getters, rootState) => (boardElementId) =>
    rootState.app.viewTypes[state.elements[boardElementId].viewTypeId],
  getSelectedBoardElements: (state) => Object.entries(state.elements).reduce((acc, [elementId, data]) => {
    if (state.userSelectedElements.includes(elementId)) {
      return [...acc, data];
    }
    return acc;
  }, []),
  getFilter: (state) => (filterId) => state.filters[filterId],
  getDatasetElementById: (state) => (datasetElementId) => state.datasetElements[datasetElementId],
  getElementInitialPosition: (state) => (size) => size ?
    ({
      x: Math
        .round(state.viewportReferencePoint.x + ((document.documentElement.clientWidth / state.zoomLevel) - size.width) * 0.5),
      y: Math
        .round(state.viewportReferencePoint.y + ((document.documentElement.clientHeight / state.zoomLevel) - size.height) * 0.5)
    }) : null
};

export default getters;
