<template>
  <dialog-box :dialog-box-specific-style="dialogBoxStyle">
    <span class="ConfirmDeleteVersion-header">Are you sure to unmark this version?
    </span>
    <div class="ConfirmDeleteVersion-body">
      <span class="ConfirmDeleteVersion-message">
        This version is older than 30 days. Unmarking it will cause its
        definitive suppression.
      </span>
    </div>

    <div class="ConfirmDeleteVersion-actions">
      <design-system-button
        :is-dark="true"
        button-type="secondary"
        size="small"
        label="Cancel"
        @click="closeDialog()"
      />
      <design-system-button
        :style="{'height': '40px', 'margin-left': '10px'}"
        size="large"
        button-type="destructive"
        label="Delete version"
        @click="deleteBoardVersion()"
      />
    </div>
  </dialog-box>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  name: 'ConfirmDeleteVersionDialogBox',
  data() {
    return {
      dialogBoxStyle: 'display: flex; flex-direction: column; position: absolute; width: 480px; height: 212px; left: 35%; top: 35%;'
    };
  },
  computed: {
    ...mapState('board', [
      'dialogBoxFlags',
      'versionToBeDeletedId'
    ])
  },
  methods: {
    ...mapMutations('board', [
      'setDialogBoxFlags'
    ]),
    ...mapActions('board', [
      'deleteVersion',
      'addBoardVersionsToStore'
    ]),
    closeDialog() {
      this.setDialogBoxFlags({...this.dialogBoxFlags, isDeleteVersionDialogOpened: false});
    },
    async deleteBoardVersion() {
      await this.deleteVersion(this.versionToBeDeletedId);
      this.closeDialog();
      await this.addBoardVersionsToStore();
    }
  }
};
</script>

<style src="./ConfirmDeleteVersion.css">
</style>

