import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import { VUE_APP_KEYCLOAK_URL, VUE_APP_KEYCLOAK_FRONTEND_CLIENT_ID, VUE_APP_KEYCLOAK_REALM } from 'SRC/config/services.js';
import store from 'SRC/store';

const saveTokens = (token, refreshToken, idToken) => {
  localStorage.setItem('token', token);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('idToken', idToken);
};

const retrieveTokens = () => {
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  const idToken = localStorage.getItem('idToken');

  return {
    token,
    refreshToken,
    idToken
  };
};

const authPlugin = {
  install(app) {
    const initTokens = retrieveTokens();
    app.use(VueKeyCloak, {
      init: {
        token: initTokens.token,
        refreshToken: initTokens.refreshToken,
        idToken: initTokens.idToken,
        onLoad: 'login-required',
        checkLoginIframe: false,
        enableLogging: true
      },
      config: {
        url: VUE_APP_KEYCLOAK_URL,
        clientId: VUE_APP_KEYCLOAK_FRONTEND_CLIENT_ID,
        realm: VUE_APP_KEYCLOAK_REALM
      },
      onReady: (keycloak) => {
        console.log('Keycloak ready', keycloak.token);
        if (keycloak.token) {
          saveTokens(keycloak.token, keycloak.refreshToken, keycloak.idToken);
          store.dispatch('auth/insertToken', keycloak.token);
        }
        setInterval(() => {
          keycloak.updateToken(60).then((refreshed) => {
            if (refreshed) {
              console.log('Token refreshed: ', refreshed);
              saveTokens(keycloak.token, keycloak.refreshToken, keycloak.idToken);
              store.dispatch('auth/insertToken', keycloak.token);
            } else {
              console.log('Token not refreshed, valid for ',
                Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000), ' seconds');
            }
          }).catch(() => {
            console.log('Failed to refresh token');
          });
        }, 10000);
      },
      onInitError: (err) => {
        console.error(err);
      }
    });
  }
};

export default authPlugin;
