<template>
  <div class="HierarchyCustomization-container">
    <div class="HierarchyCustomization-label">
      Parent
    </div>
    <multiple-select
      :options="dataTypesArrayParent"
      :search-menu-style="searchMenuStyle"
      options-container-height="120"
      :menu-open="openedMenu === 'Parent'"
      :selected-items="hierarchy.parents"
      style="width: 70%;"
      :input-value="searchParent"
      @delete-label-field-click="deleteParent"
      @search-input-value-change="setSearchParent"
      @click-item="setSelectedParents"
    />
    <div class="HierarchyCustomization-searchMenu" />
    <div class="HierarchyCustomization-label">
      Children
    </div>
    <multiple-select
      :options="dataTypesArrayChildren"
      :input-value="searchChildren"
      :menu-open="openedMenu === 'Children'"
      :selected-items="hierarchy.children"
      :search-menu-style="searchMenuStyle"
      options-container-height="120"
      style="width: 70%;"
      @delete-label-field-click="deleteChildren"
      @search-input-value-change="setSearchChildren"
      @click-item="setSelectedChildren"
    />
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex';
export default {
  name: 'HierarchyCustomization',
  data: () => ({
    openedMenu: '',
    selectedParent: [],
    selectedChildren: [],
    searchParent: '',
    searchChildren: '',
    searchMenuStyle: 'max-height: 300px;overflow-y: auto'
  }),
  computed: {
    ...mapState('app', ['dataTypes']),
    ...mapState('toolsConfig', ['hierarchy']),
    dataTypesArrayParent() {
      return Object
        .values(this.dataTypes)
        .map((e) => ({...e, label: e.name.replace(/.*\//, '')}))
        .filter((el) => this.filterTypes(el, this.searchParent))
      ;
    },
    dataTypesArrayChildren() {
      const addLabelField = (e) => ({...e, label: e.name.replace(/.*\//, '')});
      return Object
        .values(this.dataTypes)
        .map(addLabelField)
        .filter((el) => this.filterTypes(el, this.searchChildren))
      ;
    }
  },
  methods: {
    ...mapMutations('toolsConfig', ['setHierarchy', 'deleteHierarchyItem']),
    filterTypes(el, searchedString) {
      return !this.hierarchy.parents.find((e) => e.name === el.name)
          && !this.hierarchy.children.find((e) => e.name === el.name)
          && el.label !== 'Formula'
          && el.label.toLowerCase().includes(searchedString.toLowerCase());
    },
    setSearchParent(value) {
      this.searchParent = value;
    },
    setSearchChildren(value) {
      this.searchChildren = value;
    },
    setOpenedMenu(value) {
      this.openedMenu = value;
    },
    setSelectedParents(item) {
      this.setHierarchy({value: item, type: 'parents'});
      this.searchParent = '';
    },
    setSelectedChildren(item) {
      this.setHierarchy({value: item, type: 'children'});
      this.searchChildren = '';
    },
    deleteChildren({ value }) {
      this.deleteHierarchyItem({value, type: 'children'});
    },
    deleteParent({ value }) {
      this.deleteHierarchyItem({value, type: 'parents'});
    }
  }
};
</script>
<style scoped src="./HierarchyCustomization.css" />
