<template>
  <div>
    <label class="block mb-2 text-sm font-medium text-gray-900">Options</label>
    <scroll-bar
      id="scroll-container"
      :scroll-container-style="scrollContainerStyle"
    >
      <iobeya-fieldset
        id="fieldset-options"
        :items="options"
        border-color="border-gray"
        mode="gray"
        shape="rounded"
        outlined
        @focus-input="handleFocusEvent"
        @press-enter="onPressEnter"
        @press-escape="onPressEscape"
        @end-icon-click="removeOption"
      />
    </scroll-bar>

    <custom-button
      id="add-option"
      class="top-3"
      label="Add an option"
      mode="light"
      icon-name="PlusCircle"
      color="black"
      @click="addOption"
    />
  </div>
</template>

<script>

export default {
  name: 'AddFieldTypeSelect',
  props: {
    fieldItem: {
      type: Object,
      required: true,
      default: null
    }
  },
  emits: ['select-item'],
  data() {
    return {
      scrollContainerStyle: 'max-height: 170px; padding-top: 1px;',
      defaultOptions: [
        {
          startIcon: 'ColoredChip',
          endIcon: 'X',
          value: 'Option 1',
          valid: true,
          isFocused: false,
          style: 'width: 100%'
        },
        {
          startIcon: 'ColoredChip',
          endIcon: 'X',
          value: 'Option 2',
          valid: true,
          isFocused: false,
          style: 'width: 100%'
        }
      ],
      mousedownTarget: null,
      focusTarget: null,
      timer: null
    };
  },
  computed: {
    options() {
      this.$emit('select-item', {enum: this.defaultOptions});
      return this.defaultOptions;
    }
  },
  beforeUnmount() {
    clearTimeout(this.timer);
    document.removeEventListener('mousedown', this.handleMousedown);
  },
  mounted() {
    if (this.fieldItem) {
      this.defaultOptions = this.fieldItem.details.enum;
    }
    this.$emit('select-item', {enum: this.defaultOptions});
    document.addEventListener('mousedown', this.handleMousedown);
    this.addOptionIds();
  },
  methods: {
    onPressEnter(e) {
      e.stopPropagation();
      if (e.target.value.length) {
        this.defaultOptions[this.defaultOptions.length - 1].valid = true;
        this.defaultOptions[this.defaultOptions.length - 1].isFocused = false;
        this.addOption();
        clearTimeout(this.timer);
      }
    },
    onPressEscape(e) {
      e.stopPropagation();
      this.UpdateOptions();
      this.removeOption(e.target.id);
      document.getElementById('field-name').focus();
    },
    addOptionIds() {
      this.defaultOptions.forEach((element, index) => {
        element.id = `option-${index + 1}`;
      });
    },
    removeOption(id) {
      const optionToRemove = this.defaultOptions.find(
        (option) => option.id === id
      );
      const indexOptionToRemove = this.defaultOptions.indexOf(optionToRemove);
      this.defaultOptions = this.defaultOptions.filter(
        (option) => option.id !== id
      );
      this.defaultOptions.forEach((element, index) => {
        if (index >= indexOptionToRemove) {
          element.id = `option-${index + 1}`;
        }
      });
    },
    addOption() {
      const emptyOption = this.defaultOptions.find((option) => !option.value.length);
      if (emptyOption) {
        return;
      }
      this.defaultOptions.push({
        id: `option-${this.defaultOptions.length + 1}`,
        value: '',
        valid: false,
        isFocused: true,
        startIcon: 'ColoredChip',
        endIcon: 'X',
        placeholder: 'Option name',
        style: 'width: 100%'
      });

      this.timer = setTimeout(() => {
        document.getElementById(`option-${this.defaultOptions.length}`).focus();
      }, 100);
    },
    removeEmptyOption() {
      const optionToFilter = this.defaultOptions.find(
        (option) => !option.value.length
      );
      const indexOptionToRemove = this.defaultOptions.indexOf(optionToFilter);
      this.defaultOptions = this.defaultOptions.filter(
        (option) => option.value.length
      );
      this.defaultOptions.forEach((element, index) => {
        if (index >= indexOptionToRemove) {
          element.id = `option-${index + 1}`;
        }
      });
    },
    handleMousedown(e) {
      this.mousedownTarget = e.target;
      this.UpdateOptions();
      const addOption = document.getElementById('add-option');
      if (
        addOption &&
        addOption.contains(e.target)
      ) {
        return;
      }

      if (
        addOption &&
        !addOption.contains(e.target) &&
        this.focusTarget &&
        e.target.id !== this.focusTarget.id
      ) {
        this.removeEmptyOption();
      }
    },
    UpdateOptions() {
      this.defaultOptions.forEach((element) => {
        element.valid = element.value.length ? true : false;
      });
    },
    focusOption(id) {
      const focusedOption = this.defaultOptions.find(
        (option) => option.id === id
      );
      const indexFocusedOption = this.defaultOptions.indexOf(focusedOption);
      this.defaultOptions.forEach((element, index) => {
        element.isFocused = index === indexFocusedOption;
      });
    },
    handleFocusEvent(e) {
      this.focusOption(e.target.id);
      this.focusTarget = e.target;
      const addOption = document.getElementById('add-option');
      if (
        this.mousedownTarget &&
        addOption &&
        addOption.contains(this.mousedownTarget)
      ) {
        return;
      }
    }
  }
};
</script>

<style scoped src="./AddFieldTypeSelect.css" />
