<template>
  <div class="dropdown-component">
    <div class="input-container">
      <div class="tags">
        <span
          v-for="tag in getDisplayTags"
          :key="tag.id"
          class="tag"
        >
          {{ tag.text }}
          <button @click.stop="removeTag(tag.id)">x</button>
        </span>
      </div>
      <input
        v-model="searchInput"
        placeholder="Type to search related elements"
        @input="emit('search', searchInput)"
      >
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';

const props = defineProps({
  tags: {
    type: Array,
    default: () => []
  }
});
const emit = defineEmits(['search', 'onTagsChange']);

const totalTags = ref(props.tags || []);
const searchInput = ref('');

const getDisplayTags = computed(() => totalTags.value);

watch(
  () => props.tags,
  (newVal) => {
    totalTags.value = newVal;
    searchInput.value = '';
  },
  { deep: true }
);

const removeTag = (id) => {
  emit('onTagsChange', id);
};
</script>

<style src="./RelatedElementsField.css" />
