import { get } from 'SRC/api/index.js';
/**
 * Compares the given dates with the current week's dates.
 *
 * @param {Array} dates - An array of date objects to compare.
 * @param {Date} currentDate - The current date to calculate the week from.
 * @returns {boolean} - Returns true if the dates match the current week's dates, otherwise false.
 */

export const compareDates = (dates, currentDate) => {
  const datesValues = getCurrentWeekDates(currentDate);
  return datesValues.every((obj) =>  dates.some((date) => date['end-date'] === obj['end-date']));
};

/**
 * Gets the dates for the current week based on the given date.
 *
 * @param {Date} currentDate - The current date to calculate the week from.
 * @returns {Array} - An array of date objects representing the current week.
 */
export const getCurrentWeekDates = (currentDate) => {
  const dayOfWeek = currentDate.getDay();
  const startOfWeek = new Date(currentDate);
  startOfWeek.setDate(currentDate.getDate() - dayOfWeek);
  const dates = [];

  for (let i = 0; i < 7; i++) {
    const endDate = new Date(startOfWeek);
    endDate.setDate(startOfWeek.getDate() + i);
    const date = {
      type: 'custom',
      'end-date': endDate.toISOString().split('T')[0]
    };
    dates.push(date);
  }
  return dates;
};

export const fetchRelatedElements = async (datasetElementIds) => {
  if (!datasetElementIds || !datasetElementIds.length) {
    return [];
  }
  try {
    const query = datasetElementIds.map((id) => `ids=${id}`).join('&');
    const response = await get(`/dataset-elements?${query}&page=1&size=25`);
    if (response && response.data) {
      return response.data.slice();
    }
    return [];
  } catch (error) {
    console.error('Error in fetchRelations', error);
  }
};

/**
 * Filters the given dates based on the current date.
 *
 * @param {Array} dates - An array of date objects to filter.
 * @param {Date} currentDate - The current date to use for filtering.
 * @returns {Array} - An array of filtered date objects.
 */

export const getOtherFiltersDates = (dates, currentDate) => {
  const datesValues = getCurrentWeekDates(currentDate);
  const extraDates = dates.filter((obj) => !datesValues.some((date) => date['end-date'] === obj['end-date']));
  return extraDates;
};
