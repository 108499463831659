/* eslint-disable max-lines */
export const BOARD_SIZE_3A0 = {
  width: 7560,
  height: 3600
};
export const RELATION_TYPES = ['parent', 'children', 'dependency'];

export const BOARD_SIZE = BOARD_SIZE_3A0;

export const BOARD_SIZE_DEFAULT = BOARD_SIZE_3A0;

export const BOARD_SIZES = {
  BOARD_SIZE_3A0
};

export const DEFAULT_BOARD_ELEMENT_SIZE = {
  height: '240',
  width: '320'
};

export const DEFAULT_CARD_SIZE = {
  height: '240',
  width: '320'
};

export const DEFAULT_POWERBI_SIZE = {
  height: '592',
  width: '759'
};

export const DEFAULT_WEBCONTENT_SIZE = {
  height: '592',
  width: '759'
};

export const DEFAULT_BOARDGET_SIZE = {
  height: '768',
  width: '1600'
};

export const DEFAULT_BUTTON_SIZE = {
  height: '72',
  width: '480'
};

export const DEFAULT_INDICATOR_SIZE = {
  height: '519',
  width: '685'
};

export const DEFAULT_NOTE_SIZE = {
  height: '260',
  width: '260'
};
export const boardElementsSizes = {
  'card': DEFAULT_CARD_SIZE,
  'list': DEFAULT_BOARDGET_SIZE,
  'powerbi': DEFAULT_POWERBI_SIZE,
  'webcontent': DEFAULT_WEBCONTENT_SIZE,
  'indicator': DEFAULT_INDICATOR_SIZE,
  'button': DEFAULT_BUTTON_SIZE,
  'note': DEFAULT_NOTE_SIZE
};

export const DEFAULT_WIDGET_SIZE = {
  height: '48',
  width: '100'
};

export const INPUT_KEYBOARD = {
  ENTER: 'Enter',
  ESCAPE: 'Escape'
};

export const VISUALIZATION_DEF_GRID_SIZE = {
  cols: 8,
  rows: 6
};

export const ZOOM = {
  DEFAULT_LEVEL: 0.17,
  LEVEL_MIN: 0.1,
  LEVEL_MAX: 3,
  LEVEL_OF_DETAIL_THRESHOLD: 0.6,
  PRECISION: 4,
  STEP_MULTIPLIER: 1.4,
  WHEEL_DELTA_RATIO: 0.001
};

export const VIEW_MARGIN = {
  HORIZONTAL: 15,
  VERTICAL: 15
};

export const MAIN_MENU_HEIGHT = 56;

export const INTERACTION_MODES = {
  PANNING: 'panning',
  SELECT: 'select',
  LINKS: 'links'
};
export const MUTATION_TYPES = {
  ERASE_PREVIOUS: 'erase_previous',
  APPEND_TO_PREVIOUS: 'append_to_previous'
};
export default {
  BOARD_SIZE,
  BOARD_SIZE_3A0,
  BOARD_SIZE_DEFAULT,
  BOARD_SIZES,
  INPUT_KEYBOARD,
  INTERACTION_MODES,
  VISUALIZATION_DEF_GRID_SIZE,
  ZOOM,
  VIEW_MARGIN,
  MAIN_MENU_HEIGHT,
  MUTATION_TYPES
};

export const VERSION_DATETIME_OPTIONS = {
  day: 'numeric', month: 'short', hour: 'numeric',
  minute: 'numeric'
};

export const USER_TECHNICAL_TYPE = 'technical';

export const VERSION_DROPDOWN_MENU_ITEMS = [
  {
    text: 'Board History',
    source: 'History',
    action: 'history',
    state: 'default',
    iconName: '',
    iconSize: 'default',
    type: 'menu'
  },
  {
    text: 'Board Settings',
    source: 'BoardSettings',
    action: 'toggleComponents',
    state: 'default',
    iconName: '',
    iconSize: 'default',
    type: 'menu'
  }
  /*{
    text: 'Board Versions',
    state: 'default',
    iconName: '',
    type: 'menu',
    subItemslist: [
      {
        text: 'Create Version',
        source: 'CreateVersion',
        action: 'createVersion',
        iconName: 'Plus'
      },
      {
        text: 'Show Version History',
        source: 'VersionHistory',
        action: 'toggleComponents',
        iconName: 'Eye'
      }
    ]
  }*/
];

export const BOARD_LEVELS = [
  'L6 M&S',
  'L5 Specialty Care',
  'L4 Dupixent',
  'L3 Waterford',
  'L3 Geel',
  'L2.5',
  'L2 Waterford/APU',
  'L2 Geel/APU',
  'L1 Waterford/APU/PCU',
  'L1 Geel/APU/PCU',
  'L0 Waterford/APU/PCU/Shift',
  'L0 Geel/APU/PCU/Shift'
];

//to remove after full MainMenu refactoring
export const VERSIONS_MENU_ITEMS = [
  {
    id: 1,
    title: 'Board versions',
    submenu: [
      {
        id: 2,
        title: 'Create a version',
        componentName: 'CreateVersion',
        action: 'createVersion'
      },
      {
        id: 3,
        title: 'Show version history',
        componentName: 'VersionHistory',
        action: 'toggleComponents'
      }
    ]
  },
  {
    id: 4,
    title: 'Board history',
    componentName: 'History',
    action: 'history'
  }
];

export const dataTypesIcons = {
  Objective: {
    types: ['CI Goal', 'CBN', 'Foundational Element'],
    icons: {
      parent: 'Goal',
      children: 'Goal'
    },
    color: 'other'
  },
  Project: {
    icons: {
      parent: 'FolderOpen',
      children: 'FolderOpen'
    },
    color: 'primary'
  },
  Issue: {
    icons: {
      parent: 'AlertTriangle',
      children: 'AlertTriangle'
    },
    color: 'alert'
  },
  Task: {
    icons: {
      parent: 'ClipBoardList',
      children: 'ClipBoardList'
    },
    color: 'success'
  },
  KPI: {
    icons: {
      parent: 'TrendingUp',
      children: 'TrendingUp'
    },
    color: 'secondary'
  },
  Action: {
    icons: {
      parent: 'MousePointerClick',
      children: 'MousePointerClick'
    },
    color: 'warning'
  }
};

export const VERSION_DETAILS_ITEMS = [
  {
    id: 1,
    title: 'Edit information',
    action: 'editBoardVersion'
  },
  {
    id: 2,
    title: 'Restore this version',
    action: 'restoreBoardVersion'
  },
  {
    id: 3,
    title: 'Create new board from this version',
    action: 'createBoardfromVersion'
  }
];

export const DEFAULT_USER_COLOR = `${`#${(Math.floor(Math.random() * 0xFFFFFF)).toString(16)}`}`;

export const VERSION_CATEGORIES = ['recentVersion', 'oldVersion', 'currentVersion'];

export const VERSION_MODES = ['createVersion', 'editVersion', 'markVersion', 'afterCreation'];

export const COMPARAISON_DATE_OPTIONS = { month: 'short', day: 'numeric', year: 'numeric' };

export const PAGE_SIZE = 50;
export const DATASET_ELEMENTS_PAGE_SIZE = 5;
export const HISTORY_PAGE_SIZE = 10;

export const MIN_ZOOM_LEVEL = 0.4;
export const FEEDBACK_BOTTOM_POSITION = -2;
export const FEEDBACK_LEFT_POSITION = -4;
export const FEEDBACK_INFO_BOX_TOP_POSITION = -46;

// Minimum hours count to display the number of days left until a version reaches 30 days
export const MINIMUM_HOURS = 24;

// Maximum days a version is alive if not marked
export const MAXIMUM_DAYS_UNMARKED_VERSION = 30;

export const LOCALHOST_API = 'localhost';

export const CHILDREN_COMPONENT_EMITTED_EVENTS = ['checked-child', 'keyword-change', 'existing-element-click', 'remove-selectors',
  'dataset-element-change', 'childrenToAdd-change'];

export const MAX_HEIGHT_HIDDEN_AVATARS = 300;
export const MAX_ELEMENTS_IN_DROPDOWN_LIST = 6;

export const MAX_HEIGHT_CHILDREN_DROPDOWN_LIST = 245;

export const FORMULA_TOP_EDITOR_EQUATION = {
  NUMERATOR: 139.809,
  EXPONENT: 0.9043,
  OFFSET: -96.72
};
export const FORMULA_TOP_CTX_MENU_EQUATION = {
  EXP_POW: -2.70052,
  EXP_TIMES: -149.622,
  OFFSET: -33.4254
};
export const e = 2.718;
export const MAX_ZL_SCALE_EDITOR = 0.25;

export const UNKNOWN_USER = {
  firstname: 'Unknown',
  lastname: 'User',
  picture: ''
};

export const DEFAULT_AVATAR_TEXT_COLOR = '#000';
export const BOARD_ELEMENT_EDITOR_TABS = ['General', 'Relations', 'History'];

export const COLOR_PALETTE = [
  '#D0D0D0',
  '#F3B1D1',
  '#D2BBFB',
  '#B1D1F7',
  '#C8E999',
  '#FFE97B',
  '#FFC179',
  '#FF8C75',
  '#FFC4B7',
  '#FFDEB8',
  '#FFF3B9',
  '#E1FDB9',
  '#D7E8FD',
  '#EBE2FD',
  '#FFE1F0',
  '#FFFFFF',
  '#FF8C75',
  '#FFC179',
  '#FFE97B',
  '#C8E999',
  '#B1D1F7',
  '#D2BBFB',
  '#F3B1D1',
  '#D0D0D0',
  '#FB593E',
  '#FFA444',
  '#FFDF48',
  '#AFD67A',
  '#8CB9F1',
  '#B997FA',
  '#E784B3',
  '#9E9E9E',
  '#F72B18',
  '#FF8822',
  '#FFD62E',
  '#97C35F',
  '#6AA2EC',
  '#A175FA',
  '#D95A96',
  '#2D2D2D'
];

export const SEARCH_MENU_HEIGHT = 220;

export const DEFAULT_CARD_FIELDS = [
  {
    startIcon: 'ALargeSmall',
    value: 'Title',
    disabled: true,
    style: 'width: 330px;',
    details: {}
  }
];

export const FIELD_TYPES = [
  {
    iconName: 'Hash',
    label: 'Number',
    iconColor: '#999B9E'
  },
  {
    iconName: 'Text',
    label: 'Text',
    iconColor: '#999B9E'
  },
  {
    iconName: 'Calendar',
    label: 'Date',
    iconColor: '#999B9E'
  },
  {
    iconName: 'ChevronDownCircle',
    label: 'Select',
    iconColor: '#999B9E',
    iconStroke: '#999B9E'
  },
  {
    iconName: 'User',
    label: 'User',
    iconColor: '#999B9E'
  }
];

export const FIELD_MENU_ITEMS = [
  {
    id: 1,
    title: 'Edit',
    action: 'editField'
  },
  {
    id: 2,
    title: 'Delete',
    textColor: '#f15454',
    action: 'deleteField'
  }
];

export const LINKS_TYPES = {
  HierarchicalRelationType: 'HIERARCHICAL_RELATION',
  DependencyType: 'DEPENDENCY'
};

export const LEADER_LINE_OPTIONS = {
  color: '#2D6BFF',
  outlineColor: 'rgba(30, 130, 250, 0.5)',
  size: 1,
  dash: false,
  endPlug: 'arrow2',
  endPlugSize: 2,
  allowedSockets: ['right', 'left']
};
export const MAX_DISPLAYED_VIEWTYPES = 10;
export const MINIMUM_DISPLAYED_VIEWTYPES = 1;

export const DEPENDECIES_DIRECTIONS = {inward: 'INWARD', outward: 'OUTWARD'};

export const VISUAL_LINKS_ACTIONS = {
  TYPEANDCOLOR: 'Type and color',
  THICKNESSANDSTYLE: 'Stroke style',
  DIRECTION: 'Reverse direction',
  DELETE: 'Remove relation'
};

export const DEFAULT_VISUAL_LINK_MENU_SIZE = {
  height: 48,
  width: 228
};

export const STROKESTYLE_VALUES = {
  solid: false,
  dash: { len: 4, gap: 4, animation: false },
  dot: { len: 2, gap: 5, animation: false }
};

export const STROKESTYLE_MENU = {
  Thickness: [
    {
      iconName: 'MinimumStroke',
      options: {
        size: 1,
        endPlugSize: 2
      }
    },
    {
      iconName: 'MediumStrokeFilled',
      options: {
        size: 2,
        endPlugSize: 1
      }
    },
    {
      iconName: 'MaximumStrokeFilled',
      options: {
        size: 3,
        endPlugSize: 1
      }
    }
  ],
  Style: [
    {
      iconName: 'MinimumStroke',
      options: {
        dash: STROKESTYLE_VALUES.solid
      }
    },
    {
      iconName: 'StrokeDash',
      options: {
        dash: STROKESTYLE_VALUES.dash
      }
    },
    {
      iconName: 'StrokeDot',
      options: {
        dash: STROKESTYLE_VALUES.dot
      }
    }
  ]
};

/* eslint-disable no-useless-escape */
export const POWERBI_URL_PATTERNS = {
  basePattern: /^https:\/\/app\.powerbi\.com\/(Redirect|groups\/[a-zA-Z0-9\-]{1,100}\/.*)$/,
  appPattern: /^https:\/\/app\.powerbi\.com\/(Redirect|groups\/[a-zA-Z0-9\-]{1,100}\/apps\/[a-zA-Z0-9\-]{1,100}\/(?:reports|dashboards)\/[a-zA-Z0-9\-]{1,100}(\/[a-zA-Z0-9]{1,100})?)/, //eslint-disable-line max-len
  dashboardPattern: /^https:\/\/app\.powerbi\.com\/groups\/[a-zA-Z0-9\-]{1,100}\/dashboards\/[a-zA-Z0-9\-]{1,100}.*$/,
  reportPattern: /^https:\/\/app\.powerbi\.com\/groups\/[a-zA-Z0-9\-]{1,100}\/reports\/[a-zA-Z0-9\-]{1,100}\/[a-zA-Z0-9\-]{1,100}.*$/,
  visualPattern: /^https:\/\/app\.powerbi\.com\/groups\/[a-zA-Z0-9\-]{1,100}\/reports\/[a-zA-Z0-9\-]{1,100}\/[a-zA-Z0-9\-]{1,100}.*visual=[a-zA-Z0-9]{1,100}.*$/ //eslint-disable-line max-len
};

export const IFRAME_PATTERN = /<iframe[^>]*src="([^"]*)"[^>]*>([\s\S]*?)<\/iframe>/;

export const URL_PATTERN = /^https:\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/;

export const DEFAULT_STATUS_BADGE_COLORS = {
  'Not started': 'secondary',
  'Off track': 'alert',
  'On track': 'primary',
  'Achieved': 'success',
  'Draft': 'secondary',
  'None': 'secondary',
  'In progress': 'primary',
  'New': 'secondary',
  'Canceled': 'secondary',
  'Active': 'primary',
  'At risk': 'warning',
  'Delay': 'alert',
  'High': 'alert',
  'Low': 'secondary',
  'Medium': 'primary',
  'Paused': 'secondary',
  'Closed': 'success',
  'Done': 'success',
  'Reached': 'success',
  'Critical': 'alert',
  'Planning': 'secondary'
};

export const DATA_TYPES_NAMES = {
  OBJECTIVE: 'Objective',
  PROJECT: 'Project',
  ISSUE: 'Issue',
  ACTION: 'Action',
  KPI: 'KPI',
  TASK: 'Task',
  LEVEL: 'Level'
};

export const DATA_TYPES_NAMES_SPACE = {
  ELEMENT: '/element-data-types/opex',
  SYSTEM: '/system-data-types/opex'
};

export const DEFAULT_PRIORITY_ICON_COLORS = {
  'High': 'alert',
  'Low': 'secondary',
  'Medium': 'primary'
};

export const DEFAULT_CARD_TEXT_COLOR = {
  light: 0xFFFFFF,
  dark: 0x000000
};

export const assetTypes = {
  BACKGROUND_IMAGE: 'BACKGROUND_IMAGE',
  BOARD_IMAGE: 'BOARD_IMAGE'
};

export const INDICATOR_CHART_PADDING = 20;
export const CARD_SIZE_MAP = {
  S: { width: 250, height: 188 },
  M: { width: 320, height: 240 },
  L: { width: 450, height: 338 },
  XL: { width: 575, height: 431 }
};

export const SELECTION_AREA_CONTEXTUAL_MENU = [
  {
    componentType: 'text',
    text: 'selectedElementsCount',
    hasSeparator: true
  },
  {
    componentType: 'icon',
    iconName: 'Lock',
    action: 'onLockToggle',
    hasSeparator: true
  },
  {
    iconName: 'Trash',
    action: 'onDelete',
    componentType: 'icon'
  }
];

export const CARD_TEXT_FONT_SIZES = {
  level2: {
    S: {fontSize: 31, lineHeight: 42.5},
    M: {fontSize: 40, lineHeight: 54},
    L: {fontSize: 56, lineHeight: 76.5},
    XL: {fontSize: 60, lineHeight: 90}
  },
  level3: {
    S: {fontSize: 22, lineHeight: 34.5},
    M: {fontSize: 28, lineHeight: 44},
    L: {fontSize: 39.5, lineHeight: 62},
    XL: {fontSize: 48, lineHeight: 71}
  }
};

export const CARD_FRIENDLY_ID_FONT_SIZE = 16;

export const CARD_LOWER_FIELDS_FONT_SIZES = {
  level2: 16,
  level3: 14
};

export const CARD_UPPER_FIELDS_FONT_SIZES = {
  level2: 40,
  level3: 28
};

export const CARD_UPPER_FIELDS_LINE_HEIGHTS = {
  level2: 54.48,
  level3: 44
};

export const CARD_LOWER_FIELDS_LINE_HEIGHTS = {
  level2: 24,
  level3: 24
};

export const CARD_LOWER_FIELDS_ICON_SIZE = {
  level2: 24,
  level3: 12
};

export const CARD_LOWER_FIELDS_COMPONENT_SIZE = {
  level2: 48,
  level3: 24
};

export const LEVEL2_CARD_BADGE_SIZE = 32;

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const dockToggle = [];
export const dockToolsetToggle = [];

export const NOTE_SIZES = {
  S: '180',
  M: '260',
  L: '360',
  XL: '460'
};

export const NOTE_FONT_SIZES = {
  S: {fontSize: '31.25', padding: 6},
  M: {fontSize: '40',  padding: 8},
  L: {fontSize: '56.25',  padding: 11},
  XL: {fontSize: '71.88', padding: 14}
};

export const CHAR_OPTIONS = {
  yaxis: { min: 0, labels: { style: { fontSize: '28px'}, offsetX: 50 }},
  legend: {
    position: 'bottom',
    fontSize: '28px',
    offsetY: 22,
    height: 60
  },
  grid: {
    padding: {
      left: 50
    }
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '30%',
      endingShape: 'rounded'
    }
  },
  stroke: {
    width: [1, 1]
  }
};

export const OPTIONS = {
  chart: {
    id: 'basic-bar',
    background: '#FFFFFF'
  },
  tooltip: {
    enabled: true,
    shared: false,
    intersect: true,
    followCursor: true
  },
  markers: {
    size: 5,
    strokeColors: '#2D6BFF',
    hover: {
      size: 5
    }
  },
  ...CHAR_OPTIONS
};

export const SERIES = [
  {
    name: 'series-1',
    data: [30, 40, 45, 50, 49, 60, 70, 91, 125]
  }
];

export const ToolTypes = {
  IMAGE: 'image',
  CARD: 'card',
  NOTE: 'note',
  POWERBI: 'powerbi',
  LIST: 'list',
  INDICATOR: 'indicator',
  BUTTON: 'button',
  WEBCONTENT: 'webcontent'
};

export const HIERARCHY = {
  IMPORT_PATH: '/hierarchy-import',
  VIEW_PATH: '/hierarchy',
  LEVELS_COLORS: [
    { textColor: '#1D51CE', bgColor: '#DFE8FF' },
    { textColor: '#774D0D', bgColor: '#FDE491' },
    { textColor: '#A43434', bgColor: '#FBE3E3' },
    { textColor: '#00676B', bgColor: '#DAECEC' },
    { textColor: '#644DA1', bgColor: '#EBE5FB' },
    { textColor: '#5D587B', bgColor: '#E8E7EC' }
  ]
};

export const CARD_LOWER_PART_SIZE_TO_STYLE_MAP = {
  S: {
    lowerPartHeight: '0px',
    lineHeight: 36,
    titleStyle: 'font-size: 24px; line-height: 36px; font-weight: 600',
    iconSize: 'xsmall'
  },
  M: {
    lowerPartHeight: '44px',
    lineHeight: 44,
    titleStyle: 'font-size: 40px; line-height: 44px; font-weight: 600',
    iconSize: 'default',
    badgeHeight: 30,
    bottomTextStyle: 'font-size: 14px !important'
  },
  L: {
    lowerPartHeight: '56px',
    lineHeight: 64,
    titleStyle: 'font-size: 50px; line-height: 64px; font-weight: 600',
    iconSize: 'xl',
    badgeHeight: 38,
    bottomTextStyle: 'font-size: 18px !important'
  },
  XL: {
    lowerPartHeight: '72px',
    lineHeight: 74,
    titleStyle: 'font-size: 60px; line-height: 74px; font-weight: 600',
    iconSize: '2xl',
    badgeHeight: 48,
    bottomTextStyle: 'font-size: 20px !important'
  },
  XXL: {
    lowerPartHeight: '88px',
    lineHeight: 84,
    titleStyle: 'font-size: 70px; line-height: 84px; font-weight: 600',
    iconSize: '3xl',
    badgeHeight: 64,
    bottomTextStyle: 'font-size: 24px !important'
  },
  XXXL: {
    lowerPartHeight: '120px',
    lineHeight: 84,
    titleStyle: 'font-size: 70px; line-height: 84px; font-weight: 600',
    iconSize: '4xl',
    badgeHeight: 96,
    bottomTextStyle: 'font-size: 28px !important'
  }
};
export const MINIMUM_SIZES_MAP = {
  card: {
    height: 100,
    width: 130
  },
  button: {
    height: 36,
    width: 240
  },
  indicator: {
    height: 200,
    width: 300
  },
  image: {
    height: 100,
    width: 100
  },
  list: {
    height: 328,
    width: 992
  },
  webcontent: {
    height: 512,
    width: 679
  },
  powerbi: {
    height: 512,
    width: 679
  }

};
export const zoomLevelsArray = [25, 33, 50, 67, 75, 80, 90, 100, 110, 125, 150, 175, 200, 250, 300, 400, 500];
export const FEATURES = {
  WEB_CONTENT: 'WEB_CONTENT_FEATURE',
  CARD_LINK_ATTACHMENT: 'CARD_LINK_ATTACHMENT_FEATURE'
};
export const BOARDGET_LIST_BREAKPOINT = 1300;

export const RESIZE_TYPE = {
  FREE: 'FREE',
  FREE_HOMOTHETIC: 'FREE_HOMOTHETIC'
};
export const GLIDEPATH_KEYS_LIMIT = 8;
export const NO_EDITOR_ON_CREATION = ['List', 'Image', 'Bookmark', 'Embed'];

export const BOARDGET_LIST_ITEM_HEIGHT = 102.4;
export const BOARDGET_LIST_ADDITIONAL_HEIGHT = 184;
export const CHANGE_SOURCE = {
  CURRENT_USER: 'Current_User',
  OTHER_USER: 'Other_User'
};
export const USER_INTERACTIONS = {
  DRAG: 'UserInteractions_Drag',
  POINT: 'UserInteractions_Point',
  RESIZE: 'UserInteractions_Resize'
};

export const QUICK_FILTERS_KEYS = ['owner', 'due-date'];
export const EDITOR_HIDDEN_FEATURES = {
  'attachments': FEATURES.CARD_LINK_ATTACHMENT
};
