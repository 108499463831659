<template>
  <div>
    <list-boardget-modal
      v-if="isBoardgetCreation"
      v-bind="componentMap[creationComponent].props"
    />
    <card-creation-modal
      v-if="isCardCreation"
      v-bind="componentMap[creationComponent].props"
    />
    <EmbedCreationModal v-if="isEmbedCreation" />
    <ImageCreation v-if="isImageCreation" />
    <BookmarkButtonCreationModal v-if="isBookmarkButtonCreation" />
  </div>
</template>

<script setup>
import ListBoardgetModal from '../modals/ListBoardgetModal/ListBoardgetModal.vue';
import CardCreationModal from '../modals/CardCreationModal/CardCreationModal.vue';
import EmbedCreationModal from './components/Embed/modals/EmbedCreationModal.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import ImageCreation from '../modals/ImageCreation/ImageCreation.vue';
import BookmarkButtonCreationModal from '../bookmark/modals/BookmarkButtonCreationModal.vue';

const store = useStore();
const componentMap = ref({
  CardCreationModal: {
    componentName: 'CardCreationModal',
    props: {}
  },
  ListBoardgetModal: {
    componentName: 'ListBoardgetModal',
    props: {}
  },
  EmbedCreationModal: {
    componentName: 'EmbedCreationModal',
    props: {}
  },
  BookmarkButtonCreationModal: {
    componentName: 'BookmarkButtonCreationModal',
    props: {}
  },
  ImageCreation: {
    componentName: 'ImageCreation',
    props: {}
  }
});

const isBoardgetCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'ListBoardgetModal');
const isCardCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'CardCreationModal');
const isEmbedCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'EmbedCreationModal');
const isImageCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'ImageCreation');
const isBookmarkButtonCreation = computed(() => componentMap.value[creationComponent.value].componentName === 'BookmarkButtonCreationModal');
const creationComponent = computed(() => store.state.dock.creationComponent);
</script>
