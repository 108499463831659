<template>
  <dialog-box
    :dialog-box-specific-style="dialogBoxStyle"
    :no-padding="true"
    has-submit-group
    :disabled="isSaveDisabled"
    @submit-click="onSubmit"
    @cancel-click="openToolsConfigModal"
  >
    <div class="ToolsConfig-header">
      <span class="ToolsConfig-title"> Add field </span>
    </div>
    <div class="ToolsConfig-horizontal-divider" />

    <div class="relative flex flex-col p-5 mb-14">
      <div class="flex">
        <label-field
          id="field-name"
          label="Field name"
          input-style="width: 300px;"
          :model-value="fieldNameValue"
          :placeholder="placeHolders[fieldTypebeingManaged.label]"
          :has-error="displayErrorMessage"
          :error-message="errorMessage"
          border-color="border-gray"
          mode="gray"
          shape="rounded"
          outlined
          @update:modelValue="fieldNameValue = $event"
        />
        <dropdown-button-flowbite
          :options="fieldTypes"
          :disabled="isEditMode"
          title="Field type"
          :default-value="fieldTypebeingManaged"
          button-style="width: 300px"
          search-menu-style="width: 300px"
          options-container-height="120"
          mode="gray"
          border-color="border-gray"
          :search-input="searchInput"
          icon-color="#999B9E"
          icon-stroke="#999B9E"
          @click-item="setFieldType"
        />
      </div>
      <component
        :is="components[fieldTypebeingManaged.label]"
        :field-item="fieldItem"
        @select-item="setFormValue"
      />

      <div v-if="isFormulaBlockDisplayed">
        <div class="relative flex items-baseline">
          <Checkbox
            id="checkbox-based-on-formula"
            class="mt-5"
            label="Based on a formula"
            :checked="isBasedOnFormulaChecked"
            outlined
            @checkbox-event="setCheckbox"
          />
          <div
            v-show="displayCreate && !fieldTypeObject.computedByFormula"
            class="vertical-divider"
          />
          <div
            v-show="displayCreate && !fieldTypeObject.computedByFormula"
            class="flex items-start"
          >
            <icon-loader
              id="add-formula"
              class="relative right-1"
              name="PlusCircle"
              color="black"
              size="default"
              @click="displayFormulaEditor"
            />
            <label
              class="relative mb-2 text-sm font-medium text-gray-900 left-2"
            >Create</label>
          </div>
        </div>
        <outside-click-listener
          @outside-click="handleClickOutsideFormulaEditor"
        >
          <formula-widget-editor
            v-if="isFormulaEditorOpen"
            class="absolute z-10 bg-white shadow dark:bg-gray-700"
            :style="'top: 75%; width: 60%;'"
            :is-formula-name-shown="false"
            :attributes="attributes"
            :board-element-id="'sfs'"
            :error="error"
            :set-error="setError"
            :done-action="doneAction"
            @update:expression="attributes.expression = $event"
          />
        </outside-click-listener>
        <label-field
          v-show="displayFormula"
          class="mt-3"
          input-style="width: 100%;"
          :model-value="attributes.expression"
          :end-icon-action="deleteFormula"
          end-icon="X"
          disabled
          mode="gray"
          shape="rounded"
        />
      </div>
      <checkbox
        id="checkbox-summary"
        class="mt-5"
        label="Create a rollup field on parent"
        :checked="hasSummary"
        :value="hasSummary"
        outlined
        @checkbox-event="toggleHasSummary"
      />
    </div>
  </dialog-box>
</template>

<script>
import 'TOOLS_CONFIG/ToolsConfig.css';
import { mapMutations, mapState } from 'vuex';
import AddFieldTypeNumber from 'TOOLS_CONFIG/Customization/Fields/FieldTypes/AddFieldTypeNumber/AddFieldTypeNumber.vue';
import AddFieldTypeSelect from 'TOOLS_CONFIG/Customization/Fields/FieldTypes/AddFieldTypeSelect/AddFieldTypeSelect.vue';
import FormulaWidgetEditor from 'BOARD/components/widgets/formula/FormulaWidgetEditor.vue';

export default {
  name: 'AddFieldComponent',
  components: {
    AddFieldTypeNumber,
    FormulaWidgetEditor
  },
  data() {
    return {
      dialogBoxStyle: 'position: absolute; max-height: 70%; height: fit-content; left: 30%; top: 25%;',
      components: {
        Number: AddFieldTypeNumber,
        Select: AddFieldTypeSelect
      },
      placeHolders: {
        Number: 'Hours, cost, story points...',
        Text: 'Description, address...',
        Date: 'Closing date, estimated launch...',
        Select: 'Priority, status...',
        User: 'Owner, assignee...'
      },
      fieldNameValue: '',
      searchInput: {
        placeholder: 'Search for a type...',
        startIcon: 'Search',
        value: ''
      },
      defaultTypes: {
        Text: 'string',
        Select: 'string',
        Number: 'number',
        User: 'user'
      },
      fieldTypeObject: {},
      isFormulaEditorOpen: false,
      attributes: { expression: '' },
      error: false,
      displayCreate: false,
      displayFormula: false,
      isBasedOnFormulaChecked: false,
      hasSummary: false,
      fieldNameCheck: true,
      displayErrorMessage: false,
      errorMessage: 'This name is already taken',
      isEditMode: false,
      fieldItem: null,
      timer: null
    };
  },
  computed: {
    ...mapState('board', ['dialogBoxFlags']),
    ...mapState('toolsConfig', ['fieldTypebeingManaged', 'fieldTypes', 'fields', 'fieldId', 'fieldPlacement', 'openedTile']),
    isSaveDisabled() {
      return !this.fieldNameValue.length || !this.fieldNameCheck || (this.isBasedOnFormulaChecked && !this.fieldTypeObject.computedByFormula);
    },
    isFormulaBlockDisplayed() {
      return ['Number', 'Date', 'Text'].includes(this.fieldTypebeingManaged.label);
    }
  },
  watch: {
    fieldTypebeingManaged: {
      handler(value) {
        this.fieldNameValue = '';
        if (Object.keys(this.defaultTypes).includes(value.label)) {
          this.fieldTypeObject.type = this.defaultTypes[value.label];
          return;
        }
        this.fieldTypeObject.type = value.label.toLowerCase();
      },
      immediate: true,
      deep: true
    },
    fieldNameValue(value) {
      const field = this.fields.find((field) => field.value.toLowerCase() === value.toLowerCase());
      if (field && field.id !== this.fieldId) {
        this.fieldNameCheck = false;
        this.displayErrorMessage = true;
        return;
      }
      this.displayErrorMessage = false;
      this.fieldNameCheck = true;
    },
    fieldId: {
      handler(value) {
        if (value) {
          const field = this.fields.find((field) => field.id === value);
          this.fieldNameValue = field.value;
          this.fieldItem = field;
          this.isEditMode = true;
          this.hasSummary = field.summary;

          if (!field.details.computedByFormula) {
            return;
          }

          this.displayFormula = true;
          this.isBasedOnFormulaChecked = true;
          this.attributes.expression = field.details.computedByFormula.expression;
          this.fieldTypeObject.computedByFormula = {
            expression: field.details.computedByFormula.expression
          };
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    // document.getElementById('field-name').focus();
  },
  beforeUnmount() {
    clearTimeout(this.timer);
  },
  unmounted() {
    this.setFieldTypeId(null);
    this.setFieldTypebeingManaged({});
    this.fieldItem = null;
    this.isEditMode = false;
  },
  methods: {
    ...mapMutations('board', ['setDialogBoxFlags']),
    ...mapMutations('toolsConfig', [
      'setFieldTypebeingManaged',
      'setFields',
      'setFieldTypeId',
      'updateFields',
      'setFieldPlacement'
    ]),
    setFieldType(value) {
      this.setFieldTypebeingManaged({value});
    },
    openToolsConfigModal() {
      this.setDialogBoxFlags({
        ...this.dialogBoxFlags,
        isAddFieldDialogOpened: false,
        isToolsConfigDialogOpened: true
      });
    },
    applySearchFilter(search, fieldTypes) {
      return fieldTypes.filter((type) =>
        type.label.toLowerCase().includes(search.toLowerCase())
      );
    },
    setFormValue(formFieldValue) {
      const formFieldValueKeys = Object.keys(formFieldValue);
      const name =
        formFieldValueKeys && formFieldValueKeys.length
          ? formFieldValueKeys[0]
          : null;
      const fieldValue = formFieldValue[name];
      this.fieldTypeObject[name] = fieldValue;
    },
    formatString(string) {
      return string.replace(/[^a-zA-Z0-9]/g, '').replace(/' '/g, '');
    },
    onSubmit() {
      this.setDialogBoxFlags({
        ...this.dialogBoxFlags,
        isAddFieldDialogOpened: false,
        isToolsConfigDialogOpened: true
      });
      this.fieldTypeObject.friendlyName = this.fieldNameValue;
      this.fieldTypeObject.name = this.formatString(this.fieldNameValue);
      this.fieldTypeObject.originRef = `/${this.formatString(this.fieldNameValue)}`;

      if (!this.isEditMode) {
        const newField = {
          id: `custom-field-${this.fields.length}`,
          disabled: true,
          startIcon: this.fieldTypebeingManaged.iconName,
          startIconColor: '#999B9E',
          endIcon: 'MoreVertical',
          endIconColor: '#999B9E',
          value: this.fieldNameValue,
          fieldType: this.fieldTypebeingManaged.label,
          style: 'width: 330px;',
          details: this.fieldTypeObject,
          summary: this.hasSummary
        };
        this.setFields(newField);
        if (this.openedTile) {
          this.setFieldPlacement({
            ...this.fieldPlacement,
            [this.openedTile.tileName]: {
              friendlyName: this.fieldNameValue,
              startIcon: this.fieldTypebeingManaged.iconName,
              value: this.formatString(this.fieldNameValue),
              disabled: true,
              type: this.fieldTypebeingManaged.label
            }
          });
        }
        return;
      }

      const fields = this.fields;
      fields.map((field) => {
        if (field.id === this.fieldId) {
          field.value = this.fieldNameValue;
          field.details = this.fieldTypeObject;
          field.summary = this.hasSummary;
        }
        return field;
      });
      this.updateFields(fields);
    },
    toggleHasSummary() {
      this.hasSummary = !this.hasSummary;
      if (this.hasSummary) {
        delete this.fieldTypeObject.computedByFormula;
        this.isBasedOnFormulaChecked = false;
        this.attributes.expression = '';
        this.displayFormula = false;
        this.displayCreate = false;
      }
    },
    setCheckbox({event}) {
      this.isBasedOnFormulaChecked = event.target.checked;
      if (event.target.checked) {
        this.hasSummary = false;
        this.isFormulaEditorOpen = true;
        this.displayCreate = true;
        return;
      }
      this.setError(false);
      this.deleteFormula();
      this.attributes.expression = '';
      this.isFormulaEditorOpen = false;
      this.displayCreate = false;
      this.displayFormula = false;
    },
    handleClickOutsideFormulaEditor(event) {
      if (
        !document.getElementById('checkbox-based-on-formula').contains(event.target) &&
        !document.getElementById('add-formula').contains(event.target)
      ) {
        this.isFormulaEditorOpen = false;
      }
    },
    displayFormulaEditor() {
      if (this.isFormulaEditorOpen) {
        return;
      }
      this.isFormulaEditorOpen = true;
    },
    setError(value) {
      this.error = value;
    },
    doneAction() {
      this.timer =  setTimeout(() => {
        this.setFieldTypeObject();
      }, 900);
    },
    setFieldTypeObject() {
      if (!this.error && this.attributes.expression.length) {
        this.fieldTypeObject.computedByFormula = {
          expression: this.attributes.expression
        };
        this.isFormulaEditorOpen = false;
        this.displayFormula = true;
      }
    },
    deleteFormula() {
      delete this.fieldTypeObject.computedByFormula;
      this.attributes.expression = '';
      this.displayFormula = false;
      this.displayCreate = true;
    }
  }
};
</script>
