import { USERS_SERVICE_URL } from 'SRC/config/services';
import { get } from 'SRC/api/index.js';
import storageService from '../storageService';
import apiUtils from 'SRC/store/utils';
const { ALL_USERS_KEY } = storageService.sessionKeys;
export const saveUsers = (users) => storageService.saveToLocal(ALL_USERS_KEY, users);
export const getUsers = () => storageService.getFromLocal(ALL_USERS_KEY);
const circuitBreakerInfo =  {isUsersAPI: true, isActivated: true, interrupts: false };

const actions = {
  async fetchAllUsers({ commit }) {
    const users = await get(`${USERS_SERVICE_URL}/users/list`, null, true, false, circuitBreakerInfo);
    let usersData = null;
    if (users && users.data) {
      usersData = await apiUtils.getNextPage(
        users,
        users.data,
        get,
        false,
        circuitBreakerInfo
      );
      saveUsers(usersData);
    }
    usersData = usersData || getUsers();
    if (!usersData) {
      return { error: 'Error occured in fetchAllUsers' };
    }
    commit('setUsers', usersData);
    return users;
  },
  async fetchCurrentUser({ commit }) {
    const user = await get(`${USERS_SERVICE_URL}/users/me`, null, true, false, circuitBreakerInfo);
    if (user) {
      commit('setCurrentUser', user);
      return user;
    }
    return { error: 'Error occured in fetchCurrentUser' };
  }
};

export default actions;
