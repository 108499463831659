<template>
  <div
    :key="defaultXaxis.length"
    :style="containerStyle"
  >
    <apex-chart
      v-if="!isResizing"
      :id="elementId"
      data-target="board-element"
      :width="width - INDICATOR_CHART_PADDING"
      :height="height - INDICATOR_CHART_PADDING"
      type="line"
      :options="defaultXaxis"
      :series="defaultYaxis"
      :style="`min-height: ${height - 20}`"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
import { defineProps, computed } from 'vue';
import { DatesSorter } from './DatesSorter';
import { INDICATOR_CHART_PADDING, OPTIONS, SERIES, GLIDEPATH_KEYS_LIMIT } from 'GLOBALS/constants';
import { getGlidepathValuesFromCurrentDate } from './IndicatorChartUtils';

const store = useStore();

const props = defineProps({
  attributes: {
    type: Object,
    default: () => {}
  },
  elementId: {
    type: String,
    required: true
  },
  datasetElementId: {
    type: String,
    required: true
  },
  hasOutline: {
    type: Boolean,
    default: false
  },

  outlineColor: {
    type: String,
    required: true
  },
  height: {
    type: Number,
    required: true
  },
  width: {
    type: Number,
    required: true
  },
  canBeHighlighted: {
    type: Boolean,
    default: true
  },
  isResizing: {
    type: Boolean,
    required: true
  }
});

const data = computed(() => store.state.board.indicatorsRelations);
const zoomLevel = computed(() => store.state.board.zoomLevel);
const containerStyle = computed(() => {
  const initialStyle = {
    height: `${props.height}px`,
    width: `${props.width}px`,
    cursor: 'default'
  };
  if (props.hasOutline) {
    return {
      ...initialStyle,
      borderRadius: '8px',
      outline: `${Math.max(2, 2 * (1 / zoomLevel.value))}px ${props.outlineColor} solid`
    };
  }
  return { ...initialStyle };
});
const relatedDatasetElements = computed(() => {
  if (!data.value) {
    return '';
  }
  const relatedDatasetElements = data.value[props.datasetElementId] || '';
  return relatedDatasetElements;
});
const graphData = computed(() => {
  if (!relatedDatasetElements.value) {
    return '';
  }
  const graphDatas = relatedDatasetElements.value[0];
  if (graphDatas.attributes && Object.keys(graphDatas.attributes.glidepathValues).length) {
    const {attributes} = graphDatas;
    const formattedGlidepathValues =
    getGlidepathValuesFromCurrentDate(attributes.glidepathValues, attributes.glidepathFrequency, GLIDEPATH_KEYS_LIMIT);
    graphDatas.attributes.glidepathValues = formattedGlidepathValues;
  }
  return graphDatas;
});
const defaultXaxis = computed(() => {
  if (
    !graphData.value ||
    !graphData.value.attributes ||
    !graphData.value.attributes.glidepathValues
  ) {
    return OPTIONS;
  }
  const frequency = graphData.value.attributes.glidepathFrequency;
  const datesArray = Object.keys(graphData.value.attributes.glidepathValues);
  const datesSorterInstance = new DatesSorter(frequency, datesArray);
  const ordered = datesSorterInstance.generate();
  const datasetElement = store.state.board.datasetElements[props.datasetElementId];
  const title =
    datasetElement && datasetElement.attributes ? datasetElement.attributes.name : '';
  return {
    ...OPTIONS,
    stroke: {
      width: 5
    },
    title: {
      text: title,
      align: 'left',
      style: { fontSize: '28px' }
    },
    xaxis: {
      categories: ordered,
      labels: { style: { fontSize: '28px', rotate: -45 } }
    }
  };
});
const defaultYaxis = computed(() => {
  if (
    !graphData.value ||
    !graphData.value.attributes ||
    !graphData.value.attributes.glidepathValues
  ) {
    return SERIES;
  }

  const frequency = graphData.value.attributes.glidepathFrequency;
  const datesArray = Object.keys(graphData.value.attributes.glidepathValues);
  const datesSorterInstance = new DatesSorter(frequency, datesArray);
  const ordered = datesSorterInstance.generate();

  const glidepathValues = graphData.value.attributes.glidepathValues;
  const { targetValueComparator } = graphData.value.attributes;
  const targetAchievedData = [];
  const targetNotAchievedData = [];

  ordered.forEach((el) => {
    const traceValue = graphData.value.traces.get(el) || 0;
    const glidepathValueToday = glidepathValues[el] || 0;
    const targetAchievedCondition =
      (targetValueComparator === '≤' && traceValue <= glidepathValueToday) ||
      (targetValueComparator === '≥' && traceValue >= glidepathValueToday) ||
      (targetValueComparator === '<' && traceValue < glidepathValueToday) ||
      (targetValueComparator === '>' && traceValue > glidepathValueToday);

    const targetNotAchievedCondition =
      (targetValueComparator === '≤' && traceValue > glidepathValueToday) ||
      (targetValueComparator === '≥' && traceValue < glidepathValueToday) ||
      (targetValueComparator === '<' && traceValue >= glidepathValueToday) ||
      (targetValueComparator === '>' && traceValue <= glidepathValueToday);

    targetAchievedData.push(targetAchievedCondition ? traceValue : null);
    targetNotAchievedData.push(targetNotAchievedCondition ? traceValue : null);
  });

  return [
    {
      name: 'Target',
      type: 'line',
      data: ordered.map((el) => glidepathValues[el] || 0),
      color: '#2D6BFF'
    },
    {
      name: 'Target achieved',
      type: 'column',
      data: targetAchievedData,
      color: '#23C773'
    },
    {
      name: 'Target not achieved',
      type: 'column',
      data: targetNotAchievedData,
      color: '#E81B11'
    }
  ];
});

</script>
