<template>
  <div class="TagsField">
    <div :class="tagsFieldContainerStyles">
      <div class="TagsField-tags">
        <span
          v-for="(tag, index) in getDisplayTags"
          :key="index"
          class="TagsField-tags-tag"
        >
          {{ tag[mapFilterByKey] }}
          <button
            class="TagsField-deleteBtn"
            @click.stop="removeTag(tag)"
          >x</button>
        </span>
        <span
          v-if="hiddenTagsCount > 0"
          class="TagsField-tags-tag"
        >
          {{ `+${hiddenTagsCount}` }}
        </span>
      </div>
      <input
        v-model="searchQuery"
        class="TagsField-input"
        :placeholder="`Type to search ${props.fieldName}`"
        @click="showDropdown = true"
        @input="filterDropdown"
      >
    </div>
    <outside-click-listener
      v-if="showDropdown"
      @outside-click="showDropdown = false"
    >
      <iob-dropdown
        v-if="showDropdown && remainingItems.length"
        class="TagsField-dropdown"
        :items="remainingItems"
        @dropdown-element-item="selectItem"
      />
    </outside-click-listener>
    <p
      v-if="error"
      class="TagsField-error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },
  fieldName: {
    type: String,
    default: ''
  },
  componentType: {
    type: String,
    default: ''
  },
  selectedValues: {
    type: Array,
    default: () => []
  },
  modelValue: {
    type: Array,
    default: () => []
  }
});

const emit = defineEmits(['select', 'remove', 'update:modelValue']);

const searchQuery = ref('');
const showDropdown = ref(false);
const error = ref(false);
const errorMessage = ref('');

const tagsFieldContainerStyles = computed(() => ({
  'TagsField-input-container': true,
  'TagsField-input-container--error': error.value
}));

const mapFilterByKey = computed(() => props.componentType === 'avatarList' ? 'title' : 'text');

const getDisplayTags = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.slice(0, 3);
  } else {
    return props.modelValue;
  }
});

const hiddenTagsCount = computed(() => props.modelValue.length > 3 ? props.modelValue.length - 3 : 0);

const remainingItems = computed(() => {
  if (error.value) {
    return [];
  }
  return props.items.filter(
    (item) => !props.modelValue.some(
      (tag) => tag[mapFilterByKey.value] === item[mapFilterByKey.value]
    )
  ).filter((item) => item[mapFilterByKey.value].toLowerCase().includes(searchQuery.value));
});

const selectItem = ({ item }) => {
  searchQuery.value = '';
  showDropdown.value = false;
  errorMessage.value = '';
  error.value = false;
  emit('update:modelValue', [item, ...props.modelValue]);
};

const removeTag = (tagToRemove) => {
  const newModelValue = props.modelValue.filter((item) => item[mapFilterByKey.value] !== tagToRemove[mapFilterByKey.value]);
  emit('update:modelValue', newModelValue);
};

</script>

<style src="./TagsField.css" />
