import { createMachine } from 'xstate';

const machine = createMachine({
  id: 'panningMachine',
  initial: 'idle',
  predictableActionArguments: true,
  states: {
    idle: {
      on: {
        mouseClickElement: {
          target: 'simpleElementSelected'
        },
        pressOnBoard: {
          target: 'grabBoard',
          actions: ['panStart']
        }
      }
    },
    simpleElementSelected: {
      on: {
        mouseClickElement: { target: 'simpleElementSelected' },
        pressOnBoard: { target: 'grabBoard' },
        escape: { target: 'idle'},
        mousClickBoard: { target: 'idle'}
      }
    },
    grabBoard: {
      on: {
        mouseUp: {
          target: 'idle',
          actions: ['effect']
        },
        mouseDrag: {
          target: 'panning',
          actions: ['panMove']
        }
      }
    },
    panning: {
      on: {
        stopMoving: {
          target: 'grabBoard',
          actions: ['panMove']
        },
        mouseUp: {
          target: 'idle',
          actions: ['panEnd']
        },
        mouseDrag: {
          target: 'panning',
          actions: ['panMove']
        }
      }
    }
  }
});

const options = {
  actions: {
    panMove: (ev, {board, event}) => {
      if (!board.panning) {
        return;
      }
      const panOffsetX = event.clientX - board.previousPanX;
      const panOffsetY = event.clientY - board.previousPanY;

      board.setPanX(board.panX + (panOffsetX / board.zoomLevel));
      board.setPanY(board.panY + (panOffsetY / board.zoomLevel));

      board.updateViewportReferencePoint();

      board.previousPanX = event.clientX;
      board.previousPanY = event.clientY;
      board.resetCanSelect();
    },
    panStart: (ev, {board, event}) => {
      // eslint-disable-next-line no-undef
      board.panning = true;
      board.previousPanX = event.clientX;
      board.previousPanY = event.clientY;
      board.setCursor({value: 'grabbing', lock: true});
    },
    panEnd: (ev, {board}) => {
      board.panning = false;
      // eslint-disable-next-line max-lines
      board.panOffsetX = 0;
      board.panOffsetY = 0;
      board.unlockCursor();
      board.setCursor({value: 'grab', lock: false});
    }
  }
};
export default {machine, options};
