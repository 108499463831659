<template>
  <div
    class="cardLayout-container"
  >
    <div
      v-for="({tiles, id}, index) in layouts"
      :id="id"
      :key="index"
      class="cardLayout-layout"
      :class="selectedLayout == id && 'cardLayout-selected'"
      @click="selectLayout(id)"
    >
      <div
        class="cardLayout-tiles"
      >
        <div
          v-for="tile in filterTiles(tiles)"
          :key="tile"
          :style="getStyle(tile)"
        />
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardLayout',
  props: {
    layouts: {
      type: Array,
      required: true
    },
    selectedLayout: {
      type: String,
      required: true
    },
    selectLayout: {
      type: Function,
      required: true
    }
  },
  methods: {
    getStyle(tile) {
      return `
          grid-column-start: ${tile.x};
          grid-column-end: ${tile.x + tile.w};
          grid-row-start: ${tile.y - 1};
          grid-row-end: ${tile.y + tile.h - 1};
          background-color: #D9D9D9
        `;
    },
    filterTiles: (tiles) => tiles.filter((tile) => (!!tile.label || tile.tileName === 'center'))
  }
};
</script>

<style scoped>
.cardLayout-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.cardLayout-layout {
  padding: 20px;
  margin-right: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background: #f3f3f4;
  border-radius: 6px;
}

.cardLayout-selected {
  box-shadow: 0 0 0 2px #004afa inset;
}

.cardLayout-layout:hover {
  box-shadow: 0 0 0 2px #004afa inset;
  cursor: pointer;
}

.cardLayout-tiles {
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-template-rows: repeat(5, minmax(0, 1fr));
  width: 68px;
  border: 1px solid #767676;
  border-radius: 3px;
  border-collapse: separate;
  overflow: hidden;
  height: 52px;
  grid-gap: 1px;
  background: rgb(118 118 118 / 100%);
  border-top: 1px solid rgb(118 118 118 / 100%);
  box-sizing: content-box;
}
</style>
