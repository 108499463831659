const actions = {
  insertToken({ commit }, token) {
    commit('setToken', token);
    commit('setHeaders', new Headers({
      'Authorization': `Bearer ${token}`
    }));
    commit('setAuthenticated', true);
  },
  logout({ commit }) {
    commit('setToken', null);
    commit('setHeaders', null);
    commit('setAuthenticated', false);
  }
};

export default actions;
