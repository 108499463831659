<template>
  <div
    class="IobDropdown"
    :style="dropdownStyle"
    :class="{ 'IobDropdown-scrollbar': isScrollable }"
  >
    <div
      v-if="user"
      class="IobDropdown-user"
    >
      <iob-avatar-with-name
        inert
        :name="user.name"
        :subtitle="user.email"
        :picture="user.picture"
        size="large"
      />
    </div>
    <div
      v-for="(item, index) in itemsArray"
      :key="index"
    >
      <iob-title-element
        v-if="item.type === 'title'"
        :id="`iob-title-dropdown-item-${index}`"
        :key="`IobTitleDropdown-${index}`"
        :title="item.title"
        :color="item.color"
        :state="item.state"
      />
      <iob-badge-element
        v-if="item.type === 'badge'"
        :id="`iob-badge-dropdown-item-${index}`"
        :key="`IobBadgeDropdown-${index}`"
        :size="item.size"
        :text="item.text"
        :color="item.color"
        :icon-name="item.iconName"
        @handle-dropdown-element="$emit('DropdownElementItem', {item})"
      />
      <iob-member-element
        v-if="item.type === 'member'"
        :id="`iob-member-dropdown-item-${index}`"
        :key="`IobMemberDropdown-${index}`"
        :show-icon="item.showIcon"
        :icon-name="item.iconName"
        :title="item.title || item.text"
        :state="item.state"
        :subtitle="item.subtitle"
        :avatar="item.avatar"
        @handle-dropdown-element="(event) => $emit('DropdownElementItem', {item, event})"
      />
      <iob-check-list-element
        v-if="item.type === 'check'"
        :id="`iob-check-dropdown-item-${index}`"
        :field-type="item.fieldType"
        :icon-name="item.iconName"
        :avatar-picture="item.avatarPicture"
        :alt-text="item.altText"
        :avatar-color="item.avatarColor"
        :initials-color="item.initialsColor"
        :label="item.label"
        :disabled="item.disabled"
        :badge-color="item.badgeColor"
        :badge-icon="item.badgeIcon"
        :icon-color="item.iconColor"
        @onClick="(event) => $emit('DropdownElementItem', {item, event})"
      />
      <iob-icon-shape-element
        v-if="item.type === 'icon-shape'"
        :id="`iob-icon-shape-dropdown-item-${index}`"
        :key="`IobIconShapeDropdown-${index}`"
        :title="item.title"
        :subtitle="item.subtitle"
        :icon-name="item.iconName"
        :is-clickable="item.isClickable"
        :icon-color="item.iconColor"
        :icon-size="item.iconSize"
        :disabled="item.disabled"
        :title-style="item.titleStyle"
        @click="(event) => $emit('DropdownElementItem', {item, event})"
      />
      <iob-menu-element
        v-if="item.type === 'menu'"
        :id="`iob-menu-dropdown-item-${index}`"
        :key="`IobMenuDropdown-${index}`"
        :text="item.text"
        :state="item.state"
        :icon-name="item.iconName"
        :icon-size="item.iconSize"
        :show-asset="item.showAsset"
        :show-color-asset="item.showColorAsset"
        :show-avatar="item.showAvatar"
        :avatar="item.avatar"
        :show-icon="item.showIcon"
        :has-sub-items="item.subItemslist && item.subItemslist.length > 0"
        :show-right="item.showRight || (item.subItemslist && item.subItemslist.length > 0)"
        :right-icon-name="item.rightIconName"
        :show-subtext="item.showSubtext"
        :sub-text="item.subText"
        @handle-submenu-hover="(e) => handleSubmenuHover(index, e)"
        @handle-touch-start="(e) => handleTouchStart(index, e, item)"
        @toggle-submenu-dropdown="(e) => toggleSubmenuDropdown(index, e)"
        @handle-dropdown-element="(event) => $emit('DropdownElementItem',{item, event})"
      >
        <div
          v-show="isSubmenuOpen && selectedItem === index"
          class="IobDropdown IobDropdown-submenu"
          :style="getSubMenuStyle"
        >
          <iob-menu-element
            v-for="(subItem, subItemIndex) in item.subItemslist"
            :id="`iob-submenu-element-item-${index}`"
            :key="`${subItem.text}-${subItemIndex}`"
            :text="subItem.text"
            :sub-text="subItem.subText"
            :icon-name="subItem.iconName"
            :icon-size="subItem.iconSize"
            :show-asset="subItem.showAsset"
            :show-icon="subItem.showIcon"
            :state="subItem.state"
            :show-right="subItem.showRight"
            :right-icon-name="subItem.rightIconName"
            @handle-dropdown-element="$emit('DropdownElementItem', {item: subItem, event})"
            @handle-touch-start="(e) => handleTouchStart(index, e, subItem)"
          />
        </div>
      </iob-menu-element>

      <iob-list-with-id-element
        v-if="item.type === 'listwithid'"
        :id="`iob-listwithid-dropdown-item-${index}`"
        :key="`IobListWithIdDropdown-${index}`"
        :text="item.text"
        :title="item.title"
        :shape-color="item.shapeColor"
        :search-value="searchValue"
        @handle-dropdown-element="(event) => $emit('DropdownElementItem', {item, event})"
      />
      <iob-separator
        v-if="item.type === 'separator'"
        class="IobDropdown-separator"
        :color="item.color"
        :color-type="item.colorType"
        :state="item.state"
      />
      <iob-button
        v-if="item.type === 'button'"
        size="default"
        :color="item.color"
        :type="item.buttonType"
        :disabled="item.disabled"
        :label="item.label || ''"
        :left-icon="item.iconName"
        :show-left-icon="item.showLeftIcon"
        :selected="item.selected"
        :button-style="item.style"
        @click="() => {
          $emit('DropdownElementItem', {item}, false)}"
      />
      <iob-label-field
        v-if="item.type === 'input'"
        :placeholder="item.placeholder"
        :show-caracters-counter="false"
        :size="item.inputSize"
        :read-only="false"
        :has-default-style="true"
        :has-icon="true"
        :has-error="item.hasError"
        :icon-name="item.iconName"
        @update:modelValue="(value) => $emit('update:modelValue', value, item.id)"
      />
      <span
        v-if="item.type === 'text'"
        class="IobDropdown-text"
        :style="item.textStyle"
      >
        {{ item.textContent }}
      </span>
    </div>
    <slot />
  </div>
</template>

<script>
import { nextTick } from 'vue';
import { parseIfJson } from '../../../utils';
import IobMenuElement from '../IobMenuElement/IobMenuElement.vue';
import IobTitleElement from '../../Atoms/IobTitleElement/IobTitleElement.vue';
import IobMemberElement from '../IobMemberElement/IobMemberElement.vue';
import IobListWithIdElement from '../IobListWithIdElement/IobListWithIdElement.vue';
import IobBadgeElement from '../IobBadgeElement/IobBadgeElement.vue';
import IobCheckListElement from '../../Organisms/IobCheckListElement/IobCheckListElement.vue';
import IobSeparator from '../../Atoms/IobSeparator/IobSeparator.vue';
import IobAvatarWithName from '../../Molecules/IobAvatarWithName/IobAvatarWithName.vue';
import IobButton from '../../Molecules/IobButton/IobButton.vue';
import IobIconShapeElement from '../../Molecules/IobIconShapeElement/IobIconShapeElement.vue';
import IobLabelField from '../../Molecules/IobLabelField/IobLabelField.vue';
export default {
  name: 'IobDropdown',
  components: {IobIconShapeElement, IobMenuElement, IobTitleElement, IobMemberElement, IobListWithIdElement, IobBadgeElement,IobCheckListElement, IobSeparator, IobAvatarWithName , IobButton, IobLabelField},
  props: {
    items: {
      required: true,
      type: [String, Array],
      default: () => ([{
        item: {
          type: 'menu',
          text: 'text'
        }
      }])
    },
    isScrollable: {
      type: Boolean,
      default: false,
    },
    slicedData: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    dropdownStyle: {
      type: String,
      default: ''
    },
    user: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isSubmenuOpen: false,
      selectedItem: -1,

    };
  },
  computed: {
    itemsArray() {
      let parsedItems = parseIfJson(this.items) || [];
      const title = parsedItems.find((item) => item.type === 'title');
      if (this.slicedData) {
        const sliceCount = title ? 6 : 5;
        parsedItems = parsedItems.slice(0, sliceCount);
      }
      return parsedItems;
    },
    getSubMenuStyle() {
      if (this.selectedItem < 0) {
        return;
      }
      const parent = document.getElementById(
        `iob-menu-dropdown-item-${this.selectedItem}`
      );
      const height = parent.getBoundingClientRect().height;
      const width = parent.getBoundingClientRect().width;
      const viewWidth = document.documentElement.clientWidth;
      const dropdownRect = parent.getBoundingClientRect();

      if (width > viewWidth - dropdownRect.right) {
        return {
          transform: 'translate(-120%, 25%)',
        };
      } else {
        return {
          transform: `translate(${width + 6}px, ${height + 6}px)`,
        };
      }
    },
  },
  methods: {
    selectItem(index, e) {
      this.selectedItem = index;
      this.isSubmenuOpen = true;
      e.isHovered = true;
      this.computePosition();
    },
    handleSubmenuHover(index, e) {
      if (e.hasSubItems) {
        this.selectItem(index, e)
      } else {
        this.selectedItem = -1;
        this.isSubmenuOpen = false;
      }
    },
    handleTouchStart(index, e, item) {
      if (e.hasSubItems) {
        this.selectItem(index, e);
      } else {
        this.$emit('DropdownElementItem',{item, e});
      }
    },
    toggleSubmenuDropdown(index, e) {
      if (e.isHovered) {
        e.isHovered = false;
        return;
      }
      this.isSubmenuOpen = this.selectedItem !== index || !this.isSubmenuOpen;
      this.selectedItem = this.isSubmenuOpen ? index : -1;
      if (this.isSubmenuOpen) {
        // Compute the position of the submenu to move it if it is out of the screen
        this.computePosition();
      }
    },
    computePosition() {
      const current = document.getElementById(`iob-menu-dropdown-item-${this.selectedItem}`).getElementsByClassName('IobDropdown-submenu')[0];
      nextTick(() => {
        const rect = current.getBoundingClientRect();
        if (rect.x < 0) {
          current.style.transform = 'translate(0%, 25%)';
        }
      });
    }
  }
};
</script>

<style lang="scss" src="iobeya-design-tokens/scss/app/iobeya-dropdown.scss" />
<style lang="scss" scoped src="./IobDropdown.scss" />
