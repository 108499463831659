<template>
  <div
    :style="containerStyle"
    class="ContextualMenu"
    :class="multipleElementsMenu && 'ContextualMenu-fullWidth'"
    @pointerdown.stop
    @dblclick.stop
  >
    <div v-if="!locked">
      <div
        v-if="noEdit"
        class="ContextualMenu-container"
      >
        <contextual-menu-component-map
          v-for="(item, index) in SELECTION_AREA_CONTEXTUAL_MENU"
          :key="index"
          :class="{'ContextualMenu-itemContainer': true, 'space-between': !item.hasSeparator}"
          :component-type="item.componentType"
          :icon-name="item.iconName"
          :text="this[item.text]"
          :action="this[item.action]"
          :has-separator="item.hasSeparator"
        />
      </div>
      <div
        v-else
        class="ContextualMenu-container"
      >
        <contextual-menu-component-map
          v-for="(item, index) in contextualMenuItems"
          :key="index"
          :item="item"
          v-bind="buildProps(item)"
          :is-free-size="isFreeSize"
          :element-size="elementSize"
          :set-view-type="setViewType"
          :class="{'ContextualMenu-itemContainer': true, 'space-between': !item.hasSeparator}"
        />
      </div>
    </div>

    <!--Locked elements-->
    <div
      v-else
      class="ContextualMenu-container"
    >
      <button
        class="ContextualMenu-unlock-btn"
        @click="onLockToggle"
      >
        <icon-loader
          name="Unlock"
          size="large"
          style="cursor: pointer"
        />
        <span class="ContextualMenu-unlock-btn-text"> Unlock </span>
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import {
  FORMULA_TOP_CTX_MENU_EQUATION,
  MAX_ZL_SCALE_EDITOR,
  SELECTION_AREA_CONTEXTUAL_MENU,
  e
} from 'GLOBALS/constants';
import ContextualMenuComponentMap from 'BOARD/components/ContextualMenu/ContextualMenuComponentMap.vue';
import { getDimensionFromSize } from 'BOARD/utils/utils';

export default {
  name: 'ContextualMenu',
  components: { ContextualMenuComponentMap },
  props: {
    contextualMenuItems: {
      type: Array,
      required: true
    },
    onEdit: {
      type: Function,
      default: () => {}
    },
    openLink: {
      type: Function,
      default: () => {}
    },
    onDelete: {
      type: Function,
      required: true
    },
    onSizeChange: {
      type: Function,
      default: () => {}
    },
    onLayoutChange: {
      type: Function,
      default: () => {}
    },
    onOpenIndicator: {
      type: Function,
      default: () => {}
    },
    noEdit: {
      type: Boolean,
      default: false
    },
    onLockToggle: {
      type: Function,
      default: () => {}
    },
    selectedElementsCount: {
      type: String,
      default: ''
    },
    isInEdition: {
      type: Boolean,
      default: false
    },
    bgColor: {
      type: String,
      default: ''
    },
    layout: {
      type: String,
      default: ''
    },
    setColor: {
      type: Function,
      default: () => {}
    },
    closeColorsPopup: {
      type: Function,
      default: () => {}
    },
    toggleColorsPopup: {
      type: Function,
      default: () => {}
    },
    setViewType: {
      type: Function,
      default: () => {}
    },
    isColorsPopup: {
      type: Boolean,
      default: false
    },
    multipleElementsMenu: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    isFreeSize: {
      type: Boolean,
      default: false
    },
    sizeLayoutMap: {
      type: Array,
      default: () => []
    },
    onZoomChange: {
      type: Function,
      default: () => {}
    },
    zoomValue: {
      type: Number,
      default: 1
    },
    onOpenInNewTab: {
      type: Function,
      default: () => {}
    },
    onRefresh: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      SELECTION_AREA_CONTEXTUAL_MENU,
      layoutDropdownTitle: ''
    };
  },
  computed: {
    ...mapState('board', ['zoomLevel']),
    containerStyle() {
      let scale = 1 / this.zoomLevel;
      const { EXP_POW, EXP_TIMES, OFFSET } = FORMULA_TOP_CTX_MENU_EQUATION;
      const exponent = EXP_POW * this.zoomLevel;
      let top = EXP_TIMES * Math.pow(e, exponent) + OFFSET;
      const leftOffset = this.multipleElementsMenu ? 'unset' : '0';
      if (this.zoomLevel < MAX_ZL_SCALE_EDITOR) {
        top = -110;
        scale = 1 / MAX_ZL_SCALE_EDITOR - (MAX_ZL_SCALE_EDITOR - this.zoomLevel);
      }
      return `transform: scale(${scale}); top: ${top}px; right: 0; left: ${leftOffset}`;
    },
    sizeDropdownTitle() {
      const layoutItems = this.sizeLayoutMap?.find((item) => item[this.layout]);
      return getDimensionFromSize(this.size.width, this.size.height, layoutItems && layoutItems[this.layout]);
    },
    iconLayoutName() {
      const listOfLayouts = this.contextualMenuItems.find((item) => item.dropdownTitle === 'layoutDropdownTitle');
      const iconName = listOfLayouts?.list?.find((item) => item.value === this.layout)?.iconName || '';
      return  iconName;
    },
    zoomDropdownTitle() {
      return `${this.zoomValue}%`;
    }
  },
  unmounted() {
    this.closeColorsPopup();
    window.setTimeout(() => {
      document.getElementById('boardViewRef')?.focus();
    }, 0);
  },
  methods: {
    /**
      * propValue could either store a static value or
      * the variable name to manage the corresponding prop
    **/
    buildProps(item) {
      const props = {};
      for (const [propName, propValue] of Object.entries(item)) {
        props[propName] = this[propValue] !== undefined ? this[propValue] : propValue;
      }
      return props;
    }
  }
};
</script>

<style src="./ContextualMenu.scss" lang="scss" scoped />
