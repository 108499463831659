<template>
  <div
    class="CardCompartment"
    data-target="board-element"
    :style="containerStyle"
  >
    <div
      v-for="(field, position) in fields"
      :key="field.value"
      :style="getFieldStyle(field, position)"
      class="CardCompartment-field"
      data-target="board-element"
    >
      <iob-avatar-with-name
        v-if="field.type === 'user' && getCardUser(field.value)"
        v-bind="getAvatarProps(field)"
      />
      <design-system-icon-with-label
        v-else-if="field.type === 'priority'"
        v-bind="getIconWithLabelProps(field)"
      />
      <iob-badge
        v-else-if="field.type === 'status'"
        v-bind="getBadgeProps(field)"
      />
      <design-system-icon-with-label
        v-else-if="field.type === 'timeframe'"
        v-bind="getTimeframeProps(field)"
      />
      <design-system-text
        v-else
        :style="getTextStyle(field)"
        data-target="board-element"
        class="CardCompartment-text"
        :value="field.value ? field.value.toString() : ''"
      />
    </div>
  </div>
</template>

<script>
import {
  ZOOM,
  DEFAULT_STATUS_BADGE_COLORS,
  DEFAULT_PRIORITY_ICON_COLORS,
  LEVEL2_CARD_BADGE_SIZE
} from 'GLOBALS/constants';
import { mapGetters, mapState } from 'vuex';
import { getUserInitials } from 'SRC/utils/collab-utils';
import { calculateScaledValue, formatDateToLabel } from 'BOARD/utils/utils';

export default {
  name: 'CardCompartment',
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    hasOutline: {
      type: Boolean,
      default: false
    },
    outlineColor: {
      type: String,
      default: ''
    },
    bgColor: {
      type: String,
      default: ''
    },
    hasColor: {
      type: Object,
      default: () => ({})
    },
    gridSize: {
      type: Object,
      default: () => ({})
    },
    textStyle: {
      type: Object,
      required: true
    },
    fieldStyle: {
      type: Object,
      default: null
    },
    friendlyIdTextStyle: {
      type: String,
      default: ''
    },
    lowerPartFieldsSize: {
      type: Object,
      default: null
    },
    lowerPartIconSize: {
      type: Object,
      default: null
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    dimension: {
      type: Number,
      default: null
    },
    part: {
      type: String,
      required: true
    },
    hasGrid: {
      type: Boolean,
      default: true
    },
    sizeAttributes: {
      type: Object,
      default: () => ({})
    },
    contrastedColor: {
      type: String,
      required: true
    },
    upperPartHeight: {
      type: Number,
      default: 0
    }

  },
  data() {
    return ({
      lodThreshold: 0.6,
      textMargin: `
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        white-space: pre-wrap;
        overflow: hidden
      `,
      defaultBadgeColors: DEFAULT_STATUS_BADGE_COLORS,
      defaultPriorityColors: DEFAULT_PRIORITY_ICON_COLORS
    });
  },
  computed: {
    ...mapGetters('users', ['getUserById']),
    ...mapState('board', ['zoomLevel']),
    styleByPosition() {
      return {
        'center': {position: 'absolute',
          top: '30px', left: '16px', height: `calc(${this.upperPartHeight}px - 30px)`, width: '90%', 'text-align': 'top', overflow: 'hidden'},
        'top-left': {position: 'absolute', top: '11px', left: '16px'},
        'top-right': {position: 'absolute', top: '11px', right: '16px'}
      };
    },
    outline() {
      return this.hasOutline
        ? {
          outline: `${Math.max(2, 2 * (1 / this.zoomLevel))}px ${this.outlineColor
          } solid`
        }
        : {};
    },
    backgroundColor() {
      return this.hasColor && this.bgColor ? this.bgColor : '';
    },
    containerStyle() {
      let style = {backgroundColor: this.backgroundColor,
        ...this.outline
      };
      if (this.hasGrid) {
        style = {
          ...style,
          gridTemplateRows: `repeat(${this.gridSize.rows}, minmax(0, 1fr))`,
          gridTemplateColumns: `repeat(${this.gridSize.cols}, minmax(0, 1fr))`
        };
      } else if (this.part === 'lower') {
        style = {...style, display: 'flex'};
      }
      return style;
    },
    componentSize() {
      if (this.zoomLevel > this.lodThreshold) {
        return `${this.lowerPartFieldsSize && this.lowerPartFieldsSize.level3}`;
      }
      return `${this.lowerPartFieldsSize && this.lowerPartFieldsSize.level2}`;
    },
    iconCustomSize() {
      if (this.zoomLevel > this.lodThreshold) {
        return `${this.lowerPartIconSize && this.lowerPartIconSize.level3}`;
      }
      return `${this.lowerPartIconSize && this.lowerPartIconSize.level2}`;
    },
    badgeSize() {
      if (this.zoomLevel > this.lodThreshold) {
        return `${this.lowerPartFieldsSize && this.lowerPartFieldsSize.level3}`;
      }
      return `${calculateScaledValue(this.width, this.height, LEVEL2_CARD_BADGE_SIZE)}px;`;
    },
    userNameStyle() {
      if (this.zoomLevel > this.lodThreshold) {
        return `width: ${calculateScaledValue(this.width, this.height, 104)}px;white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`;
      }
      return '';
    },
    lowerFieldsStyle() {
      if (this.zoomLevel > this.lodThreshold) {
        return this.fieldStyle && this.fieldStyle.level3;
      }
      return this.fieldStyle && this.fieldStyle.level2;
    }
  },
  created() {
    this.lodThreshold = ZOOM.LEVEL_OF_DETAIL_THRESHOLD;
  },
  methods: {
    getAvatarProps(field) {
      let props = {
        picture: this.getCardUser(field.value).picture,
        name: this.getCardUser(field.value).fullName,
        'alt-text': this.getUserInitials(field.value),
        'data-target': 'board-element'
      };
      if (this.hasGrid) {
        props = {
          ...props,
          'alt-text-style': this.getTextStyle(field),
          'custom-dimension': this.componentSize,
          'name-style': `${this.getTextStyle(field)}; ${this.userNameStyle}`
        };
      } else {
        props = {
          ...props,
          size: this.sizeAttributes.iconSize,
          nameStyle: this.sizeAttributes.bottomTextStyle
        };
      }
      return props;
    },
    getIconWithLabelProps(field) {
      let props = {
        label: field.value,
        color: this.defaultPriorityColors[field.value],
        customDimension: this.componentSize,
        'custom-icon-dimension': this.iconCustomSize,
        'label-style': this.getTextStyle(field),
        icon: 'ChevronUp',
        'data-target': 'board-element'
      };
      if (this.hasGrid) {
        props = {...props,
          label: field.value,
          color: this.defaultPriorityColors[field.value],
          'label-style': this.getTextStyle(field),
          icon: 'ChevronUp',
          'data-target': 'board-element'
        };
      } else {
        props = {
          ...props,
          customDimension: this.componentSize,
          'custom-icon-dimension': this.iconCustomSize,
          size: this.sizeAttributes.iconSize,
          labelStyle: this.sizeAttributes.bottomTextStyle
        };
      }
      return props;
    },
    getBadgeProps(field) {
      let props = {
        label: field?.value?.toString(),
        color: this.defaultBadgeColors[field?.value],
        'data-target': 'board-element'
      };
      if (this.hasGrid) {
        props = {
          ...props,
          'custom-height': this.badgeSize,
          'label-style': this.getTextStyle(field)
        };
      } else {
        props = {
          ...props,
          size: this.sizeAttributes.iconSize,
          'custom-height': this.sizeAttributes.badgeHeight,
          labelStyle: this.sizeAttributes.bottomTextStyle
        };
      }
      return props;
    },
    getTimeframeProps(field) {
      let props = {
        label: this.getHorizon(field.value),
        color: 'secondary',
        icon: 'CalendarRange',
        'data-target': 'board-element'
      };
      if (this.hasGrid) {
        props = {
          ...props,
          'custom-dimension': this.componentSize,
          'custom-icon-dimension': this.iconCustomSize,
          'label-style': this.getTextStyle(field)
        };
      } else {
        props = {
          ...props,
          size: this.sizeAttributes.iconSize,
          labelStyle: this.sizeAttributes.bottomTextStyle
        };
      }
      return props;
    },
    getHorizon(dateSettings) {
      if (!dateSettings) {
        return 'None';
      }
      const attributes = {};
      Object.keys(dateSettings).forEach((attr) => {
        attributes[attr] = dateSettings[attr];
      });
      return formatDateToLabel(dateSettings.type, attributes);
    },
    getLinesLimit({ originRef, h}) {
      const isTitle = originRef === '/vms/title';
      if (!isTitle) {
        return h;
      }
      if (!this.hasGrid) {
        const linesConstant = this.sizeAttributes.lineHeight;
        return Math.floor((this.upperPartHeight) / linesConstant) - 1;
      }
      const cellHeight = 252.0 / 6;
      const linesConstant = this.zoomLevel > this.lodThreshold ? 60 : 70;
      let lines = Math.floor((h * cellHeight) / linesConstant);
      if (this.dimension === 'XL') {
        lines = Math.max(lines, 3);
      }
      return lines;
    },
    getFieldStyle({ x, y, w, h, fieldName, position }) {
      if (this.hasGrid) {
        if (fieldName === 'friendly-id' || fieldName === 'title') {
          return {
            gridColumn: `${x} / ${x + w}`,
            gridRow: `${y} / ${y + h}`
          };
        }
        return {
          gridColumn: `${x} / ${x + w}`,
          gridRow: `${y} / ${y + h}`,
          ...this.lowerFieldsStyle
        };
      } if (this.part === 'lower') {
        return {marginRight: '10px'};
      }
      if (!this.hasGrid) {
        return this.styleByPosition[position];
      }
      return {};
    },
    getCardUser(userId) {
      const user = this.getUserById(userId);
      if (user) {
        return {
          fullName: `${user.firstname} ${user.lastname}`,
          picture: user.picture ? user.picture : ''
        };
      }
    },
    getUserInitials(userId) {
      const user = this.getUserById(userId);
      if (user) {
        return getUserInitials(user.firstname, user.lastname);
      }
    },
    getTextStyle(field) {
      if (field.fieldName !== 'friendly-id' && !this.hasGrid) {
        return `${this.sizeAttributes.titleStyle}; color: ${this.contrastedColor};
        -webkit-line-clamp: ${this.getLinesLimit(field)};
        `;
      }
      if (field.fieldName !== 'friendly-id') {
        if (this.zoomLevel > this.lodThreshold) {
          return `${this.textStyle.level3} -webkit-line-clamp : ${this.getLinesLimit(field)}`;
        }
        return `${this.textStyle.level2} -webkit-line-clamp: ${this.getLinesLimit(field)}`;
      }
      if (!this.hasGrid && field.fieldName === 'friendly-id') {
        return `
          color: ${this.contrastedColor};
        `;
      }
      if (this.zoomLevel > this.lodThreshold) {
        return `
          ${this.friendlyIdTextStyle};
          color: ${this.contrastedColor};
          -webkit-line-clamp: ${this.getLinesLimit(field)};
        `;
      }
      return '';
    }
  }
};
</script>

<style scoped src="./CardCompartment.css" />
