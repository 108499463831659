import { CurrentDateOccurence } from 'SRC/utils/currentDateOccurence/CurrentDateOccurence';
import { DatesSorter } from './DatesSorter';

/**
 * Extracts a subset of glidepath values starting from the closest occurences of current date, with a limit of GLIDEPATH_KEYS_LIMIT (e.g., 8).
 *
 * @param {Object} glidepathValues - An object where keys are dates and values are glidepath values.
 * @param {string} glidepathFrequency - The frequency of the glidepath (e.g., 'monthly', 'quarterly')
 * @param {number} GLIDEPATH_KEYS_LIMIT - The maximum number of glidepath (e.g., 8)
 * @returns {Object} The glidepath values from the current date
 */
export const getGlidepathValuesFromCurrentDate = (glidepathValues, glidepathFrequency, GLIDEPATH_KEYS_LIMIT) => {
  const keys = Object.keys(glidepathValues);
  if (keys.length <= GLIDEPATH_KEYS_LIMIT) {
    return glidepathValues;
  }
  /**
   * Sorts the dates according to the the specified frequency.
   * @type {DatesSorter}
   */
  const datesSorterInstance = new DatesSorter(glidepathFrequency, keys);
  const orderedKeys = datesSorterInstance.generate();
  const currentDate = new Date();
  const UTCDate = new Date(Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getUTCDate()));
  const currentDateOccurenceGenerator = new CurrentDateOccurence(glidepathFrequency, UTCDate);
  const startDate = currentDateOccurenceGenerator.generate();
  let startIndex = orderedKeys.indexOf(startDate);

  /**
   * Adjusts startIndex if there are not enough elements after the start date.
   * @type {number}
   */
  if (orderedKeys.length - startIndex < GLIDEPATH_KEYS_LIMIT) {
    startIndex = GLIDEPATH_KEYS_LIMIT - (orderedKeys.length - startIndex);
  }
  const values = orderedKeys.slice(startIndex, startIndex + GLIDEPATH_KEYS_LIMIT)
    .reduce((acc, el) => ({ ...acc, [el]: glidepathValues[el] || '' }), {});
  return values;
};
