<template>
  <div class="ToolsConfig-header">
    <input
      :style="`width: ${width}`"
      :value="title"
      @input="onTitleChange"
      @blur="resetTitle"
    >
  </div>
</template>

<script>
import 'TOOLS_CONFIG/ToolsConfig.css';

export default {
  name: 'ToolsConfigHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    setTitle: {
      type: Function,
      required: true
    }
  },
  data() {
    return ({
      value: '',
      width: '40px',
      initialTitle: this.title
    });
  },
  watch: {
    title: {
      handler(value) {
        if (value.length < 21) {
          this.width = `${14 * value.length}px`;
        }
      },
      immediate: true
    }
  },
  methods: {
    onTitleChange({ target: { value } }) {
      this.setTitle(value);
    },
    resetTitle() {
      if (!this.title) {
        this.setTitle(this.initialTitle);
      }
    }
  }
};
</script>

<style scoped>
.ToolsConfig-header {
  height: 91px;
  padding-top: 31px;
  padding-bottom: 24px;
  padding-left: 57px;
  display: flex;
  align-items: center;
}

.add-icon {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #9a9a9a;
  transform: translateY(2px);
  margin-left: 4px;
}

input {
  border-style: hidden;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  color: #000;
  background: transparent;
  min-width: 40px;
}

input:focus {
  outline: none;
  border-style: hidden;
}
</style>
